import { React, Component, _, View, resourceActions, Text, TouchableOpacity, TextInput, PickerInput, Popup, LabelledView } from '~/components/index.js'; //eslint-disable-line
import { Button, CheckboxInput } from '@symbolic/rn-lib';
import { connect, setAppData } from '@symbolic/redux';
import { setActiveView, setEvent } from '~/redux/index.js';
import { withRouter } from 'react-router-native';
import { Keyboard } from 'react-native';
import { updateProcessInstanceStep } from '~/helpers/process-instances';

import K from '~/k';
import checkIcon from '~/assets/check-icon-white.png';

class DotCompletionPopup extends Component {
  state = {
    showingHelp: false,
    customHoursToComplete: this.estimatedSize
  };

  close = () => {
    if (!K.isWeb) Keyboard.dismiss();

    setTimeout(() => {
      this.props.onClose();
    }, K.isWeb ? 0 : 100); //HINT wait for keyboard blur on mobile
  }

  handleHoursToCompleteChange = ({value}) => {
    this.updateProcessInstance({key: 'hoursToComplete', value});
  }

  confirmCustomHoursToComplete = () => {
    var {customHoursToComplete} = this.state;

    if (!isNaN(parseInt(customHoursToComplete)) && parseInt(customHoursToComplete) >= 0) {
      this.setState({isEnteringCustomHoursToComplete: false});

      this.handleHoursToCompleteChange({value: parseInt(customHoursToComplete)});
    }
    else {
      alert('Please enter a valid number');
    }
  }

  handleCompletionNotesChange = ({value}) => {
    this.updateProcessInstance({key: 'completionNotes', value});
  }

  updateProcessInstance = ({value, key}) => {
    updateProcessInstanceStep({[key]: value}, this.props);
  }

  get estimatedSize() {
    return parseInt(_.get(this.props.processInstance, `steps.${this.props.processStep.id}.size`, 5));
  }

  handleSubmitButtonPress = () => {
    if (!K.isWeb) Keyboard.dismiss();

    setTimeout(() => {
      this.props.onSubmit();
    }, K.isWeb ? 0 : 100); //HINT wait for keyboard blur on mobile    this.props.onSubmit();
  }

  get suggestedHoursToCompleteValues() {
    var minSuggestedHours = Math.max(this.estimatedSize - 4, 0);
    var maxSuggestedHours = this.estimatedSize + 5;
    var suggestedHoursToCompleteValues = _.range(minSuggestedHours, maxSuggestedHours);

    return suggestedHoursToCompleteValues;
  }

  render() {
    var {processStep, processInstance} = this.props;
    var {isEnteringCustomHoursToComplete} = this.state;
    var {suggestedHoursToCompleteValues} = this;

    var hoursToComplete = parseInt(_.get(processInstance, `steps.${processStep.id}.hoursToComplete`, this.estimatedSize));
    var isUsingCustomHoursToComplete = _.includes(suggestedHoursToCompleteValues, hoursToComplete);

    var completionNotes = _.get(processInstance, `steps.${processStep.id}.completionNotes`, '');

    var hadRework = _.get(processInstance, `steps.${processStep.id}.hadRework`, 0);
    var teammatesWereMissing = _.get(processInstance, `steps.${processStep.id}.teammatesWereMissing`, 0);
    var requiredMaterialsWereNotReady = _.get(processInstance, `steps.${processStep.id}.requiredMaterialsWereNotReady`, 0);
    var workWasComplex = _.get(processInstance, `steps.${processStep.id}.workWasComplex`, 0);

    var colorForTimeToComplete = ({value}) => {
      var color = K.colors.doubleGray;

      if (value < this.estimatedSize) {
        color = `rgba(136, 207, 66, ${Math.abs(this.estimatedSize - value) / 4})`;
      }
      else if (value > this.estimatedSize) {
        color = `rgba(210, 140, 118, ${Math.abs(this.estimatedSize - value) / 4})`;
      }

      return color;
    };

    return (
      <Popup
        scrollEnabled
        useCloseButton
        onClose={this.close}
        {...(K.orientation === 'landscape' ? {height: '75%'} : {})}
      >
        <View style={{marginBottom: K.spacing}}>
          <Button
            mode='dark'
            label='Submit & Complete'
            onPress={this.handleSubmitButtonPress}
          />
        </View>
        <View style={{position: 'relative'}}>
          <LabelledView label='Hours to complete' gray
            styles={{innerView: {padding: K.spacing, paddingTop: K.spacing}, label: {marginBottom: 0}}}
          >
            {isEnteringCustomHoursToComplete ? (
              <View style={{flexDirection: 'row'}}>
                <TextInput
                  value={hoursToComplete}
                  onInput={({value}) => this.setState({customHoursToComplete: value})}
                  style={{flex: 1, marginRight: K.margin, backgroundColor: K.colors.doubleGray}}
                />
                <Button
                  mode='dark'
                  icon={checkIcon}
                  onPress={this.confirmCustomHoursToComplete}
                />
              </View>
            ) : (
              <View style={{flexDirection: 'row'}}>
                {_.map(suggestedHoursToCompleteValues, (value, index) => {
                  return (
                    <Button
                      key={`hours-to-complete-button-${index}`}
                      label={value}
                      onPress={() => this.handleHoursToCompleteChange({value})}
                      style={{
                        backgroundColor: colorForTimeToComplete({value}),
                        flex: value === this.estimatedSize ? 2 : 1,
                        marginRight: 2,
                        paddingHorizontal: 0,
                        borderWidth: value === hoursToComplete ? 1 : 0
                      }}
                    />
                  )
                })}
              </View>
            )}
          </LabelledView>
          <TouchableOpacity
            style={{...K.fonts.sl, cursor: 'pointer', position: 'absolute', top: 5, right: K.spacing}}
            onPress={() => this.setState({isEnteringCustomHoursToComplete: !isEnteringCustomHoursToComplete})}
          >
            {isUsingCustomHoursToComplete ? <Text style={{opacity: 0.5}}>CUSTOM</Text> : <Text style={{opacity: 1}}>{`CUSTOM: ${hoursToComplete}`}</Text>}
          </TouchableOpacity>
        </View>
        <LabelledView label='Notes' gray styles={{innerView: {paddingTop: 2}}}>
          <TextInput
            grayLabelledView
            multiline
            value={completionNotes}
            standardAutoheightStyles
            onChange={this.handleCompletionNotesChange}
          />
        </LabelledView>
        <View>
          <CheckboxInput
            label='Rework'
            style={{marginBottom: 1}}
            value={hadRework}
            onChange={({value}) => this.updateProcessInstance({value, key: 'hadRework'})}
          />
          <CheckboxInput
            label='People Out'
            style={{marginBottom: 1}}
            value={teammatesWereMissing}
            onChange={({value}) => this.updateProcessInstance({value, key: 'teammatesWereMissing'})}
          />
          <CheckboxInput
            label='Materials / Parts not in house'
            style={{marginBottom: 1}}
            value={requiredMaterialsWereNotReady}
            onChange={({value}) => this.updateProcessInstance({value, key: 'requiredMaterialsWereNotReady'})}
          />
          <CheckboxInput
            label='Complex Product'
            style={{marginBottom: 1}}
            value={workWasComplex}
            onChange={({value}) => this.updateProcessInstance({value, key: 'workWasComplex'})}
          />
        </View>
        <Text style={K.explanationText}>
          {`This popup is intended to help capture information about how long work took and any issues or events that affected the work.

The first input captures how many hours the work took to complete - it defaults to the number of hours projected, but if the projected estimate was 5 hours and it actually took 6, you should select 6.

Then you can enter notes describing any unusual issues or events that took place, and check the relevant boxes below.`}
        </Text>
      </Popup>
    );
  }
}

export default withRouter(connect({
  mapState: (state, ownProps) => {
    return {
      activeView: state.activeView || {},
      session: state.session,
    };
  },
  mapDispatch: {
    setActiveView,
    ..._.pick(resourceActions.processInstances, ['updateProcessInstance']),
  }
})(DotCompletionPopup));
