import K from '~/k';

var s = {};

s.table = {
  flex: 1,
  alignSelf: 'stretch',
  // flexDirection: 'row'
};
  s.stickyHeader = {
    flexDirection: 'row',
    // alignSelf: 'center'
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: '100%'
  };
    s.stickyCorner = {

    };
  s.innerTable = {
    flexDirection: 'row',
    paddingBottom: K.spacing * 3,
    alignSelf: 'center',
    ...(K.isWeb ? {maxWidth: '100%'} : {})
  };
  s.verticalScrollView = {
    overflowY: 'overlay',
    flex: 1
  };
  s.horizontalScrollView = {

  };
  s.tableHeader = {
    // flexDirection: 'row'
  };

export default s;
