import K from '~/k';

var s = {};

s.K = {
  rowHeight: K.rowHeight,
  instanceRowHeight: K.instanceRowHeight,
  stickyColumnWidth: K.stickyColumnWidth,
  dotSize: K.step.small.diameter,
  dotMargin: K.step.small.margin
};

s.page = {
  // height: '100%',
  flex: 1,
  // ...(K.isWeb ? {} : {paddingHorizontal: K.spacing})
};
    s.homeIconContainer = {
      marginBottom: 10,
    };
      s.homeIcon = {
        width: 30,
        height: 30
      };
    s.headerTitle = {
      ...K.fonts.label,
      opacity: 1,
      lineHeight: undefined,
      textTransform: 'none',
      fontSize: 20,
      marginBottom: 20,
      // letterSpacing: 4
    };
    s.headerMessages = {
      fontSize: 13,
      opacity: 0.5,
      letterSpacing: 0.8,
      marginBottom: K.spacing,
      alignItems: 'center'
    };
      s.headerMessage = {
        textAlign: 'center'
      };
  s.processTypeSelectorContainer = {
    alignItems: 'center',
    marginBottom: K.spacing * 2,
    // marginTop: K.spacing
  };

  s.scrollViewContent = {
    paddingBottom: K.spacing * 7
  };
  s.processInstancesContainer = {
    marginTop: K.spacing * 2,
    marginBottom: K.spacing * 3,
    flex: 1,
    alignSelf: K.isWeb ? 'center' : 'stretch',
    alignItems: 'center',
  };
    s.processInstancesLabel = {
      ...K.fonts.su,
      fontSize: K.calc(15),
      fontWeight: 'bold',
      opacity: 1,
      marginBottom: K.spacing,
    };
    s.processInstances = {
      flex: 1,
      marginBottom: 0,//60
    };
      s.processInstanceRow = {
        flexDirection: 'row',
        marginTop: 1,
        ...(K.isWeb ? {width: 600} : {})
      };
        s.processInstance = {
          paddingLeft: K.spacing,
          paddingVertical: K.isWeb ? 0 : 8,
          flexGrow: 1,
          flex: 1,
          justifyContent: 'flex-start',
          alignItems: K.isWeb ? 'center' : 'flex-start',
          minHeight: K.button.height,
          borderRadius: 0,
          flexDirection: K.isWeb ? 'row' : 'column',
          backgroundColor: K.colors.gray,
          borderTopLeftRadius: K.borderRadius,
          borderBottomLeftRadius: K.borderRadius
        };
          s.statusDot = {
            marginRight: K.spacing,
            width: 15,
            height: 15,
            borderRadius: 15
          };
          s.processInstanceTitle = {
            fontSize: K.calcFont(14),
            letterSpacing: K.calcFont(1),
            flex: 1,
            textTransform: 'uppercase',
            marginBottom: K.isWeb ? 0 : K.margin
          };
        s.deleteButton = {
          marginLeft: 1,
          width: K.button.width,
          alignSelf: 'stretch',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: K.colors.gray,
          borderTopRightRadius: K.borderRadius,
          borderBottomRightRadius: K.borderRadius
        }
          s.deleteImage = {
            zIndex: 1,
            width: K.calc(20),
            height: K.calc(20),
            opacity: 0.5
          };
    s.processInstancesPlaceholder = {
      height: K.calc(70),
      paddingHorizontal: K.spacing,
      backgroundColor: K.colors.gray,
      borderRadius: K.borderRadius,
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: 1,
      opacity: 0.8,
      ...(K.isWeb ? {width: 600} : {alignSelf: 'stretch'})
    };
      s.processInstancesPlaceholderText = {
        opacity: 0.5,
        textAlign: 'center',
        paddingHorizontal: K.spacing
      };

export default s;
