import K from '~/k';

var s = {};

var collaborationDotSize = 10;

s.sharingPopupView = {
  flex: 1,
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: 'rgba(0, 0, 0, 0.45)',
};
  s.sharingPopupContainer = {
    backgroundColor: 'white',
    borderRadius: 6,
    height: '100%'
  };
    s.shareColumn = {
      paddingHorizontal: K.spacing,
      height: K.button.height,
      alignItems: 'center',
      flexDirection: 'row',
      alignSelf: 'stretch',
      backgroundColor: 'black',
      borderRadius: K.borderRadius,
      marginBottom: K.margin
    };
    s.shareLinkColumn = {
      //paddingHorizontal: K.spacing,
      height: K.button.height,
      flexDirection: 'row',
      // width: '100%',
      // flex: 1,
      backgroundColor: K.colors.gray,
      borderRadius: K.borderRadius,
      marginBottom: K.margin
    };
    s.shareRow = {
      flexDirection: 'row',
      alignItems: 'center',
      ...K.button,
      paddingHorizontal: K.spacing,
      backgroundColor: K.colors.gray,
      width: 'auto',
      alignSelf: 'stretch'
    };
    s.autoComplete = {
      flexDirection: 'row',
      paddingHorizontal: K.spacing,
      backgroundColor: K.colors.gray,
      width: '100%',
    }
    s.sharingTrialTitle = {
      textTransform: 'uppercase',
      fontWeight: 'bold',
      marginBottom: 3
    };
      s.shareImageContainer = {
        alignItems: 'center',
        justifyContent: 'center',
        alignContent: 'center',
        width: 18,
        marginRight: 15
      };
      s.manageAccessImage = {
        alignItems: 'center',
        height: 18,
        width: 13,
      };
      s.shareLinkImage = {
        alignItems: 'center',
        height: 14,
        marginRight: 10,
        width: 14,
      };
      s.screenshotImage = {
        height: 15,
        width: 20
      };
      s.inviteUsersImage = {
        height: K.calc(20),
        width: K.calc(20)
      };
      s.deleteImage = {
        height: K.calc(20),
        width: K.calc(20)
      };
      s.shareText = {
        justifyContent: 'center',
        alignItems: 'center',
        color: 'white'
      };
      s.shareLinkText = {
        justifyContent: 'center',
        alignItems: 'center',
      };
      s.shareLeftText = {
        width: '100%',
      };
      s.shareTextInput = {
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        borderBottomWidth: 0
      };
      s.collaborationDot = {
        width: collaborationDotSize,
        height: collaborationDotSize,
        borderRadius: K.borderRadius,
        marginRight: K.spacing
      };


export default s;
