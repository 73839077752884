import K from '~/k';

var categories = [
  {title: 'Core features', items: [
    {title: 'Templates', description: `We have templates for all of our apps to help users get started.

- You can always start from one of these templates to see how it works and then delete it.
- You can modify templates or start from scratch without a template.`, media: []},
    {title: 'Dots', description: `Dots correspond to steps in a structured process.

A dot:
- Has a customizeable color, title, and number to represent its order in the process
- Can be marked as complete by clicking it to fill it in
- Can be marked as blocked or n/a via the step popup
- Can have an owner and due date assigned
- Can have links or a Protagonist decision assigned
- Can be commented on, and more...`},
    {title: 'Notifications', description: `Notifications are a key feature in Polydot. Notifications can be set up to be sent when:

- A project is first published
- Dots are filled in
- Someone is assigned to a step
- Someone comments and chooses to notify everyone`}
  ]}
];

export default categories;
