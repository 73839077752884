import K from '~/k';

var s = {};

s.secondaryHeader = {
  backgroundColor: K.colors.gray,
  paddingHorizontal: 2,
  borderBottomWidth: 1,
  borderColor: 'white'
};
  s.meta = {
    ...(K.isWeb ? {flexDirection: 'column', justifyContent: 'center', alignItems: 'center',} : {})
  };
    s.processInstanceTitleContainer = {
      position: 'relative',
      maxWidth: 400,
      width: '100%'
    };
      s.processInstanceTitle = {
        ...K.fonts.pageHeader
      };
      s.editImage = {
        width: K.calc(24),
        height: K.calc(22),
        opacity: 0.5
      };

export default s;
