import {
  React, Component, View, ScrollView, styleSpread, Button, logAnalyticsEvent, resourceActions,
  Text, TouchableOpacity, Image
} from '~/components'; //eslint-disable-line

import { setActiveView, setEvent } from '~/redux/index.js';
import { connect, setAppData } from '@symbolic/redux';
import { Label, confirm } from '@symbolic/rn-lib';
import { withSafeAreaInsets } from 'react-native-safe-area-context';
import { DocumentTitle } from '@symbolic/rn-lib';
import { categoryTitleFor, processTypesFor } from '~/helpers/process-types';
import { api } from '@symbolic/lib';
import { isSearchMatch } from '~/helpers/search';

import _ from '@symbolic/lodash';
import K from '~/k';
import styles from './home-page.styles';
import createIcon from '~/assets/create-icon-white.png';
import homeIcon from '~/assets/home-icon.png';

import ProcessInstancesView from '~/components/process-instances-view/process-instances-view';

var s = styleSpread(styles);

class HomePage extends Component {
  state = {
    isCreating: false,
    hasSeenTutorial: _.get(this.props.session.user, 'appData.weflowLite.hasSeenTutorial', false)
  };

  async componentDidMount() {
    this.hasCreatedProcessType = await sessionStore.get('hasCreatedProcessType');

    var processInstancesResponse = await api.request({uri: '/process-instances/get', body: {}});

    await this.props.trackProcessInstances({processInstances: _.get(processInstancesResponse, 'data.processInstances'), reset: true});
  }

  rejectTutorial = async () => {
    this.props.setAppData({key: 'hasSeenTutorial', value: true, appKey: 'weflowLite'});

    this.setState({hasSeenTutorial: true});
  }

  rejectColorScheme = async () => {
    this.props.setAppData({key: 'hasSeenColorSchemePrompt', value: true, appKey: 'weflowLite'});

    alert('To access color scheme settings go to Menu > Workspace > Color Scheme');
  }

  findBusinessOrgId = () => {
    var businessOrgs = _.filter(this.props.session.orgs, {type: 'business', role: 'owner'});

    return _.get(businessOrgs,'0.id');
  }

  onDeleteProcessInstancePress = async ({id}) => {
    this.props.destroyProcessInstance({id});
  }

  get sortedPinnedProcessInstanceIds() {
    var pinData = _.get(this.props.session.user, `appData.weflowLite.pinData`);

    return _.chain(pinData)
      .get('processInstances')
      .map(({rank}, id) => ({rank, id: parseInt(id)}))
      .filter(({id}) => !!id)
      .sortBy([
        ({rank}) => rank === undefined ? 10000 : rank, //sort by existing ranked items first
        (_item, index) => index //then index for unranked items
      ])
      .map('id')
      .value();
  }

  get maxStepCount() {
    var {processTypes} = this.props;
    var expandedProcessTypeIds = _.get(this.props.session.user, `appData.weflowLite.expandedProcessTypeIds`, []);

    var maxStepCount = _.chain(processTypes)
      .reject(({id, isSingleUse, orgId}) => {
        if (_.includes(_.map(this.props.session.orgs, 'id'), orgId)) {
          if (isSingleUse) {
            return !_.includes(expandedProcessTypeIds, 'aggregateOrg' + orgId);
          }
          else {
            return !_.includes(expandedProcessTypeIds, '' + id)
          }
        }
        else {
          return !_.includes(expandedProcessTypeIds, 'aggregateOrg' + -1);
        }
      })
      .filter(processType => _.some(this.props.processInstancesByProcessTypeId[processType.id], {isComplete: 0, isArchived: 0}))
      .map(processType => _.size(_.filter(processType.processSteps, {parentStepId: null})))
      .max()
      .value();

    return Math.max(4, maxStepCount || 4);
  }

  homeProcessTypesDataFor({searchTerm, allProcessInstances}) {
    var orgs = this.props.session.orgs;
    var orgIds = _.map(orgs, 'id');
    var homeProcessTypesData = [];
    var maxVisibleInstances = 20;

    allProcessInstances = allProcessInstances || _.values(this.props.processInstancesById);

    var visibleProcessInstances = _.sortBy(searchTerm ? allProcessInstances : _.filter(allProcessInstances, processInstance => {
      return !processInstance.isComplete && !processInstance.isArchived;
    }), 'id');

    _.forEach([...orgIds, -1], orgId => {
      var org = _.find(orgs, {id: orgId});

      var processTypes = _.filter(this.props.processTypes, processType => {
        return orgId === -1 ? !_.includes(orgIds, processType.orgId) : processType.orgId === orgId;
      });

      var standardProcessTypes = _.filter(processTypes, type => !type.isSingleUse && orgId !== -1);
      var singleUseProcessTypes = _.filter(processTypes, type => type.isSingleUse || orgId === -1);
      var singleUseProcessTypeIds = _.map(singleUseProcessTypes, 'id');
      var singleUseProcessInstances = _.filter(allProcessInstances, instance => _.includes(singleUseProcessTypeIds, instance.processTypeId));

      //single use & shared
      var visibleSingleUseProcessInstances = _.filter(visibleProcessInstances, instance => _.includes(singleUseProcessTypeIds, instance.processTypeId));

      if (org && org.usesProcessInstanceRank) {
        visibleSingleUseProcessInstances = _.sortBy(visibleSingleUseProcessInstances, [
          ({rank}) => rank !== undefined ? 0 : 1,
          'rank',
          'id'
        ]);
      }

      visibleSingleUseProcessInstances = visibleSingleUseProcessInstances.slice(0, maxVisibleInstances);
      visibleSingleUseProcessInstances = _.map(visibleSingleUseProcessInstances, processInstance => ({...processInstance, processType: _.find(this.props.processTypes, {id: processInstance.processTypeId})}))

      homeProcessTypesData.push({isSingleUse: 1, orgId, categoryId: `aggregateOrg${orgId}`, allProcessInstancesOfType: singleUseProcessInstances, visibleProcessInstances: visibleSingleUseProcessInstances});

      //standard
      _.forEach(standardProcessTypes, processType => {
        var allProcessInstancesOfType = _.filter(allProcessInstances, {processTypeId: processType.id});

        var visibleProcessInstancesOfType = _.filter(visibleProcessInstances, {processTypeId: processType.id});

        if (processType.usesProcessInstanceRank) {
          visibleProcessInstancesOfType = _.sortBy(visibleProcessInstancesOfType, [
            ({rank}) => rank !== undefined ? 0 : 1,
            'rank',
            'id'
          ]);
        }

        visibleProcessInstancesOfType = visibleProcessInstancesOfType.slice(0, maxVisibleInstances);

        homeProcessTypesData.push({processType, categoryId: `${processType.id}`, orgId, allProcessInstancesOfType, visibleProcessInstances: visibleProcessInstancesOfType});
      });
    });

    var processTypesData = _.get(this.props.session.user, `appData.weflowLite.processTypesData`, {});

    homeProcessTypesData = _.sortBy(homeProcessTypesData, [
      ({visibleProcessInstances}) => visibleProcessInstances.length > 0 ? 0 : 1,
      ({orgId}) => orgId === -1 ? 100000000 : orgId,
      ({isSingleUse}) => isSingleUse ? 0 : 1
    ]);

    var unrankedData = _.filter(homeProcessTypesData, ({categoryId}) => processTypesData[categoryId] === undefined);
    var sortedData = [];
    var homeProcessTypeData, rankedCategoryId;

    for (var rank = 0; rank < homeProcessTypesData.length; rank++) {
      rankedCategoryId = _.findKey(processTypesData, {rank});

      if (rankedCategoryId) {
        homeProcessTypeData = _.find(homeProcessTypesData, {categoryId: rankedCategoryId});
      }
      else {
        homeProcessTypeData = unrankedData.shift();
      }

      if (homeProcessTypeData) sortedData.push(homeProcessTypeData);
    }

    return sortedData;
  }

  handlePinRankChange = ({value, oldRank}) => {
    if (parseInt(value)) {
      var pinData = _.get(this.props.session.user, `appData.weflowLite.pinData`);
      var oldIds = this.sortedPinnedProcessInstanceIds;
      var newRank = parseInt(value) - 1;

      if (newRank > oldIds.length - 1) newRank = oldIds.length - 1;

      if (oldRank !== newRank) {
        var newIds = _.arrayMove([...oldIds], oldRank, newRank);

        _.forEach(newIds, (id, rank) => _.set(pinData, `processInstances.${id}.rank`, rank));

        this.props.setAppData({appKey: 'weflowLite', key: 'pinData', value: pinData});
      }
    }
  }

  //HINT see sorting at end of homeProcessTypesDataFor...
  //this ranking algorithm is a bit unusual
  //the goal is to allow users to effectively pin a specific type to a chosen location
  //without interfering with the smart ordering that normally happens
  //any ranked types will stay in their assigned rank (i.e. 3) once a user sets that spot
  //all other types will naturally fall in the spots that remain open
  handleProcessTypeRankChange = ({value, oldRank, processType, categoryId}) => {
    if (parseInt(value)) {
      var processTypesData = _.get(this.props.session.user, `appData.weflowLite.processTypesData`, {});

      var newRank = parseInt(value) - 1;

      if (oldRank !== newRank) {
        var sortedByRank = _.chain(processTypesData)
          .map(({rank}, id) => ({rank, id}))
          .sortBy('rank')
          .filter(({rank}) => rank >= newRank)
          .value();

        //HINT we're going to shift any higher ranks down if they conflict.
        //EXAMPLE newRank: 3, otherRanks: [3, 4, 5, 10] => [3, 4, 5, 6, 10] - 3 items got shifted down one to make room for the new rank
        var rankShifter = newRank;

        while (sortedByRank.length && rankShifter === sortedByRank[0].rank) {
          var {rank, id} = sortedByRank.shift();

          _.set(processTypesData, `${id}.rank`, rank + 1);
        }

        _.set(processTypesData, `${categoryId}.rank`, newRank);

        this.props.setAppData({appKey: 'weflowLite', key: 'processTypesData', value: processTypesData});
      }
    }
  }

  toggleIsExpanded = ({categoryId}) => {
    var expandedProcessTypeIds = [..._.get(this.props.session.user, `appData.weflowLite.expandedProcessTypeIds`, [])];

    if (!_.includes(expandedProcessTypeIds, categoryId)) {
      expandedProcessTypeIds.push(categoryId);
    }
    else {
      _.pull(expandedProcessTypeIds, categoryId);
    }

    this.props.setAppData({appKey: 'weflowLite', key: 'expandedProcessTypeIds', value: expandedProcessTypeIds});
  }

  reset = async () => {
    if (await confirm('', 'Are you sure?\n\nThis will remove all pins, collapse all revealed projects, and reset ranks.\n\nThis cannot be undone.')) {
      var oldAppData = _.get(this.props.session.user, `appData`);
      var newAppData = {...oldAppData, weflowLite: _.omit(_.get(oldAppData, 'weflowLite'), ['expandedProcessTypeIds', 'pinData', 'processTypesData'])};

      this.props.setAppData({appKey: 'weflowLite', appData: newAppData});
    }
  }

  dataForProcessInstancesView = ({searchTerm, filterBySearchTerm}) => {
    if (this.props.showingTutorial) return [];

    var data = [];
    var {processTypes, session} = this.props;
    var processInstances = [];
    var {orgs} = session;
    var allProcessInstances = filterBySearchTerm({processInstances: _.values(this.props.processInstancesById)});

    var pinData = _.get(this.props.session.user, `appData.weflowLite.pinData`);
    var expandedProcessTypeIds = _.get(this.props.session.user, `appData.weflowLite.expandedProcessTypeIds`, []);
    var processTypesData = _.get(this.props.session.user, `appData.weflowLite.processTypesData`, {});

    //<--- pins
    var {sortedPinnedProcessInstanceIds} = this;

    var processInstances = filterBySearchTerm({processInstances: _.filter(_.map(sortedPinnedProcessInstanceIds, id => this.props.processInstancesById[id]), instance => !!instance)});

    if (processInstances.length) data.push({key: `pinSpacerBefore`, type: 'spacer'});

    var pinCount = 0;

    _.forEach(processInstances, processInstance => {
      var processType = _.find(processTypes, {id: processInstance.processTypeId});

      if (processType) {
        var oldRank = pinCount;

        data.push({
          key: `processInstance${processInstance.id}`, type: 'processInstance', processInstance, processType,
          isPin: true, pinRank: pinCount, handlePinRankChange: ({value}) => this.handlePinRankChange({value, oldRank})
        });

        pinCount += 1;
      }
    });

    if (processInstances.length) data.push({key: `pinSpacerAfter`, type: 'spacer'});
    //----> pins

    var homeProcessTypesData = this.homeProcessTypesDataFor({searchTerm, allProcessInstances});
    var processTypeCount = 0;

    _.forEach(homeProcessTypesData, ({isSingleUse, categoryId, processType, orgId, allProcessInstancesOfType, visibleProcessInstances}) => {
      var org = _.find(orgs, {id: orgId});
      var isExpanded = _.includes(expandedProcessTypeIds, categoryId);
      var processInstancesAreVisible = visibleProcessInstances.length > 0 && (isExpanded || searchTerm);

      //space before
      if (processInstancesAreVisible) data.push({key: `spacerBeforeProcessType${categoryId}`, type: 'spacer'});

      //project header
      var categoryTitle = categoryTitleFor({processType, isSingleUse, org, orgs});

      if (!searchTerm || visibleProcessInstances.length > 0 || isSearchMatch(searchTerm, categoryTitle)) {
        var processTypeRank = processTypeCount;
        var userRank = _.get(processTypesData, `${categoryId}.rank`);
        var isUserRanked = userRank !== undefined && processTypeRank === userRank;

        var toggleIsExpanded = () => this.toggleIsExpanded({categoryId});
        var handleProcessTypeRankChange = ({value}) => this.handleProcessTypeRankChange({value, oldRank: processTypeRank, processType, categoryId});

        data.push({
          key: `processType${categoryId}Header`, type: 'processTypeHomeHeader',
          processType, isSingleUse, org, hasVisibleProcessInstances: visibleProcessInstances.length > 0, orgs,
          processTypeRank, toggleIsExpanded, handleProcessTypeRankChange, isExpanded, isUserRanked, processInstancesAreVisible
        });
      }

      //instances
      if (processInstancesAreVisible) {
        _.forEach(visibleProcessInstances, processInstance => {
          data.push({key: processInstance.id, type: 'processInstance', processInstance, processType: isSingleUse ? processInstance.processType : processType, isSingleUse});
        });
      }

      if (processInstancesAreVisible) {
        //+ 6 more
        var moreQuantity = allProcessInstancesOfType.length - visibleProcessInstances.length;

        if (!searchTerm && moreQuantity > 0) {
          data.push({key: `moreProcessType${categoryId}`, type: 'more', moreQuantity, processType, org, isSingleUse});
        }

        //space after
        data.push({key: `spacerAfterProcessType${categoryId}`, type: 'spacer'});
      }

      processTypeCount += 1;
    });

    var {processTypesData, pinData, expandedProcessTypeIds} = _.get(this.props.session.user, `appData.weflowLite`, {});

    if (processTypesData || pinData || expandedProcessTypeIds) {
      data.push({key: `spacerBeforeReset`, type: 'spacer'});

      data.push({key: 'reset', type: 'custom', renderStickyColumn: () => (
        <TouchableOpacity onPress={this.reset} style={{paddingHorizontal: K.spacing}}>
          <Label>Reset home screen</Label>
        </TouchableOpacity>
      )});

      data.push({key: `spacerAfterReset`, type: 'spacer'});
    }

    return data;
  }

  renderScrollHeader = () => {
    var hasUsedApp = this.props.processTypes.length > 0 || this.hasCreatedProcessType;
    var {showingTutorial, tutorialStep} = this.props;
    var shouldRenderTutorialPrompt = (this.state.hasSeenTutorial === false && !showingTutorial);
    var colorSchemePromptOrgId = this.findBusinessOrgId();
    var shouldRenderColorSchemePrompt = (!_.get(this.props.session.user, 'appData.weflowLite.hasSeenColorSchemePrompt', false) && colorSchemePromptOrgId);

    return (
      <View style={{alignItems: 'center', paddingTop: K.spacing * 3, paddingBottom: K.spacing * (K.isWeb ? 1 : 1)}}>
        <View {...s.homeIconContainer}>
          <Image {...s.homeIcon} source={homeIcon}/>
        </View>
        <Text {...s.headerTitle}>Welcome to Polydot</Text>
        {this.props.showingTutorial && (
          <Text style={{marginTop: K.spacing * 2}}>Click + above to create a project</Text>
        )}
        {shouldRenderTutorialPrompt ? (
          <View style={{alignItems: 'center', height: 200, justifyContent: 'center'}}>
            <Text style={{opacity: 0.6}}>Would you like a quick interactive tutorial?</Text>
            <View style={{flexDirection: 'row', marginTop: K.spacing * 2}}>
              <Button style={{width: 'auto', marginRight: K.margin}} onPress={this.rejectTutorial} label='Maybe Later'/>
              <Button onPress={() => this.props.setActiveView({data: {showingTutorial: true, tutorialStep: 1}})} style={{width: 'auto', backgroundColor: 'black'}} textStyle={{color: 'white'}} label='Yes'/>
            </View>
          </View>
        ) : this.state.hasSeenTutorial !== undefined && (<>
          {!hasUsedApp && (
            <View {...s.headerMessages}>
              <Text {...s.headerMessage}>Create new projects from here.</Text>
              <Text style={{...styles.headerMessage, marginBottom: 10}}>Start from scratch or use a template to begin.</Text>
            </View>
          )}
          {!showingTutorial && !K.isWeb && hasUsedApp && (
            <View {...s.webAppMessage}>
              <Text style={{...K.explanationText, opacity: 0.4, textAlign: 'center'}}>{`Use Polydot on desktop at\npolydot.app`}</Text>
            </View>
          )}
        </>)}
        {shouldRenderColorSchemePrompt && (
          <View style={{alignItems: 'center', height: 200, justifyContent: 'center'}}>
            <Text style={{opacity: 0.6}}>Would you like to add a color scheme to your workspace?</Text>
            <View style={{flexDirection: 'row', marginTop: K.spacing * 2}}>
              <Button style={{width: 'auto', marginRight: K.margin}} onPress={this.rejectColorScheme} label='Maybe Later'/>
              <Button onPress={() => this.props.history.push(`/edit-workspace/${colorSchemePromptOrgId}`)} style={{width: 'auto', backgroundColor: 'black'}} textStyle={{color: 'white'}} label='Yes'/>
            </View>
          </View>
        )}

      </View>
    );/* : (
      <View style={{backgroundColor: K.colors.gray, padding: K.spacing, alignItems: 'center'}}>
        <View style={{maxWidth: headerMaxWidth, width: '100%', paddingHorizontal: K.spacing}}>
          <Text style={{...K.fonts.pageHeader, marginBottom: K.margin}}>Polydot</Text>
          <Text style={{opacity: 0.5}}>Home</Text>
        </View>
      </View>
    );*/
  }

  render() {
    var {session, showingTutorial} = this.props;

    var dotWidth = this.props.getDotWidth({isHome: true});
    var dotRowWidth = this.props.getDotRowWidth({maxStepCount: this.maxStepCount, isHome: true});

    return (
      <DocumentTitle title='Home - Polydot'>
        <ProcessInstancesView
          renderScrollHeader={this.renderScrollHeader}
          showSearch={showingTutorial}
          dotRowWidth={dotRowWidth}
          dotWidth={dotWidth}
          appData={session.user.appData} //HINT trigger render on update
          processInstanceIds={_.join(_.map(this.props.processInstances, 'id'), ' ')} //hint trigger render on change
          processTypeIds={_.join(_.map(this.props.customProcessTypes, 'id'), ' ')} //hint trigger render on change
          dataFor={this.dataForProcessInstancesView}
          categoryViewSettings={this.props.getCategoryViewSettings({isHome: true})}
          considerLoadingResources={this.props.considerLoadingResources}
          isHome
        />
      </DocumentTitle>
    )
  }
}

export default withSafeAreaInsets(connect({
  mapState: state => ({
    customProcessTypes: _.values(state.resources.processTypes.byId),
    processInstancesById: state.resources.processInstances.byId,
    processInstancesByProcessTypeId: state.resources.processInstances.byFieldKeyIndex.processTypeId,
    processTypes: _.values(processTypesFor({state})),
    ..._.pick(state.activeView.data, ['tutorialStep', 'showingTutorial'])
  }),
  mapDispatch: {
    setActiveView, setEvent, trackUsers: resourceActions.users.trackUsers, setAppData,
    ..._.pick(resourceActions.processInstances, ['trackProcessInstances', 'updateProcessInstance', 'destroyProcessInstance']),
    ..._.pick(resourceActions.processTypes, ['trackProcessTypes', 'updateProcessType']),
    ..._.pick(resourceActions.processSteps, ['trackProcessSteps']),
  }
})(HomePage));
