import { React, Component, _, View, TouchableOpacity, Link, Text, styleSpread, resourceActions, Comments, Tooltip, AccessManager, Loading } from '~/components';

import { setActiveView, setEvent } from '~/redux/index.js';
import { withSafeAreaInsets } from 'react-native-safe-area-context';
import { Image, RefreshControl} from 'react-native';
import { processTypeFor, getCanModifyProcessType } from '~/helpers/process-types';
import { triggerNotifiedIndicator } from '~/helpers/notified-indicator';
import { api } from '@symbolic/lib';
import { connect, updateMyAccount } from '@symbolic/redux';
import { involvedUsersFor, activeInvolvedUsersFor, userSuggestionsFor } from '~/helpers/process-instances';
import { Label, confirm, CopilotStepView, getDevice, getExpoPushToken, Button, prepareToAnimate } from '@symbolic/rn-lib';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { DocumentTitle } from '@symbolic/rn-lib';

import SecondaryHeader from './secondary-header/secondary-header.js';
import StepRow from './step-row/step-row';
import SharePopup from '~/components/popups/share/share-popup';
import ProcessInstanceStepPopup from '~/components/popups/process-instance-step/process-instance-step-popup.js';
import ProtagonistPopup from '~/components/popups/protagonist-popup/protagonist-popup';
import PublishPopup from '~/components/popups/publish-popup/publish-popup';
import ProcessFieldSettingsPopup from '~/components/popups/process-field-settings-popup/process-field-settings-popup';
import ProcessInstanceField from '~/components/process-instance-field/process-instance-field';

import createIcon from '~/assets/create-icon-white.png';
import createIconDark from '~/assets/create-icon-dark.png';
import sortIconDark from '~/assets/sort-icon-black.png';

import K from '~/k';

class ProcessInstancePage extends Component {
  state = {
    isLoading: true,
    isViewingSharee: false,
    isRefreshing: false,
    isShowingProcessFieldsPopup: false,
    processFieldPopupMode: '',
    editingProcessFieldId: {},
    fieldCutoffSize: 6,
  };

  async componentDidMount() {
    this.setState({prevProcessInstanceId: _.get(this.props, 'processInstance.id')});

    await this.considerLoadingResources();
  }

  async componentDidUpdate(prevProps) {
    if (_.get(prevProps, 'processInstance.id') !== _.get(this.props, 'processInstance.id')) {
      this.setState({prevProcessInstanceId: _.get(this.props, 'processInstance.id')});

      await this.considerLoadingResources();
    }
  }

  //HINT set to loading if id changes
  static getDerivedStateFromProps(props, state) {
    return {...state, ...(props.processInstance && state.prevProcessInstanceId && _.get(props.processInstance, 'id') !== state.prevProcessInstanceId ? {isLoading: true} : {})}
  }

  async considerLoadingResources({callback}={}) {
    if (this.props.processInstance) {
      try {
        var comments = await api.get('comments', {
          where: {resourceId: this.props.processInstance.id, type: ['processInstance', 'processInstanceStep']}
        });

        var usersData = await api.request({uri: '/get-users', body: {processInstanceId: this.props.processInstance.id}});
        var users = usersData.data.users;

        this.props.trackComments({comments, reset: true});
        this.props.trackUsers({users}); //HINT temporary hack until user sharing set up

        var involvedUserCount = users.length; //TODO assigned & shared users

        if (!K.isWeb && !this.props.activeView.data.showingTutorial) {
          var deviceId = (await getDevice({appKey: 'weflowLite'})).id;

          if ((involvedUserCount > 1 || Platform.OS === 'android') && _.get(this.props.session, `user.devices.${deviceId}.expoPushToken`) === undefined) {
            var expoPushToken = await getExpoPushToken({appKey: 'weflowLite'});

            if (expoPushToken) {
              await this.props.updateMyAccount({expoPushTokenData: {expoPushToken, deviceId}});
            }
          }
        }

        setTimeout(() => {
          this.setState({isLoading: false});

          if (this.isSharee) {
            setTimeout(() => {
              this.props.startCopilot({autopilot: true, key: 'processInstanceShowView', startArgs: [false, this.scrollViewRef]});
            }, 1000);
          }
        });
      }
      catch (error) {
        console.error(error);
      }

      if (callback) callback();
    }
  }

  toggleIsModifyingProcessType = () => {
   this.setState({isModifyingProcessType: !this.state.isModifyingProcessType})
  }

  createProcessStep = () => {
    this.props.setActiveView({data: {tutorialStep: 7}});

    var {processType} = this.props;

    var topLevelStepCount = _.get(_.filter(this.props.processType.processSteps, {level: 0}), 'length', 0);

    if (!this.props.processInstance.wasModified) this.props.updateProcessInstance({id: this.props.processInstance.id, props: {wasModified: 1}});

    this.props.createProcessStep({props: {
      processTypeId: processType.id,
      color: 'green',
      title: '',
      rank: topLevelStepCount,
      orgId: processType.orgId || this.props.session.activeOrg.id
    }});
  }

  toggleIsViewingSharee = async () => {
    if (!this.state.isViewingSharee) {
      //WARNING using confirm because of mobile bug
      if (await confirm('', 'When you publish or share with someone, they get a link.\n\nThat link will take them here and show them what you\'re about to see.')) {
        this.scrollViewRef.scrollTo({y: 0});

        global.isShowingHelpCopilot = false;

        setTimeout(() => this.props.startCopilot({key: 'processInstanceShowView', startArgs: [false, this.scrollViewRef]}), 200);

        this.setState({isViewingSharee: true, showingPublishPopup: false})
      }
    }
    else {
      this.setState({isViewingSharee: false});
    }
  }

  onCreateComment = ({notifyAllCollaborators, hasMentionedUsers}) => {
    var {processInstance, activeView} = this.props;

    if (processInstance.wasModified !== 1) this.props.updateProcessInstance({id: processInstance.id, props: {wasModified: 1}});

    if (processInstance.isPublished && (notifyAllCollaborators || hasMentionedUsers)) {
      var usersToNotify = _.reject(activeInvolvedUsersFor({usersById: this.props.usersById, processInstance}), {id: this.props.session.user.id});

      if (_.map(usersToNotify).length) {
        triggerNotifiedIndicator({setActiveView: this.props.setActiveView, activeView, notificationTriggerData: {type: 'comment', processInstanceId: processInstance.id, numberOfCollaborators: _.map(usersToNotify).length}});
      }
    }
  }

  handleCollapsePress({processStepId, forceExpand=false}) {
    prepareToAnimate();

    var {processInstance} = this.props;
    var {collapsedStepIds} = this;

    this.props.updateProcessInstance({id: processInstance.id, props: {collapsedStepIds: (_.includes(collapsedStepIds, processStepId) || forceExpand) ? _.pull(collapsedStepIds, processStepId): [...collapsedStepIds, processStepId]}});
  }

  handleCreateProcessField  = async ({processInstance, type, title}) => {
    var defaultValue;
    var data;

    if (type === 'text') {
      data = {
        multiline: 0,
      }
      defaultValue = '';
    }
    else if (type === 'dropdown') {
      data = {
        options: [
          {value: 1, title: 'Option 1', isDeleted: 0},
          {value: 2, title: 'Option 2', isDeleted: 0},
          {value: 3, title: 'Option 3', isDeleted: 0},
        ],
      };

      defaultValue = 1;
    }
    else if (type === 'currency') {
      defaultValue = 0;
    }
    else if (type === 'number') {
      defaultValue = 0;
    }

    var processField = await api.create('processField', {
      title, type, data, defaultValue,
      orgId: processInstance.orgId,
      ownerId: this.props.session.user.id,
      processTypeId: processInstance.processTypeId,
      sizeMultiplier: type === 'text' ? 2 : 1,
      rank: (this.props.processFields.length + 1),
    });

    await this.props.trackProcessFields({processFields: [processField]});

    setTimeout(() => {
      this.setState({processFieldPopupMode: 'update', editingProcessFieldId: processField.id});
    },);
  }

  onRefresh = async () => {
    this.setState({isRefreshing: true});

    var callbackCount = 0;

    var callback = () => {
      callbackCount++;

      if (callbackCount === 2) {
        this.setState({isRefreshing: false})
      }
    }

    await this.props.considerLoadingResources({forceReload: true, callback});
    await this.considerLoadingResources({callback});
  }

  get isSharee() {
    var role = this.props.processInstance.involvedUsers[this.props.session.user.id];

    return role && role !== 'owner';
  }

  get pseudoCollapsedStepIds() {
    return _.map(_.filter(this.props.processType.processSteps, processStep => {
      var potentialCollapsedSteps = [processStep, ...processStep.parentSteps];

      return _.some(potentialCollapsedSteps, potentialCollapsedStep => {
        return _.includes(this.collapsedStepIds, potentialCollapsedStep.id);
      });
    }), 'id');
  }

  get notApplicableStepIds() {
    var {processInstance, processType} = this.props;

    return _.map(_.filter(processType.processSteps, processStep => {
      var potentialNotApplicableSteps = [processStep, ...processStep.parentSteps];

      return _.some(potentialNotApplicableSteps, potentialNotApplicableStep => {
        return _.get(processInstance, `steps.${potentialNotApplicableStep.id}.status`, 'incomplete') === 'notApplicable';
      });
    }), 'id');
  }

  get collapsedStepIds() {
    return _.get(this.props.processInstance, 'collapsedStepIds', []);
  }

  render() {
    if (!this.props.processInstance || this.isLoading) return null;

    var {processInstance, processStep, processType, processFields, activeView, session, startCopilot, insets, hasMultipleInstancesOfType, involvedUsers} = this.props;
    var {showingPublishPopup, isViewingSharee, isShowingProcessFieldsPopup, fieldCutoffSize, isShowingAllFields} = this.state;
    var {protagPopupIsVisible} = activeView.data;
    var {toggleIsModifyingProcessType, toggleIsViewingSharee, pseudoCollapsedStepIds, collapsedStepIds, notApplicableStepIds} = this;
    var {user} = session;

    var isSharee = isViewingSharee || this.isSharee;
    var canModifyProcessType = getCanModifyProcessType({processType, session});
    var isModifyingProcessType = canModifyProcessType && (!hasMultipleInstancesOfType || this.state.isModifyingProcessType);
    var isOwner = processInstance.ownerId === user.id;
    var showToggleIsViewingShareeButton = !this.isSharee;
    var hideExit = !global.isShowingHelpCopilot; //HINT help menu shows exit
    var orgIsAccessible = _.includes(_.map(session.orgs, 'id'), processInstance.orgId);
    var isInSharedOrg = orgIsAccessible && (processInstance.orgId !== user.personalOrgId);

    var activeProcessFields = _.filter(processFields, processField => !processField.isDisabled && (processField.isInternalOnly === 0 || _.includes(_.map(this.props.session.orgs, 'id'), processField.orgId)));
    var disabledFields = _.filter(processFields, {isDisabled: 1});

    return this.state.isLoading ? <Loading text={'Grabbing project\n\nthis can take a few seconds\n\nHold tight...'}/> : (
      <DocumentTitle title={`${processInstance.title} - Polydot`}>
        <View style={{flex: 1}}>
          <SecondaryHeader
            triggerUndoPopup={this.props.triggerUndoPopup}
            back={this.props.back}
            {...{processInstance, processType, isViewingSharee, toggleIsViewingSharee, showingPublishPopup, canModifyProcessType, startCopilot, hasMultipleInstancesOfType, stateIsModifyingProcessType: this.state.isModifyingProcessType, toggleIsModifyingProcessType}}
          />
          <KeyboardAwareScrollView
            style={{flex: 1, overflowY: 'overlay'}}
            extraHeight={75 + (this.state.scrollViewTop || 0)}
            innerRef={ref => global.processInstanceScrollViewRef = this.scrollViewRef = ref}
            refreshControl={<RefreshControl refreshing={this.state.isRefreshing} onRefresh={this.onRefresh} enabled={true}/>}
            contentContainerStyle={{paddingHorizontal: K.spacing + insets.left, paddingTop: K.spacing * 2, paddingBottom: K.spacing * 8, alignItems: 'center'}}
            keyboardShouldPersistTaps='handled'
            onLayout={() => {
              if (!K.isWeb && this.scrollViewRef && this.scrollViewRef.measure) {
                this.scrollViewRef.measure((_x, _y, _width, _height, _pageX, pageY) => {
                  this.state.scrollViewTop !== pageY && this.setState({scrollViewTop: pageY});
                });
              }
            }}
          >
            <View style={{alignItems: 'stretch', ...(K.isWeb ? {maxWidth: 600, width: '100%'} : {alignSelf: 'stretch'})}}>
              {processFields.length > 0 && (
                <View style={{width: '100%', marginBottom: K.spacing * 2, justifyContent: 'flex-start', flexDirection: 'row', flexWrap: 'wrap'}}>
                  {_.map(_.orderBy(activeProcessFields,'rank', 'asc'), (processField, index) => {

                    //currentFieldsSize += processField.sizeMultiplier;

                    return (
                      <ProcessInstanceField key={processField.id}
                      {...{processInstance, processField}}
                      toggleShowingFieldPopup = {() => this.setState({editingProcessFieldId: processField.id, processFieldPopupMode: 'update', isShowingProcessFieldsPopup: true})}
                      isModifyingProcessType={isModifyingProcessType}
                      isSharee={{isSharee}}
                      contextKey='project'
                      updateProcessInstance= {this.props.updateProcessInstance}
                      />
                    );

                    // Show More/Less
                    // if ((currentFieldsSize <= fieldCutoffSize) || (isShowingAllFields && (currentFieldsSize > fieldCutoffSize)) ) {
                    //   return (
                    //     <ProcessInstanceField key={processField.id}
                    //     {...{processInstance, processField}}
                    //     toggleShowingFieldPopup = {()=> this.setState({editingProcessFieldId: processField.id, processFieldPopupMode: 'update', isShowingProcessFieldsPopup: true})}
                    //     />
                    //   );
                    // }else if (isShowingAllFields && (currentFieldsSize === fieldCutoffSize)){
                    //   return (
                    //       <ProcessInstanceField key={processField.id}
                    //       {...{processInstance, processField}}
                    //       toggleShowingFieldPopup = {()=> this.setState({editingProcessFieldId: processField.id, processFieldPopupMode: 'update', isShowingProcessFieldsPopup: true})}
                    //       />
                    //   );
                    // } else {
                    //   return
                    // }
                  })}
                  {(isModifyingProcessType && disabledFields) && _.map(_.orderBy(disabledFields,'rank', 'asc'), (processField, index) => {
                      return (
                        <ProcessInstanceField key={processField.id}
                        {...{processInstance, processField}}
                        toggleShowingFieldPopup = {() => this.setState({editingProcessFieldId: processField.id, processFieldPopupMode: 'update', isShowingProcessFieldsPopup: true})}
                        isModifyingProcessType={isModifyingProcessType}
                        isSharee={{isSharee}}
                        contextKey='project'
                        updateProcessInstance= {this.props.updateProcessInstance}
                        />
                      );
                    })}
                </View>
              )}
              {isModifyingProcessType && !isSharee && (
                <View style={{marginBottom: K.spacing * 2, width: '100%', flexDirection: 'row', alignItems: 'center'}}>
                  {/* <Button /// SHOW FIELDS MORE / LESS
                    alignLeft
                    style={{marginRight: isModifyingProcessType ? K.margin * 2: 0, marginBottom:K.margin * 2, flex: 1}}
                    textStyle={{opacity: 0.5, width: '100%'}}
                    onPress={() => this.setState({isShowingAllFields: !this.state.isShowingAllFields})}
                    icon={leftArrow}
                    iconStyle={{transform: [{rotate: this.state.isShowingAllFields ? '0deg' : '-90deg'}], opacity: 0.7, width: K.calc(16), height: K.calc(16)}}
                    label={'Show More'}
                  /> */}
                  <>
                    <CopilotStepView
                      key={hideExit}
                      {...{hideExit}}
                      name='fields'
                      order={16}
                      text={`Here you can add custom input fields to your project to track information such as an email, a project value, or a project type.`}
                      style={{marginRight: K.margin, flex: 1}}
                    >
                      <Button
                        alignLeft
                        style={{}}
                        textStyle={{opacity: 0.5, width: '100%'}}
                        onPress={() => this.setState({processFieldPopupMode: 'create', isShowingProcessFieldsPopup: true})}
                        icon={createIconDark}
                        label={'Add field'}
                      />
                    </CopilotStepView>
                    {this.props.processFields.length > 1 && (
                      <Button
                        alignLeft
                        style={{marginRight: K.margin, flex: 1}}
                        textStyle={{opacity: 0.5, width: '100%'}}
                        onPress={() => this.setState({processFieldPopupMode: 'reorder', isShowingProcessFieldsPopup: true})}
                        icon={sortIconDark}
                        label={'Reorder fields'}
                      />
                    )}
                  </>
                </View>
              )}
              {isShowingProcessFieldsPopup && (
                <ProcessFieldSettingsPopup
                  onClose={() => this.setState({isShowingProcessFieldsPopup: false})}
                  handleCreateField={this.handleCreateProcessField}
                  updateProcessField={this.props.updateProcessField}
                  mode={this.state.processFieldPopupMode}
                  editingProcessFieldId={this.state.editingProcessFieldId}
                  processField={_.find(processFields, {id: this.state.editingProcessFieldId})}
                  {...{processInstance, processFields, processType}}
                />
              )}
              <View style={{marginBottom: K.spacing * 4, alignSelf: 'stretch'}}>
                {_.map(processType.processSteps, (processStep, index) => {
                  var isNotApplicable = _.includes(notApplicableStepIds, processStep.id);
                  var hideNotApplicableStep = isNotApplicable && (!orgIsAccessible || isViewingSharee);

                  return !hideNotApplicableStep && !_.includes([...collapsedStepIds, ...pseudoCollapsedStepIds], processStep.parentStepId) && (
                    <StepRow
                      key={processStep.id}
                      collapsed={_.includes(collapsedStepIds, processStep.id)}
                      handleCollapsePress={({processStepId, forceExpand}) => this.handleCollapsePress({processStepId, forceExpand})}
                      {...{processStep, processInstance, appearAsNotApplicable: isNotApplicable, processType, index, startCopilot, isSharee, isModifyingProcessType}}
                    />
                  );
                })}
                {isModifyingProcessType && (
                  <View style={{marginTop: K.spacing * 2 - K.margin, flexDirection: 'row', alignItems: 'center', height: K.step.height}}>
                    <AccessManager behaviorMap={{inactive: isInSharedOrg ? 'hide' : 'show'}}>
                      <TouchableOpacity style={{position: 'relative', alignSelf: 'flex-start', height: K.step.height, width: K.step.height, borderRadius: K.step.height / 2, justifyContent: 'center', alignItems: 'center', backgroundColor: 'black'}} onPress={this.createProcessStep}>
                        <Image source={createIcon} style={{width: K.calc(20), height: K.calc(20)}}/>
                      </TouchableOpacity>
                    </AccessManager>
                  </View>
                )}
              </View>
              {protagPopupIsVisible && <ProtagonistPopup activeProcessInstance={processInstance}/>}
              {isOwner && !isViewingSharee && !processType.isSingleUse && canModifyProcessType && (
                <Tooltip text={isModifyingProcessType ? '' : `Edit steps (affects all projects in ${processType.pluralTitle})`}>
                  <AccessManager behaviorMap={{inactive: isInSharedOrg ? 'showPopup' : 'show'}}>
                    <Button
                      alignLeft
                      style={{marginBottom: K.margin}}
                      textStyle={{opacity: 0.5}}
                      onPress={toggleIsModifyingProcessType}
                      label={isModifyingProcessType ? 'Done editing' : 'Edit'}
                    />
                  </AccessManager>
                </Tooltip>
              )}
              <CopilotStepView
                order={17}
                key={isSharee ? 'commentsSharee' : 'comments'}
                name='comments'
                text={!isSharee ? `Leave notes and mention collaborators with @Name` : `You can also leave a comment if you’d like, and we'll notify your collaborators.\n\nP.S. You can use Polydot for your other projects too. If you're interested, press the home icon top left.\n\nThat's all for now!`}
                style={{position: 'relative'}}
              >
                <AccessManager behaviorMap={{inactive: isInSharedOrg ? 'hide' : 'show'}}>
                  <Comments
                    placeholder='LEAVE A COMMENT'
                    onCreate={this.onCreateComment}
                    creationProps={{orgId: processInstance.orgId}}
                    isPublished={processInstance.isPublished}
                    resourceId={processInstance.id}
                    additionalUsers={userSuggestionsFor({
                      ..._.pick(this.props, ['session', 'usersById', 'processInstance']),
                      activeInvolvedUsersOnly: processInstance.isPrivate
                    })}
                    type={'processInstance'}
                    autoFocus={false}
                  />
                </AccessManager>
              </CopilotStepView>
              {isViewingSharee && !this.isSharee && (
                <Tooltip text={isViewingSharee ? '' : 'See what someone you share with would see'}>
                  <Button onPress={this.toggleIsViewingSharee} mode={isViewingSharee ? 'dark' : 'light'} alignLeft style={{marginTop: K.margin}} textStyle={{opacity: isViewingSharee ? 1 : 0.5}} label={!isViewingSharee ? 'Collaborator view' : 'Back to creator view'}/>
                </Tooltip>
              )}
            </View>
          </KeyboardAwareScrollView>
          {processStep && (
            <ProcessInstanceStepPopup
              triggerUndoPopup={this.props.triggerUndoPopup}
              handleCollapsePress={({processStepId, forceExpand}) => this.handleCollapsePress({processStepId, forceExpand})}
              {...{
                processStep, processInstance, processType, involvedUsers, isViewingSharee,
                canModifyProcessType, isModifyingProcessType, hasMultipleInstancesOfType, toggleIsModifyingProcessType
              }}
            />
          )}
          {showingPublishPopup && (
            <PublishPopup onClose={() => this.setState({showingPublishPopup: false})} {...{processInstance, processType, showToggleIsViewingShareeButton, toggleIsViewingSharee, isViewingSharee}}/>
          )}
          {this.props.activeView.data.sharePopupIsVisible && (
            <SharePopup
              hideSharePopup={() => this.props.setActiveView({data: {sharePopupIsVisible: false}})}
            />
          )}
        </View>
      </DocumentTitle>
    )
  }
}

export default withSafeAreaInsets(connect({
  mapState: (state, ownProps) => {
    var {session, activeView, resources} = state;

    var processInstanceId = parseInt(ownProps.match.params.processInstanceId);
    var processInstance = _.get(resources.processInstances.byId, processInstanceId);

    if (!processInstance) {
      if (ownProps.history) state.activeView.data.deletedProcessInstanceId !== processInstanceId && ownProps.history.push('/404/project')
    }
    else {
      var processType = processTypeFor({state, processTypeId: processInstance.processTypeId});

      if (ownProps.match.params.processStepId) {
        var processStep = _.find(processType.processSteps, {id: parseInt(ownProps.match.params.processStepId)});

        if (!processStep && ownProps.history) ownProps.history.push('/404/step');
      }
    }

    return {
      session, activeView, processInstance, processType, processStep,
      usersById: resources.users.byId,
      involvedUsers: involvedUsersFor({processInstance, usersById: resources.users.byId}),
      comments: _.get(resources, 'comments.byId', {}),
      hasMultipleInstancesOfType: !_.get(processType, 'isSingleUse'),
      processFields: processInstance ? _.filter(state.resources.processFields.byId, {processTypeId: processInstance.processTypeId}) : [],
    };
  },
  mapDispatch: {
    setActiveView, setEvent, updateMyAccount,
    ..._.pick(resourceActions.processInstances, ['trackProcessInstances', 'updateProcessInstance', 'destroyProcessInstance']),
    ..._.pick(resourceActions.processSteps, ['trackProcessSteps', 'createProcessStep']),
    ..._.pick(resourceActions.processTypes, ['trackProcessTypes', 'updateProcessType']),
    ..._.pick(resourceActions.processFields, ['trackProcessFields', 'updateProcessField']),

    ..._.pick(resourceActions.users, ['trackUsers']),
    ..._.pick(resourceActions.comments, ['trackComments'])
  }
})(ProcessInstancePage));
