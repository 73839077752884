import _ from 'lodash';
import lib, { api } from '@symbolic/lib';
import { createProcessTypeAndSteps } from '~/helpers/process-types';
import { logAnalyticsEvent } from '~/components';

async function updateProcessInstanceField(value, {processField, processInstance, updateProcessInstance, otherProps}) {
  api.update('processInstance', {where: {id: processInstance.id}, props: {...otherProps, fieldData: {processFieldId: processField.id, value}}}); //hit api

  var fields = {...processInstance.fields, [processField.id]: value};

  await updateProcessInstance({id: processInstance.id, props: {fields, ...otherProps}, hitApi: false}); //update local data
}

//HINT made to be easily called like update updateProcessInstanceStep({status: 'incomplete'}, this.props);
//HINT can be called like updateProcessInstanceStep({status: 'incomplete'}, {processStep, processInstance, updateProcessInstance})
async function updateProcessInstanceStep(props, {processStep, processInstance, updateProcessInstance}, otherProps) {
  if (!processStep) throw new Error('processStep missing');
  if (!processInstance) throw new Error('processStep missing');
  if (!updateProcessInstance) throw new Error('updateProcessInstance missing');

  var steps = _.cloneDeep(processInstance.steps || {}); //avoid mutating

  steps[processStep.id] = {...steps[processStep.id], ...props};

  //HINT intentionally no await so ui updates immediately
  api.update('processInstance', {where: {id: processInstance.id}, props: {...otherProps, stepData: {processStepId: processStep.id, props}}}); //hit api

  await updateProcessInstance({id: processInstance.id, props: {steps, ...otherProps}, hitApi: false}); //update local data
}

function involvedUserIdsFor({processInstance}) {
  return processInstance && _.map(_.keys(processInstance.involvedUsers), idString => parseInt(idString));
}

function involvedUsersFor({usersById, processInstance}) {
  return processInstance && _.map(processInstance.involvedUsers, (role, id) => ({...usersById[id], role}));
}

function activeInvolvedUsersFor({usersById, processInstance}) {
  var involvedUsers = usersById ? involvedUsersFor({usersById, processInstance}) : _.map(processInstance.involvedUsers, (role, id) => ({role, id}));

  return _.reject(involvedUsers, involvedUser => isAssigneeAndAllStepsAreNA({user: involvedUser, processInstance}));
}


function isAssigneeAndAllStepsAreNA({processInstance, user}) {
  var allStepsNA = false;
  var role = processInstance.involvedUsers[user.id];

  if (role === 'assignee') {
    if (_.some(_.map(processInstance.steps, 'status'), status => status === 'notApplicable')) {
      var assignedSteps = _.filter(processInstance.steps, ({team}) => {
        return _.some(team, ({userId}) => userId === user.id);
      });

      if (_.every(assignedSteps, ({status}) => status === 'notApplicable')) {
        allStepsNA = true;
      }
    }
  }

  return role === 'assignee' && allStepsNA;
}

function userSuggestionsFor({searchTerm, usersById, session, processInstance, filterUserIds, orgUsersOnly=false, activeInvolvedUsersOnly=false, preventEmailInput=false}) {
  var lowerSearchTerm = _.toLower(searchTerm);
  var involvedUserIds = involvedUserIdsFor({processInstance});
  var orgIds = [processInstance.orgId];

  if (processInstance.orgId === session.user.personalOrgId) {
    orgIds = _.map(session.orgs, 'id');
  }

  var userSuggestions = _.filter(usersById, user => {
    if (activeInvolvedUsersOnly && !_.includes(_.map(activeInvolvedUsersFor({usersById, processInstance}), 'id'), user.id)) return false; //hint only want active involved users
    if (searchTerm && (!_.includes(_.toLower(user.name), lowerSearchTerm) && !_.includes(_.toLower(user.email), lowerSearchTerm))) return false; //HINT no search match
    if (!(_.some(orgIds, orgId => (_.get(user, `rolesByOrgId.${orgId}`) && !_.includes(['left', 'disabled'], user.rolesByOrgId[orgId]))) || (!orgUsersOnly && _.includes(involvedUserIds, user.id)))) return false; //HINT not in workspace together or specifically shared
    if (filterUserIds && _.includes(filterUserIds, user.id)) return false; //HINT specifically filtered out

    return true;
  });

  userSuggestions = _.sortBy(userSuggestions, [
    user => user.id === session.user.id ? 0 : 1,
    user => _.includes(involvedUserIds, user.id) ? 0 : 1,
    user => _.get(user, `rolesByOrgId.${processInstance.orgId}`) ? 0 : 1,
    'name'
  ]);

  if (searchTerm && (!userSuggestions.length || lib.validation.emailIsValid(searchTerm)) && !preventEmailInput) {
    userSuggestions.unshift({
      id: -1,
      firstName: lib.validation.emailIsValid(searchTerm) ? `"${searchTerm}" (valid email)` : `"${searchTerm}"`,
      lastName: '',
      invalidMessage: lib.validation.emailIsValid(searchTerm) ? '' : 'Please enter a valid collaborator or email'
    });
  }

  return userSuggestions;
}

async function updateInvolvedUsers({mode='set', role='sharee', users, processInstance, updateProcessInstance}) {
  var involvedUsers = {...processInstance.involvedUsers};

  _.forEach(users, user => {
    if (mode === 'set') {
      if (!involvedUsers[user.id] || involvedUsers[user.id] === 'assignee') involvedUsers[user.id] = role;
    }
    else {
      delete involvedUsers[user.id];
    }
  });

  await updateProcessInstance({id: processInstance.id, props: {involvedUsers}, hitApi: false});
}

var isCreatingProcessInstance = false;

async function createProcessInstance({
  processType, session, history, org,
  trackProcessTypes, createProcessSteps, trackProcessInstances, updateProcessType,
}) {
  if (!isCreatingProcessInstance) {
    isCreatingProcessInstance = true;

    try {
      logAnalyticsEvent('process_instance_created');

      if (!processType || (processType && processType.isSingleUse)) processType = await createProcessTypeAndSteps({orgId: org.id, session, trackProcessTypes, createProcessSteps});

      var processInstance = await api.create('processInstance', {
        title: `Untitled ${processType.title}`,
        orgId: processType.orgId,
        ownerId: session.user.id,
        processTypeId: processType.id,
        steps: {},
        wasModified: 0,
        isPublished: processType.autoPublish,
        outsideCollaboratorsCanEditSteps: processType.outsideCollaboratorsCanEditSteps,
        localId: processType.instanceCount + 1
      });

      await trackProcessInstances({processInstances: [processInstance]});

      setTimeout(() => history.push(`/projects/${processInstance.id}`));
    }
    catch (error) {
      console.error(error);
    }

    setTimeout(() => isCreatingProcessInstance = false);
  }
}

export { updateProcessInstanceField, updateProcessInstanceStep, userSuggestionsFor, involvedUsersFor, updateInvolvedUsers, activeInvolvedUsersFor, isAssigneeAndAllStepsAreNA, createProcessInstance };
