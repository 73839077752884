var slides = [
  {
    title: 'Collaborate using dots',
    body: 'Coordinate with clients',
    image: require('./2.jpg'),
    mediaResizeMode: 'contain'
  },
  {
    title: 'Flexible enough for any type of work',
    body: 'Coordinate with contractors & other outside partners',
    image: require('./3.jpg'),
    mediaResizeMode: 'contain'
  },
  {
    title: 'Structured enough to mark sure things get done',
    body: 'Coordinate internal projects',
    image: require('./4.jpg'),
    mediaResizeMode: 'contain'
  }
];

export default slides;
