import { React, Component, _, View, resourceActions, Text, TextInput, Image, styleSpread} from '~/components/index.js'; //eslint-disable-line
import { withRouter } from 'react-router-native';
import { connect } from '@symbolic/redux';
import { AutocompleteInput, Popup, CheckboxInput, Button, LabelledView, Loading, confirm } from '@symbolic/rn-lib';
import lib, { api } from '@symbolic/lib';
import { TouchableOpacity } from 'react-native';
import { triggerNotifiedIndicator } from '~/helpers/notified-indicator';
import { setActiveView } from '~/redux/active-view/active-view';
import { userSuggestionsFor, involvedUsersFor, updateInvolvedUsers, activeInvolvedUsersFor, updateProcessInstanceStep } from '~/helpers/process-instances';

import deleteIcon from '~/assets/x-icon.png';
import styles from './team-picker-input.styles';
import K from '~/k';

var s = styleSpread(styles);

class TeamPickerInput extends Component {
  state = {
    assignee: {},
    searchTerm: '',
    isLoading: false
  };

  handleAddAssignee = ({assignee}) => {
    if (this.props.resourceKey === 'processInstance') {
      var {processInstance, activeView} = this.props;
      var steps = _.cloneDeep(processInstance.steps || {});

      //HINT intentionally no await
      updateProcessInstanceStep({team: _.uniq([..._.get(steps, `${this.props.processStep.id}.team`, []), {userId: assignee.id, role: 'owner'}])}, this.props);

      setTimeout(() => updateInvolvedUsers({users: [assignee], mode: 'set', role: 'assignee', ..._.pick(this.props, ['processInstance', 'updateProcessInstance'])})) //WARNING timeout so update calls don't overlap

      if (processInstance.isPublished && assignee.id !== this.props.session.user.id) {
        var usersToNotify = _.reject(activeInvolvedUsersFor({usersById: this.props.usersById, processInstance}), {id: this.props.session.user.id});

        if (_.map(usersToNotify).length) {
          triggerNotifiedIndicator({setActiveView: this.props.setActiveView, activeView, notificationTriggerData: {type: 'stepAssignment', individualAssignment: true, processInstanceId: processInstance.id, step: this.props.processStep, numberOfCollaborators: _.map(usersToNotify).length}});
        }
      }
    }
    else if (this.props.resourceKey === 'processStep') {
      var defaultTeam = _.cloneDeep(this.props.processStep.defaultTeam || {});

      defaultTeam[`${assignee.id}`] = {role: 'owner'};

      this.props.updateProcessStep({id: this.props.processStep.id, props: {defaultTeam, wasModified: 1}});
    }

    this.setState({searchTerm: '', autocompleteIsVisible: false});
  }

  handleAssignEmail = async () => {
    var assigneeData = await api.request({uri: '/process-instances/get-or-create-assignee', body: {processInstanceId: this.props.processInstance.id, inviteeEmail: this.state.searchTerm}});
    var {assignee} = assigneeData.data;

    this.props.trackUsers({users: [assignee]});

    this.handleAddAssignee({assignee});
  }

  removeAssignee = async ({assignee}) => {
    if (this.props.resourceKey === 'processInstance') {
      var steps = _.cloneDeep(this.props.processInstance.steps || {});

      //HINT intentionally no await
      updateProcessInstanceStep({team: _.filter(_.get(steps, `${this.props.processStep.id}.team`, []), assigneeData => assigneeData.userId !== assignee.id)}, this.props);

      setTimeout(() => updateInvolvedUsers({users: [assignee], mode: 'set', role: 'assignee', ..._.pick(this.props, ['processInstance', 'updateProcessInstance'])})) //WARNING timeout so update calls don't overlap
    }
    else if (this.props.resourceKey === 'processStep') {
      if (!this.state.removeAssignments || await confirm('', 'Are you sure? This cannot be undone and will affect many projects.')) {
        this.setState({isLoading: true});

        try {
          var {processInstances, processSteps} = (await api.request({uri: '/process-steps/remove-default-assignee', body: {
            processStepId: this.props.processStep.id,
            processTypeId: this.props.processType.id,
            defaultUserId: assignee.id,
            ..._.pick(this.state, ['removeAllDefaultAssignments', 'removeAssignments', 'removeAsCollaborator'])
          }})).data;

          this.props.trackProcessInstances({processInstances});
          this.props.trackProcessSteps({processSteps});

          this.setState({isRemovingDefaultAssignee: false, isLoading: false});
        }
        catch (error) {
          console.error(error);

          this.setState({isLoading: false});
        }
      }
    }
  }

  get autocompleteOptions() {
    return userSuggestionsFor({
      ..._.pick(this.props, ['session', 'usersById', 'processInstance']),
      searchTerm: this.state.searchTerm,
      filterUserIds: this.assigneeUserIds,
      orgUsersOnly: this.props.resourceKey === 'processStep',
      activeInvolvedUsersOnly: !this.isOwner && this.props.processInstance.isPrivate,
      preventEmailInput: !this.isOwner && this.props.processInstance.isPrivate
    });
  }

  get assigneeUserIds() {
    if (this.props.resourceKey === 'processInstance') {
      return _.map(_.get(this.props.processInstance, `steps.${this.props.processStep.id}.team`, []), 'userId');
    }
    else if (this.props.resourceKey === 'processStep') {
      return _.map(_.get(this.props.processStep, `defaultTeam`, {}), (_assigneeData, userId) => _.toInteger(userId));
    }
  }

  get assignees() {
    return _.filter(_.map(this.assigneeUserIds, id => this.props.usersById[id]), user => user);
  }

  get isOwner() {
    return this.props.processInstance.ownerId === this.props.session.user.id;
  }

  render() {
    var {assignees, autocompleteOptions} = this;
    var {searchTerm} = this.state;


    var autocompleteIsVisible = this.state.autocompleteIsVisible
    var setAutocompleteIsVisible = (value) => this.setState({autocompleteIsVisible: value});

    if (this.state.isRemovingDefaultAssignee) {
      var otherAssignedSteps = _.filter(this.props.processType.processSteps, processStep => processStep.id !== this.props.processStep.id && !!_.get(processStep, `defaultTeam.${this.state.isRemovingDefaultAssignee.id}`));
      var isAssignedToOtherSteps = otherAssignedSteps.length > 1;
      var {removeAllDefaultAssignments} = this.state;
    }

    return (
      <View style={{position: 'relative'}}>
        <View
          style={{
            zIndex: 1,
            alignSelf: 'stretch',
            borderBottomLeftRadius: ((!autocompleteIsVisible && !assignees.length) || !autocompleteOptions.length) ? K.borderRadius : 0,
            borderBottomRightRadius: ((!autocompleteIsVisible && !assignees.length) || !autocompleteOptions.length) ? K.borderRadius : 0,
            marginBottom: assignees.length ? 1 : 0
          }}
        >
          <AutocompleteInput
            data={autocompleteOptions}
            placeholder={!this.isOwner && this.props.processInstance.isPrivate ? 'NAME': 'NAME OR EMAIL'}
            inputValue={searchTerm}
            setInputValue={(value) => this.setState({searchTerm: value})}
            {...{autocompleteIsVisible, setAutocompleteIsVisible}}
            onSelect={item => item.id === -1 ? this.handleAssignEmail() : this.handleAddAssignee({assignee: item})}
          />
        </View>
        <View style={{zIndex: 0}}>
          {_.map(assignees, (assignee, index) => (
            <View key={`${assignee.id}-${index}`} style={{height: K.button.height, paddingHorizontal: K.spacing, flexDirection: 'row', alignItems: 'center', ...(index === assignees.length - 1 ? {borderBottomRightRadius: K.borderRadius, borderBottomLeftRadius: K.borderRadius} : {marginBottom: 1}), borderRadius: 0, backgroundColor: lib.colors.colorFor({user: assignee})}}>
              <Text {...s.shareLeftText}>{assignee.name}</Text>
              <TouchableOpacity onPress={() => this.props.resourceKey === 'processInstance' ? this.removeAssignee({assignee}) : this.setState({isRemovingDefaultAssignee: assignee, removeAllDefaultAssignments: 0, removeAssignments: 0, removeAsCollaborator: 0})} style={{position: 'absolute', right: 15}}>
                <Image source={deleteIcon} {...s.deleteImage}/>
              </TouchableOpacity>
            </View>
          ))}
        </View>
        {this.state.isRemovingDefaultAssignee && (
          <Popup onClose={() => this.setState({isRemovingDefaultAssignee: false})}>
            {this.state.isLoading ? (
              <View style={{paddingVertical: K.spacing}}>
                <Loading text='Removing default assignee'/>
              </View>
            ) : (<>
              {isAssignedToOtherSteps && (
                <LabelledView
                  styles={{outerView: {marginBottom: K.spacing * 2}}}
                  label={`In addition to "${this.props.processStep.title || '?'}" Step:`}
                >
                  <CheckboxInput
                    style={{marginTop: 1}}
                    label={`Also remove from ${_.join(_.map(otherAssignedSteps, step => `"${step.title || '?'}"`), ', ')}`}
                    value={this.state.removeAllDefaultAssignments}
                    onChange={({value}) => this.setState({removeAllDefaultAssignments: value})}
                  />
                </LabelledView>
              )}
              <LabelledView
                label={`In addition to future projects:`}
                styles={{outerView: {marginBottom: K.spacing * 1}}}
              >
                <CheckboxInput
                  style={{marginTop: 1}}
                  label='Remove assignments on incomplete projects'
                  value={this.state.removeAssignments}
                  onChange={({value}) => this.state.removeAsCollaborator ? alert('This option is required if you want to stop notifications.') : this.setState({removeAssignments: value})}
                />
                {!!(removeAllDefaultAssignments || !isAssignedToOtherSteps) && (
                  <CheckboxInput
                    style={{marginTop: 1}}
                    label='Stop notifications on incomplete projects'
                    value={this.state.removeAsCollaborator}
                    onChange={({value}) => this.setState({removeAsCollaborator: value, removeAssignments: value})}
                  />
                )}
              </LabelledView>
              <View style={{flexDirection: 'row', marginTop: K.spacing}}>
                <Button style={{flex: 1, marginRight: K.margin}} label='Cancel' onPress={() => this.setState({isRemovingDefaultAssignee: false})}/>
                <Button style={{flex: 1}} dark label='Remove' onPress={() => this.removeAssignee({assignee: this.state.isRemovingDefaultAssignee})}/>
              </View>
            </>)}
          </Popup>
        )}
      </View>
    );
  }
}

export default withRouter(connect({
  mapState: (state, ownProps) => ({
    ..._.pick(state, ['session', 'activeView']),
    usersById: state.resources.users.byId,
    involvedUsers: ownProps.resourceKey === 'processInstances' && involvedUsersFor({processInstance: ownProps.processInstance, usersById: state.resources.users.byId}),
  }),
  mapDispatch: {
    setActiveView,
    ..._.pick(resourceActions.processInstances, ['trackProcessInstances', 'updateProcessInstance', 'destroyProcessInstance']),
    ..._.pick(resourceActions.processSteps, ['updateProcessStep', 'trackProcessSteps']),
    ..._.pick(resourceActions.users, ['trackUsers']),
  }
})(TeamPickerInput));
