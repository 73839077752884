import K from '~/k';

var s = {};

s.K = {
  rowHeight: K.rowHeight,
  instanceRowHeight: K.instanceRowHeight,
  stickyColumnWidth: K.stickyColumnWidth,
  dotSize: K.step.small.diameter,
  dotMargin: K.step.small.margin
};

export default s;
