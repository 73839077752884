import { React, Component, resourceActions, View, styleSpread, Popup, Text, TouchableOpacity, LabelledView, TextInput, PickerInput} from '~/components/index.js';
import _ from '@symbolic/lodash'
import { connect } from '@symbolic/redux';
import lib, { api } from '@symbolic/lib';
import { setActiveView } from '~/redux/active-view/active-view';
import { withRouter } from 'react-router-native';
import { Button, CheckboxInput} from '@symbolic/rn-lib';

import K from '~/k';
import styles from './process-field-settings-popup.styles.js';
import trashIcon from '~/assets/trash-icon-black.png';
import addIcon from '~/assets/create-icon-white.png';

var s = styleSpread(styles);

class ProcessFieldSettingsPopup extends Component {
  state = {
    title: '',
    type: 'text'
  }

  handleCreate = async ({processInstance}) => {
    await this.props.handleCreateField({processInstance, ..._.pick(this.state, ['type', 'title'])});
  }

  handleDelete = () => {
    this.props.onClose();

    this.props.destroyProcessField({id: this.props.processField.id});
  }

  updateProcessFieldSetting = ({propKey, value}) => {
    var id = this.props.processField.id;

    if (propKey === 'data') {
      var data = _.cloneDeep(this.props.processField.data || {});

      data = {...data, value};

      this.props.updateProcessField({id, props: {data}});
    }
    else {
      this.props.updateProcessField({id, props: {[propKey]: value}});
    }
  }

  updateProcessFieldData = ({propKey, value}) => {
    var id = this.props.processField.id;
    var data = _.cloneDeep(this.props.processField.data || {});

    data = {...data, [propKey]: value};

    this.props.updateProcessField({id, props: {data}});
  }

  generateNewOptionValue = ({existingValues}) => {
    const valueSet = new Set(existingValues);

    if (!valueSet.has(existingValues.length + 1)) return existingValues.length + 1;

    const maxValue = _.max(existingValues);
    const newValue = maxValue + 1;

    if (valueSet.has(newValue)) throw new Error(`Unable to generate a new unique option value. Existing values: ${existingValues.join(',')}. Attempted value: ${newValue}.`);

    return newValue;
  };

  createProcessFieldOption = () => {
    var options = _.cloneDeep(this.props.processField.data.options || {});
    var newValue = this.generateNewOptionValue({existingValues: _.map(options, 'value')});
    var newOption = {value: newValue, title: '', isDeleted: 0};

    options.push(newOption);

    this.updateProcessFieldData({propKey: 'options', value: options});
  };

  updateProcessFieldOption = ({value, title}) => {
    var options = _.cloneDeep(this.props.processField.data.options || []);
    var index = _.findIndex(options, {value});
    var newOption = {value, title, isDeleted: 0};

    options.splice(index, 1, newOption);

    this.updateProcessFieldData({propKey: 'options', value: options});
  };

  updateProcessFieldOptionRank = ({value, option}) => {
    var options = _.cloneDeep(this.props.processField.data.options || {});
    var activeOptions = _.filter(options, option => !option.isDeleted);
    var oldIndex= _.findIndex(activeOptions, option);
    var newIndex = _.parseInt(value) - 1;

    if (newIndex > options.length - 1) newIndex = activeOptions.length - 1;
    if (newIndex < 0) newIndex = 0;

    if (oldIndex !== newIndex && !isNaN(newIndex)) {
      var newActiveOptionsOrder = _.arrayMove([...activeOptions], oldIndex, newIndex);
      var deletedOptions = _.filter(options, option => option.isDeleted);;

      options = [...newActiveOptionsOrder, ...deletedOptions];

      this.updateProcessFieldData({propKey: 'options', value: options});
    }
  }

  updateProcessFieldRank = ({value, processField}) => {
    var updates = [];
    var newRank = _.parseInt(value) - 1;
    var oldRank = _.findIndex(_.sortBy(this.props.processFields, 'rank'), {id: processField.id});

    var oldIds = _.map(_.sortBy(this.props.processFields, 'rank'), 'id');

    if (newRank > oldIds.length - 1) newRank = oldIds.length - 1;
    if (newRank < 0) newRank = 0;

    if (oldRank !== newRank && !isNaN(newRank)) {
      var newIds = _.arrayMove([...oldIds], oldRank, newRank);

      _.forEach(newIds, (id, rank) => updates.push({where: {id}, props: {rank}}));

      this.props.updateProcessFields({updates});
    }
  }

  deleteProcessFieldOption = ({deletedIndex}) => {
    var options = _.cloneDeep(this.props.processField.data.options || {});

    options[deletedIndex] = {...options[deletedIndex],isDeleted: 1};

    this.updateProcessFieldData({propKey: 'options', value: options});
  }

  render() {
    var {processInstance, processType, processField, processFields, mode} = this.props;

    return (
      <Popup scrollEnabled onClose={this.props.onClose}>
        <View style = {{paddingVertical: K.spacing}}>
          {mode === 'create' && (
            <View>
              <Text style={{...K.fonts.pageHeader, marginLeft: K.margin * 2, marginBottom: K.spacing * 2}}>Create New Field</Text>
              <LabelledView gray label='Field Type' styles={{outerView: {marginVertical: K.spacing}}}>
                <PickerInput
                  basic
                  flex
                  style={{height: K.inputHeight}}
                  buttonStyle={{backgroundColor: K.colors.gray}}
                  showDownArrow={true}
                  options={[
                    {value: 'text', title: 'Text'},
                    {value: 'currency', title: 'Currency'},
                    {value: 'dropdown', title: 'Dropdown List'},
                    //{value: 'number', title: 'Number'},
                  ]}
                  value={this.state.type}
                  onChange={({value})=> this.setState({type: value})}
                />
              </LabelledView>
              <TextInput
                grayLabelledView
                label='Title'
                placeholder='ENTER TITLE'
                value={this.state.title}
                onChange={({value})=> this.setState({title: value})}
              />
              <Button
                mode='dark'
                label='Create Field'
                style={{marginTop: K.spacing * 2, alignSelf: 'stretch'}}
                onPress={() => this.handleCreate({processInstance})}
              />
            </View>
          )}
          {mode === 'reorder' && (
            <View>
              <Text style={{...K.fonts.pageHeader, marginLeft: K.margin * 2, marginBottom: K.spacing * 2}}>Reorder Fields</Text>
              {_.map(_.orderBy(processFields, 'rank', 'asc'), (processField, index) => {
                return (
                  <View key={processField.id} style={{backgroundColor: K.colors.gray, borderRadius: K.borderRadius, alignItems: 'stretch', justifyContent: 'center', flexDirection: 'row', marginBottom: K.margin}}>
                    <TextInput
                      value={index + 1}
                      onChange={({value})=> this.updateProcessFieldRank({value, processField})}
                      style={{color: 'rgb(179, 179, 179)', alignSelf: 'stretch', paddingLeft: 0, width: 36, textAlign: 'center', paddingHorizontal: 0, borderBottomRightRadius: 0, borderTopRightRadius: 0}}
                      selectTextOnFocus
                    />
                    <Text style={{flex: 1, paddingVertical: 8, flex: 1, alignSelf: 'center'}}>{processField.title}</Text>
                  </View>
                );
              })}
              <Button
                mode='dark'
                label='Done'
                style={{marginTop: K.spacing * 2, alignSelf: 'stretch'}}
                onPress={() => this.props.onClose()}
              />
            </View>
          )}
          {mode === 'update' && (
            <View style={{display: 'flex'}}>
              <Text style={{...K.fonts.pageHeader, marginLeft: K.margin * 2, marginBottom: K.spacing *2}}>Update Field</Text>
              <TextInput
                grayLabelledView
                label='Title'
                value={processField.title ? processField.title : this.state.title}
                onChange={({value})=> this.updateProcessFieldSetting({propKey: 'title', value})}
              />
              {processField.type === 'dropdown' && (
                <LabelledView label='Options' styles={{outerView: {marginVertical: K.spacing * 2}}}>
                  {_.map(_.reject(processField.data.options, {isDeleted: 1}), (option, index) => {

                    return (
                      <View style={{alignItems: 'stretch', flexDirection: 'row', marginBottom: K.margin}} key={`${option.title}-${index}`}>
                        <TextInput
                          value={_.findIndex(_.reject(processField.data.options, {isDeleted: 1}), option) + 1}
                          onChange={({value})=> this.updateProcessFieldOptionRank({value , option})}
                          style={{color: 'rgb(179, 179, 179)', alignSelf: 'stretch', paddingLeft: 0, width: 36, textAlign: 'center', paddingHorizontal: 0, borderBottomRightRadius: 0, borderTopRightRadius: 0}}
                        />
                        <TextInput
                          placeholder='ENTER TITLE'
                          value={option.title}
                          onChange={({value})=> this.updateProcessFieldOption({value: option.value, title: value})}
                          style={{flex: 1, borderRadius: 0}}
                        />
                        <Button
                          icon={trashIcon}
                          style={{borderBottomLeftRadius: 0, borderTopLeftRadius: 0, opacity: 0.5}}
                          onPress={() => this.deleteProcessFieldOption({deletedIndex: _.findIndex(processField.data.options, option)})}
                        />
                      </View>
                    );
                  })}
                  <Button
                    icon={addIcon}
                    mode='dark'
                    style={{marginTop: K.margin}}
                    onPress={this.createProcessFieldOption}
                  />
                </LabelledView>
              )}
              <LabelledView label='Size' gray styles={{outerView: {zIndex: 1}, innerView: {paddingTop: 6}}}>
                <PickerInput
                  showDownArrow
                  basic
                  flex
                  options={[
                    {value: 1, title: '1'},
                    {value: 2, title: '2'},
                    {value: 3, title: '3'},
                    {value: 4, title: '4'},
                    {value: 5, title: '5'},
                    {value: 6, title: '6'},
                  ]}
                  value={_.get(processField, 'sizeMultiplier', 1)}
                  onChange={({value}) => this.updateProcessFieldSetting({propKey: 'sizeMultiplier', value})}
                />
              </LabelledView>
              {processField.type === 'text' && (
                <CheckboxInput
                  label={'Multiline' }
                  style={{}}
                  value={_.get(processField.data, 'multiline', 0)}
                  onChange={({value}) => this.updateProcessFieldData({propKey: 'multiline', value})}
                />
              )}
              {!processType.isSingleUse && (
                <CheckboxInput
                  label={'Show in Category View'}
                  style={{marginTop: K.margin}}
                  value={_.get(processField, 'visibleInCategoryView', 0)}
                  onChange={({value}) => this.updateProcessFieldSetting({propKey: 'visibleInCategoryView', value})}
                />
              )}
              <CheckboxInput
                label={processField.isDisabled === 0 ? 'Disable Field' : 'Field Disabled' }
                style={{marginTop: K.margin}}
                value={_.get(processField, 'isDisabled', 0)}
                onChange={({value}) => this.updateProcessFieldSetting({propKey: 'isDisabled', value})}
              />
              <CheckboxInput
                label={'Visible to outside collaborators'}
                style={{marginTop: K.margin}}
                value={_.get(processField, 'isInternalOnly', 1) === 1 ? 0 : 1}
                onChange={({value}) => this.updateProcessFieldSetting({propKey: 'isInternalOnly', value: value === 1 ? 0 : 1})}
              />
              <Button
                mode='dark'
                label= 'Done'
                style={{marginTop: K.spacing * 2, alignSelf: 'stretch'}}
                onPress={() => this.props.onClose()}
              />
              <Button
                label='Delete'
                style={{backgroundColor: K.colors.deleteRed, alignSelf: 'stretch', marginTop: K.margin}}
                onPress={this.handleDelete}
              />
            </View>
          )}
        </View>
      </Popup>
    );
  }
}

export default withRouter(connect({
  mapDispatch: {
    ..._.pick(resourceActions.processFields, ['destroyProcessField', 'updateProcessFields']),
  }
})(ProcessFieldSettingsPopup));
