import { React, Component, _, View, resourceActions, Button, Text, TextInput, PickerInput, styleSpread, Popup } from '~/components/index.js'; //eslint-disable-line
import { confirm, Tooltip, DateInput, LabelledView } from '@symbolic/rn-lib';
import { Fragment } from 'react';
import { api } from '@symbolic/lib';
import { withRouter } from 'react-router-native';
import { connect } from '@symbolic/redux';
import { setActiveView, setEvent } from '~/redux/index.js';

import K from '~/k';
import moment from 'moment';

class ProcessInstancePopup extends Component {
  state = {};

  handleInputChange = ({key, value}) => {
    this.props.updateProcessInstance({id: this.props.processInstance.id, props: {[key]: value, wasModified: 1}});
  }

  handleDeletePress = async () => {
    var {processInstance, processType} = this.props;
    var {id, title} = processInstance;

    if (await confirm('', 'Are you sure you want to DELETE this project?')) {
      this.props.setActiveView({data: {deletedProcessInstanceId: processInstance.id}}); //WARNING hack to avoid 404 redirect

      setTimeout(async () => {
        await this.props.destroyProcessInstance({id: processInstance.id});

        if (processType.isSingleUse) await this.props.destroyProcessType({id: processType.id});

        setTimeout(() => {
          this.props.back({activeProcessInstance: processInstance, activeProcessInstanceType: processType});

          setTimeout(() => this.props.setActiveView({data: {deletedProcessInstanceId: null}}));

          this.props.triggerUndoPopup({event: {
            message: `${title ? `The project "${title}"` : 'That project'} has been deleted`,
            action: () => {
              this.props.trackProcessInstances({processInstances: [processInstance]});

              if (processType.isSingleUse) this.props.trackProcessTypes({processTypes: [processType]});

              setTimeout(() => {
                this.props.updateProcessInstance({id, props: {deleted: 0}});

                if (processType.isSingleUse) this.props.updateProcessType({id: processType.id, props: {deleted: 0}});
              });
            }
          }});
        });
      });
    }
  }

  handleSizeChange = async ({value}) => {
    var size = parseFloat(value);

    if (!isNaN(size) && size >= 0) {
      this.props.updateProcessInstance({id: this.props.processInstance.id, props: {size, wasModified: 1}});
    }
    else {
      alert('Please enter a valid number');
    }
  }

  handleStartDateChange = async ({value}) => {
    var startDate = value ? value.format('YYYY-MM-DD HH:mm:ss') : value;

    this.props.updateProcessInstance({id: this.props.processInstance.id, props: {startDate, wasModified: 1}});
  }

  handleArchivePress = async () => {
    var {processInstance} = this.props;

    this.props.updateProcessInstance({id: processInstance.id, props: {isArchived: processInstance.isArchived ? 0 : 1}});
  }

  close = () => {
    this.props.onClose();
  }

  toggleIsSingleUse = async () => {
    if (await confirm('', 'Standardizing a project is useful when you follow the same process repeatedly.\n\nWould you like to continue?')) {
      await this.props.updateProcessType({id: this.props.processType.id, props: {isSingleUse: 0}});

      var stepUpdates = _.map(this.props.processType.processSteps, processStep => {
        return {where: {id: processStep.id}, props: {defaultNotes: _.get(this.props.processInstance, `stepNotes.${processStep.id}`, '')}};
      });

      await this.props.updateProcessSteps({updates: stepUpdates});

      setTimeout(() => this.props.history.push(`/categories/${this.props.processType.id}`));

      //TODO undo
    }
  }

  duplicateProcessType = async () => {
    if (await confirm('', 'Are you sure?\n\nPolydot will use these steps as a starting point for a new, uncategorized project.\n\nIf you want one category with multiple projects that follow the same steps, you should standardize this project instead.')) {
      var {processType: originalProcessType} = this.props;
      var {orgId} = originalProcessType;

      try {
        var processType = await api.create('processType', {
          title: originalProcessType.title,
          pluralTitle: `${originalProcessType.pluralTitle} (copy)`,
          createdByUserId: this.props.session.user.id,
          instanceCount: 1,
          isSingleUse: 1,
          orgId
        });

        var originalProcessStepsByLevel = _.groupBy(originalProcessType.processSteps, 'level');

        var processStepPropsFor = (processStep, additionalProps) => ({
          ..._.omit(processStep, ['parentStepId', 'processTypeId', 'id', 'lastUpdated', 'lastUpdatedBy', 'created']),
          processTypeId: processType.id,
          ...additionalProps
        });

        var level0ProcessSteps = [], level1ProcessSteps = [], level2ProcessSteps = [];

        level0ProcessSteps = await api.create('processSteps', _.map(originalProcessStepsByLevel['0'], processStep => {
          return processStepPropsFor(processStep);
        }));

        if (originalProcessStepsByLevel['1'] && originalProcessStepsByLevel['1'].length) {
          level1ProcessSteps = await api.create('processSteps', _.map(originalProcessStepsByLevel['1'], processStep => {
            var originalParentStepIndex = _.findIndex(originalProcessStepsByLevel['0'], {id: processStep.parentStepId});
            var newParentStep = level0ProcessSteps[originalParentStepIndex];

            return processStepPropsFor(processStep, {parentStepId: newParentStep.id});
          }));

          if (originalProcessStepsByLevel['2'] && originalProcessStepsByLevel['2'].length) {
            level2ProcessSteps = await api.create('processSteps', _.map(originalProcessStepsByLevel['2'], processStep => {
              var originalParentStepIndex = _.findIndex(originalProcessStepsByLevel['1'], {id: processStep.parentStepId});
              var newParentStep = level1ProcessSteps[originalParentStepIndex];

              return processStepPropsFor(processStep, {parentStepId: newParentStep.id});
            }));
          }
        }

        var processInstance = await api.create('processInstance', {
          title: `Untitled ${processType.title} (copy)`,
          orgId,
          ownerId: this.props.session.user.id,
          processTypeId: processType.id,
          steps: {},
          localId: 1,
          wasModified: 0
        });

        await this.props.trackProcessSteps({processSteps: [...level0ProcessSteps, ...level1ProcessSteps, ...level2ProcessSteps]});
        await this.props.trackProcessTypes({processTypes: [processType]});
        await this.props.trackProcessInstances({processInstances: [processInstance]});

        setTimeout(() => {
          this.props.history.push(`/projects/${processInstance.id}`);
        }, 100);
      }
      catch (error) {
        console.error(error);

        this.setState({isLoading: false});
      }
    }
  }

  async transferCategory({newOrgId}) {
    var {processType} = this.props;

    if (await confirm('', 'Are you sure you want to transfer this category to a new workspace? All assignments will be removed.')) {
      await api.request({uri: '/process-types/transfer', body: {newOrgId, processTypeId: processType.id}});

      await this.props.trackProcessTypes({processTypes: [_.set(processType, 'processTypeId', newOrgId)]});
    }
  }

  render() {
    var {processInstance, processType, session} = this.props;
    var isOwner = processInstance.ownerId === this.props.session.user.id;
    var canEditProcessInstance = (processInstance.isPrivate ? isOwner : _.find(this.props.session.orgs, {id: processInstance.orgId})) && !this.props.isViewingSharee;

    return (
      <Popup
        scrollEnabled
        onClose={this.close}
        {...(K.orientation === 'landscape' ? {height: '75%'} : {})}
      >
        {processType.id === 187 && canEditProcessInstance && (
          <TextInput
            grayLabelledView
            label='Project Size / Value'
            value={processInstance.size}
            onChange={this.handleSizeChange}
          />
        )}
        <LabelledView label='Actions' styles={{outerView: {marginTop: K.spacing}}}>
          {!!processType.isSingleUse && canEditProcessInstance && (
            <Tooltip left text='Create a category for many projects with these same steps'>
              <Button onPress={this.toggleIsSingleUse} style={{alignItems: 'flex-start', paddingHorizontal: K.spacing, marginBottom: K.margin}} label='Standardize into category'/>
            </Tooltip>
          )}
          <Tooltip left text={`Use these steps as a starting point for a new, uncategorized project`}>
            <Button alignLeft label='Copy & Use as template' style={{marginBottom: K.spacing * 2}} onPress={this.duplicateProcessType}/>
          </Tooltip>
          {canEditProcessInstance && (
            <Fragment>
              <Button
                alignLeft
                style={{marginBottom: K.margin}}
                onPress={this.handleArchivePress}
                label={processInstance.isArchived ? 'Unarchive' : 'Archive'}
              />
              <Button
                style={{backgroundColor: K.colors.deleteRed}}
                onPress={this.handleDeletePress}
                label='Delete'
                alignLeft
              />
            </Fragment>
          )}
          {!!processType.isSingleUse && (session.orgs.length > 1) && <LabelledView label='Transfer project to another Workspace' styles={{outerView: {marginTop: K.spacing * 2}}}>
            <Tooltip left text={'Choose another workspace you are a part of to move this project to - all assignments will be removed'}>
              <PickerInput
                basic
                onChange={({value}) => this.transferCategory({newOrgId: value})}
                options={_.map(session.orgs, ({id, title}) => ({value: id, title}))}
                value={processType.orgId}
                showDownArrow
              />
            </Tooltip>
          </LabelledView>}
        </LabelledView>
      </Popup>
    );
  }
}

export default withRouter(connect({
  mapState: (state, ownProps) => {
    var {resources, session} = state;

    var processInstance = _.get(resources.processInstances.byId, ownProps.id);

    return {processInstance, session};
  },
  mapDispatch: {
    setActiveView, setEvent,
    ..._.pick(resourceActions.processInstances, ['trackProcessInstances', 'updateProcessInstance', 'destroyProcessInstance']),
    ..._.pick(resourceActions.processTypes, ['updateProcessType', 'destroyProcessType', 'trackProcessTypes']),
    ..._.pick(resourceActions.processSteps, ['trackProcessSteps', 'updateProcessSteps']),
  }
})(ProcessInstancePopup));
