import K from '~/k';

var s = {};

s.K = {
  rowHeight: K.rowHeight,
  instanceRowHeight: K.instanceRowHeight,
  stickyColumnWidth: K.stickyColumnWidth,
  dotSize: K.step.small.diameter,
  dotMargin: K.step.small.margin
};

s.page = {
  height: '100%',
  flex: 1,
  ...(K.isWeb ? {} : {paddingHorizontal: K.spacing})
};
  s.header = {
    marginTop: 30,
    alignSelf: 'center',
    alignItems: 'center',
    ...(K.isWeb ? {width: 400} : {})
  };
    s.homeIconContainer = {
      marginBottom: 10,
    };
      s.homeIcon = {
        width: 30,
        height: 30
      };
    s.headerTitle = {
      ...K.fonts.label,
      textTransform: 'none',
      fontSize: 20,
      marginBottom: 20,
      // letterSpacing: 4
    };
    s.addButtonContainer = {
      justifyContent: 'center',
      // marginTop: K.spacing
      position: 'absolute',
      // left: K.spacing * 2,
      pointerEvents: 'none'
    };
      s.addButton = {
        backgroundColor: 'black',
        borderRadius: K.calc(70 / 2),
        width: K.calc(70),
        height: K.calc(70),
        pointerEvents: 'auto',
        ...(!K.isWeb && {
          top: -1,
          left: -1
        })
      };
  s.processTypeSelectorContainer = {
    alignItems: 'center',
    // marginTop: K.spacing
  };

  s.scrollViewContent = {
    paddingBottom: K.spacing * 7,
    paddingTop: K.spacing
  };
  s.processInstancesContainer = {
    marginBottom: K.spacing * 1,
    flex: 1,
    // alignSelf: K.isWeb ? 'center' : 'stretch',
    alignItems: 'center', //'flex-start',
  };
    s.processInstancesLabelContainer = {
      height: s.K.rowHeight,
      width: s.K.stickyColumnWidth,
      justifyContent: 'center',
    };
    s.processInstancesLabel = {
      ...K.fonts.su,
      paddingHorizontal: K.spacing,
    };
    s.processInstances = {
      flex: 1,
      marginBottom: 0,//60
    };
      s.processInstanceRow = {
        flexDirection: 'row',
        marginTop: 1
      };
        s.processInstanceLink = {
          height: s.K.instanceRowHeight,
          justifyContent: 'center',
          width: s.K.stickyColumnWidth,
          paddingHorizontal: K.spacing
        };
        s.processInstance = {
          paddingLeft: K.spacing,
          paddingVertical: K.isWeb ? 5 : 8,
          flexGrow: 1,
          flex: 1,
          height: s.K.instanceRowHeight,
          justifyContent: 'flex-start',
          alignItems: K.isWeb ? 'center' : 'flex-start',
          borderRadius: 0,
          flexDirection: K.isWeb ? 'row' : 'column',
          // backgroundColor: K.colors.gray,
          borderTopLeftRadius: K.borderRadius,
          borderBottomLeftRadius: K.borderRadius
        };
          s.statusDot = {
            marginRight: K.spacing,
            width: 15,
            height: 15,
            borderRadius: 15
          };
        s.deleteButton = {
          marginLeft: 1,
          width: K.button.width,
          alignSelf: 'stretch',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: K.colors.gray,
          borderTopRightRadius: K.borderRadius,
          borderBottomRightRadius: K.borderRadius
        }
          s.deleteImage = {
            zIndex: 1,
            width: K.calc(20),
            height: K.calc(20),
            opacity: 0.5
          };
    s.processInstancesPlaceholder = {
      height: s.K.rowHeight,
      borderRadius: K.borderRadius,
      justifyContent: 'center',
      paddingHorizontal: K.spacing,
      width: s.K.stickyColumnWidth,
      marginTop: 1,
      opacity: 0.8,
      // ...(K.isWeb ? {width: 600} : {alignSelf: 'stretch'})
    };
      s.processInstancesPlaceholderText = {
        opacity: 0.5,
        // textAlign: 'center',
        // paddingHorizontal: K.spacing,
        width: s.K.stickyColumnWidth
      };

export default s;
