import lib, { api } from '@symbolic/lib';

import { React, Component, _, View, resourceActions, Text, TextInput, styleSpread, Popup, LabelledView, Image, Tooltip } from '~/components/index.js'; //eslint-disable-line
import { withRouter } from 'react-router-native';
import { TouchableOpacity } from 'react-native';
import { connect } from '@symbolic/redux';
import { Button, AutocompleteInput, CheckboxInput, Label, confirm } from '@symbolic/rn-lib';
import Subscriptions from '~/components/subscriptions/subscriptions.js';
import { setActiveView } from '~/redux/active-view/active-view';
import { triggerNotifiedIndicator } from '~/helpers/notified-indicator';
import { userSuggestionsFor, involvedUsersFor, updateInvolvedUsers, activeInvolvedUsersFor } from '~/helpers/process-instances';
import { getCanModifyProcessType } from '~/helpers/process-types';

import K from '~/k';
import deleteIcon from '~/assets/x-icon.png';
import ownerIcon from '~/assets/owner-icon.png';
import privateIcon from '~/assets/privacy-eye-closed.png';
import publicIcon from '~/assets/privacy-eye-open.png';
import styles from './publish-popup.styles';

var s = styleSpread(styles);

class PublishPopup extends Component {
  state = {
    searchTerm: '',
    hideAutocomplete: true,
    shareNote: '',
    pendingSharees: [],
    justNotified: false,
  };

  handlePublishPress = async () => {
    var {processInstance, activeView, session, usersById} = this.props;
    var {pendingSharees} = this.state;

    //TODO
    if (!processInstance.isPublished) {
      var sharees = _.reject(activeInvolvedUsersFor({usersById, processInstance}), {id: processInstance.ownerId});

      await api.request({uri: '/process-instances/publish', body: {sharees, processInstanceId: this.props.processInstance.id, shareNote: this.state.shareNote}});

      this.setState({justNotified: true});

      setTimeout(() => {
        this.setState({justNotified: false});
      }, 1000);
    }
    else {
      await api.request({uri: '/process-instances/publish', body: {processInstanceId: this.props.processInstance.id, shareNote: this.state.shareNote, sharees: pendingSharees}});

      await updateInvolvedUsers({mode: 'set', users: pendingSharees, ..._.pick(this.props, ['processInstance', 'updateProcessInstance'])});

      this.setState({pendingSharees: [], shareNote: '', justNotified: true});

      setTimeout(() => this.setState({justNotified: false}), 1000);
    }

    var usersToNotify = processInstance.isPublished ? pendingSharees : _.reject(activeInvolvedUsersFor({usersById, processInstance}), {id: this.isOwner ? processInstance.ownerId : undefined});

    if (_.map(usersToNotify).length) {
      triggerNotifiedIndicator({setActiveView: this.props.setActiveView, activeView, notificationTriggerData: {type: 'publish', processInstanceId: processInstance.id, numberOfCollaborators: _.map(usersToNotify).length}});
    }

    this.props.updateProcessInstance({id: processInstance.id, props: {isPublished: 1, wasModified: 1}});
  }

  handleAddSharee = async ({sharee}) => {
    if (!this.props.processInstance.isPublished) {
      await api.request({uri: '/process-instances/share', body: {
        processInstanceId: this.props.processInstance.id,
        inviteeEmail: sharee.email,
        shouldNotify: false
      }});

      await updateInvolvedUsers({mode: 'set', users: [sharee], ..._.pick(this.props, ['processInstance', 'updateProcessInstance'])});
    }
    else {
      this.setState({pendingSharees: [sharee, ...this.state.pendingSharees]});
    }

    this.setState({hideAutocomplete: true, searchTerm: '', pressingAutofill: false});
  }

  handleAssignEmail = async () => {
    var shareeData = await api.request({uri: '/process-instances/get-or-create-assignee', body: {processInstanceId: this.props.processInstance.id, inviteeEmail: this.state.searchTerm}});

    var {assignee} = shareeData.data;

    this.props.trackUsers({users: [assignee]});

    await this.handleAddSharee({sharee: assignee});
  }

  setProcessInstanceOwner = async ({sharee}) => {
    if (await confirm('', `Are you sure you want to transfer ownership of this project to ${sharee.name}?`)) {
      this.props.updateProcessInstance({id: this.props.processInstance.id, props: {ownerId: sharee.id}});
    }
  }

  removeSharee = async ({sharee}) => {
    await api.request({uri: '/process-instances/unshare', body: {
      processInstanceId: this.props.processInstance.id,
      emailToUnshare: sharee.email
    }});

    await updateInvolvedUsers({mode: 'unset', users: [sharee], ..._.pick(this.props, ['processInstance', 'updateProcessInstance'])});
  }

  toggleIsPrivate = () => {
    var {processInstance} = this.props;

    var {isPrivate} = processInstance;

    this.props.updateProcessInstance({id: this.props.processInstance.id, props: {isPrivate: isPrivate ? 0 : 1}});
  }

  get autocompleteOptions() {
    return userSuggestionsFor({
      ..._.pick(this.props, ['session', 'usersById', 'processInstance']),
      searchTerm: this.state.searchTerm,
      filterUserIds: [
        ..._.map(this.state.pendingSharees, 'id'),
        ..._.map(this.props.involvedUsers, 'id'),
        ...(this.isOwner ? [this.props.processInstance.ownerId] : [])
      ], //TODO involved users?
      orgUsersOnly: true
    });
  }

  get isOwner() {
    return this.props.processInstance.ownerId === this.props.session.user.id;
  }

  render() {
    var {processInstance, processType, session, showToggleIsViewingShareeButton, isViewingSharee, usersById} = this.props;
    var {hideAutocomplete, searchTerm, pendingSharees, shareNote, justNotified} = this.state;
    var {autocompleteOptions} = this;
    var {user} = session;

    var canModifyProcessType = getCanModifyProcessType({processType, session});
    var listedUsers = _.reject(activeInvolvedUsersFor({usersById: this.props.usersById, processInstance}), {id: undefined});

    var showAutoPublish = !processType.isSingleUse && canModifyProcessType;

    return (
      <Popup
        scrollEnabled
        onClose={this.props.onClose}
        {...(K.orientation === 'landscape' ? {height: '75%'} : {})}
      >
        <View style={{zIndex: 1}}>
          <View style={{flexDirection: 'row', marginBottom: K.spacing * 3}}>
            <Tooltip left style={{flex: 1}} text={(processInstance.isPublished && pendingSharees.length < 1) ? 'Please add collaborators before pressing share' : ''}>
              <TouchableOpacity disabled={processInstance.isPublished && pendingSharees.length < 1} onPress={this.handlePublishPress} style={{height: K.step.height, backgroundColor: 'black', borderRadius: processInstance.isPublished ? K.borderRadius : 100, justifyContent: 'center', alignItems: 'center'}}>
                <Label style={{opacity: 1, color: 'white'}}>{(processInstance.isPublished ? (justNotified ? 'Sharing...' : (pendingSharees.length ? `Share with (${pendingSharees.length})` : 'Share')) : (justNotified ? 'Publishing...' : 'Publish (Enable Notifications)'))}</Label>
              </TouchableOpacity>
            </Tooltip>
            {!!processInstance.isPublished && <Tooltip text={processInstance.isPrivate ? `Private to just you and collaborators` : 'Visible in your workspace and to collaborators'}>
              <Button disabled={!processInstance.isPublished} style={{backgroundColor: K.colors.gray, marginLeft: K.margin, height: K.step.height}} icon={processInstance.isPrivate ? privateIcon : publicIcon} onPress={this.toggleIsPrivate}/>
            </Tooltip>}
          </View>
          <TextInput
            grayLabelledView
            multiline
            value={shareNote}
            label='note'
            placeholder='(OPTIONAL)'
            standardAutoheightStyles
            onChange={({value}) => this.setState({shareNote: value})}
          />
          <LabelledView gray label='Invite' styles={{outerView: {zIndex: 1}}}>
            <AutocompleteInput
              data={autocompleteOptions}
              placeholder={'NAME OR EMAIL (NO SIGN UP NECESSARY)'}
              inputValue={searchTerm}
              setInputValue={(value) => this.setState({searchTerm: value})}
              autocompleteIsVisible={!hideAutocomplete}
              setAutocompleteIsVisible={(value) => this.setState({hideAutocomplete: !value})}
              onSelect={item => item.id === -1 ? this.handleAssignEmail() : this.handleAddSharee({sharee: item})}
            />
          </LabelledView>
          <View style={{zIndex: 0}}>
            {!!processInstance.isPublished && !!pendingSharees.length && (
              <LabelledView label='Pending Collaborators' styles={{outerView: {marginTop: K.spacing}}}>
                {_.map(pendingSharees, (sharee, index) => (
                  <View key={`${sharee.id}-${index}`} style={{
                    ...styles.shareRow,
                    borderRadius: 0,
                    marginBottom: index === pendingSharees.length - 1 ? 0 : 1,
                    backgroundColor: lib.colors.colorFor({user: sharee}),
                    ...(index === pendingSharees.length - 1 ? {borderBottomRightRadius: K.borderRadius, borderBottomLeftRadius: K.borderRadius} : {marginBottom: 1}),
                    ...(index === 0 ? {borderTopRightRadius: K.borderRadius, borderTopLeftRadius: K.borderRadius} : {})
                  }}>
                    <Text {...s.shareLeftText}>{sharee.name}</Text>
                    <TouchableOpacity onPress={() => this.setState({pendingSharees: _.reject(pendingSharees, sharee)})} style={{position: 'absolute', right: 15}}>
                      <Image source={deleteIcon} {...s.deleteImage}/>
                    </TouchableOpacity>
                  </View>
                ))}
              </LabelledView>
            )}
            {!!listedUsers.length && (
              <LabelledView label={processInstance.isPublished ? 'Collaborators' : 'Pending Collaborators'} styles={{outerView:{marginVertical: K.spacing}}}>
                {_.map(listedUsers, (sharee, index) => (
                  <View key={`${sharee.id}-${index}`} style={{
                      ...styles.shareRow,
                      ...((index === 0) ? {borderTopRightRadius: K.borderRadius, borderTopLeftRadius: K.borderRadius} : {}),
                      ...(index === listedUsers.length - 1 ? {borderBottomRightRadius: K.borderRadius, borderBottomLeftRadius: K.borderRadius} : {marginBottom: 1}),
                      borderRadius: 0,
                      backgroundColor: lib.colors.colorFor({user: sharee})
                    }}>
                    <Text {...s.shareLeftText}>{sharee.name}</Text>
                    <View style={{position: 'absolute', right: 60}}>
                      {sharee.id === processInstance.ownerId && (
                        <Tooltip text={`Owner`}>
                          <Image source={ownerIcon} {...s.ownerImage}/>
                        </Tooltip>
                      )}
                      {(this.isOwner && sharee.id !== processInstance.ownerId) && (
                        <Tooltip text={`Click to transfer ownership`}>
                          <TouchableOpacity onPress={() => this.setProcessInstanceOwner({sharee})} style={{opacity: 0.2}}>
                            <Image source={ownerIcon} {...s.userImage}/>
                          </TouchableOpacity>
                        </Tooltip>
                      )}
                    </View>
                    {sharee.id !== processInstance.ownerId && (
                      <TouchableOpacity onPress={() => this.removeSharee({sharee})} style={{position: 'absolute', right: 15}}>
                        <Image source={deleteIcon} {...s.deleteImage}/>
                      </TouchableOpacity>
                    )}
                  </View>
                ))}
              </LabelledView>
            )}
            <Subscriptions {...{processType, label: 'Category Subscriptions'}}/>
          </View>
        </View>
        <View style={{zIndex: 0}}>
          {(showAutoPublish || showToggleIsViewingShareeButton) && (
            <LabelledView label='Settings' styles={{outerView: {marginTop: K.spacing * 2}}}>
              {showToggleIsViewingShareeButton && (
                <Tooltip left text={isViewingSharee ? '' : 'See what someone you share with would see'}>
                  <Button
                    onPress={() => {
                      this.props.onClose();
                      this.props.toggleIsViewingSharee();
                    }}
                    mode={isViewingSharee ? 'dark' : 'light'}
                    style={{alignItems: 'flex-start', paddingHorizontal: K.spacing}}
                    textStyle={{opacity: isViewingSharee ? 1 : 0.5}}
                    label={!isViewingSharee ? 'Go to Collaborator view' : 'Go back to creator view'}
                  />
                </Tooltip>
              )}
              {showAutoPublish && (
                <Tooltip left text={'Saves time if projects in this category are always published right away'}>
                  <CheckboxInput
                    label={`Always Publish Automatically`}
                    style={{marginTop: K.margin}}
                    value={_.get(processType, 'autoPublish', 0)}
                    onChange={({value}) => this.props.updateProcessType({id: processType.id, props: {autoPublish: value}})}
                  />
                </Tooltip>
              )}
              {canModifyProcessType && <Tooltip left text={'Choose whether collaborators outside this workspace can edit step properties (instructions, by when, assignees, links), or just fill in dots and provide responses.'}>
                <CheckboxInput
                  label={`Collaborators Can Edit Step Properties`}
                  style={{marginTop: K.margin}}
                  value={_.get(processInstance, 'outsideCollaboratorsCanEditSteps', 0)}
                  onChange={({value}) => this.props.updateProcessInstance({id: processInstance.id, props: {outsideCollaboratorsCanEditSteps: value}})}
                />
              </Tooltip>}
            </LabelledView>
          )}
          <Text style={K.explanationText}>
            {processInstance.isPublished ? `Now that this project is published, notifications will be sent as actions are taken. \n\nUsers can edit notification preferences in Menu > Account settings.` : `We'll email collaborators when you press "Publish." We won't send notifications until then. \n\nUsers can edit notification preferences in Menu > Account settings.`}
          </Text>
        </View>
      </Popup>
    );
  }
}

export default withRouter(connect({
  mapState: (state, ownProps) => ({
    ..._.pick(state, ['session', 'activeView']),
    usersById: state.resources.users.byId,
    involvedUsers: involvedUsersFor({processInstance: ownProps.processInstance, usersById: state.resources.users.byId}),
  }),
  mapDispatch: {
    setActiveView,
    ..._.pick(resourceActions.processInstances, ['trackProcessInstances', 'updateProcessInstance', 'destroyProcessInstance']),
    ..._.pick(resourceActions.processTypes, ['updateProcessType']),
    ..._.pick(resourceActions.users, ['trackUsers'])
  }
})(PublishPopup));
