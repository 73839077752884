import {
  React, Component, View, styleSpread,
  Text
} from '~/components'; //eslint-disable-line

import { connect } from '@symbolic/redux';
import { Popup, Button, Tooltip } from '@symbolic/rn-lib';
import { withSafeAreaInsets } from 'react-native-safe-area-context';
import { ScrollView } from 'react-native';
import { setActiveView } from '~/redux';

import _ from '@symbolic/lodash';
import K from '~/k';
import styles from './report-page.styles';
import Report from './report/report';
import printIcon from '~/assets/print-icon.png';

var s = styleSpread(styles);

class ReportPage extends Component {
  state = {
    isEditing: false
  };

  get fileTitle() {
    return `weekly-production-calendar`;
  }

  print = () => {
    var cachedTitle = document.title;

    document.title = this.fileTitle;

    this.props.setActiveView({data: {isPrinting: true}});

    document.querySelector('html').style.height = 'auto';
    document.querySelector('body').style.height = 'auto';
    document.querySelector('#root').style.height = 'auto';
    document.querySelector('#root > div > div > div > div').style.overflow = 'visible';
    document.querySelector('#root > div > div > div > div > div').style.height = 'auto';

    setTimeout(() => {
      window.print();

      document.title = cachedTitle;

      document.querySelector('html').style.height = '100%';
      document.querySelector('body').style.height = '100%';
      document.querySelector('#root').style.height = '100%';
      document.querySelector('#root > div > div > div > div').style.overflow = 'hidden';
      document.querySelector('#root > div > div > div > div > div').style.height = '100%';

      this.props.setActiveView({data: {isPrinting: false}});
    });
  }

  render() {
    var {insets, session, report} = this.props;
    var canEdit = false;
    var report = {title: 'Weekly Production Calendar'};
    var {isPrinting} = this.props.activeView.data;
    var ContainerView = isPrinting ? View : ScrollView;

    return (
      <ContainerView>
        {K.isWeb && isPrinting && (
          <style>{`
          @media print {
            @page {
              size: landscape
            }
            body {
              -webkit-print-color-adjust: exact;
            }
          }
          `}
          </style>
        )}
        <View style={{padding: K.spacing, backgroundColor: isPrinting ? 'transparent' : K.colors.gray, alignItems: 'center'}}>
          <View style={{marginBottom: K.spacing}}>
            {canEdit ? (
              <TextInput
                multiline
                blurOnSubmit
                returnKeyType='done'
                style={{...styles.processInstanceTitle, borderRadius: 0, paddingHorizontal: 0, height: 'auto', top: K.isWeb  ? 0 : -3}}
                value={report.title}
                // onChange={this.handleProcessInstanceTitleChange}
                // selectTextOnFocus={autoFocus}
              />
            ) : (
              <View style={{marginRight: K.spacing, textAlign: 'center'}}>
                <Text {...s.reportTitle}>{report.title}</Text>
              </View>
            )}
          </View>
          {!isPrinting && (
            <Tooltip text='Print'>
              <Button icon={printIcon} onPress={this.print}/>
            </Tooltip>
          )}
        </View>
        <View style={{padding: K.spacing, alignItems: 'center'}}>
          <Report />
        </View>
        {this.state.isEditing && (
          <Popup>

          </Popup>
        )}
      </ContainerView>
    )
  }
}

export default withSafeAreaInsets(connect({
  mapState: state => {
    return {
      ..._.pick(state, ['activeView'])
    };
  },
  mapDispatch: {
    setActiveView
  }
})(ReportPage));
