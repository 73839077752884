import Svg, { Path } from 'react-native-svg';

import { resourceActions, View, Text, AccessManager, Pressable } from '~/components';
import { handleDotPress, colorFor } from '~/helpers/process-steps';
import { connect, setAppData }  from '@symbolic/redux';
import { setActiveView } from '~/redux/active-view/active-view';
import { Animated, Easing } from 'react-native';
import { Audio } from 'expo-av';
import { involvedUsersFor } from '~/helpers/process-instances';
import { getTrialStatus, Tooltip } from '@symbolic/rn-lib';
import lib from '@symbolic/lib';

import DotCompletionPopup from '~/components/popups/dot-completion/dot-completion-popup';

import moment from 'moment';
import React from 'react';
import _ from 'lodash';
import K from '~/k';
import styles from './process-instance-step-dot.styles';

// var soundIsLoaded = false;
// var sound = new Audio.Sound();

// (async () => {
//   try {
//     await sound.loadAsync(require('../assets/sounds/dot-press.wav'), null, false);

//     await sound.setVolumeAsync(0.2);

//     soundIsLoaded = true;
//   }
//   catch (error) {
//     console.log(error)
//   }
// })();

// Audio.setAudioModeAsync ({
//   allowsRecordingIOS: false,
//   interruptionModeIOS: Audio.INTERRUPTION_MODE_IOS_MIX_WITH_OTHERS,
//   playsInSilentModeIOS: false,
//   interruptionModeAndroid: Audio.INTERRUPTION_MODE_ANDROID_DUCK_OTHERS,
//   playThroughEarpieceAndroid: true,
//   staysActiveInBackground: false,
// });

class ProcessInstanceStepDot extends React.Component {
  state = {diameterScale: new Animated.Value(1), showingDotCompletionPopup: false};

  shouldComponentUpdate(nextProps, nextState) {
    return (nextProps.processInstance !== this.props.processInstance || nextProps.processStep !== this.props.processStep || nextProps.orderBy !== this.props.orderBy || !_.isEqual(nextProps.categoryViewSettings, this.props.categoryViewSettings) || nextState !== this.state);
  }

  handleDotPress = async () => {
    var step = this.props.processStep;
    var {processType, processInstance, activeView, session, updateProcessInstance, setActiveView, involvedUsers, setAppData} = this.props;

    var oldStatus = _.get(processInstance, `steps.${step.id}.status`, processType.defaultStepStatus);
    var newStatus = oldStatus === 'complete' || oldStatus === 'notApplicable' ? 'incomplete' : 'complete';

    var percentComplete = _.get(processInstance, `steps.${step.id}.percentComplete`);

    if (percentComplete === 1){
      alert('Please change % complete to something other than 100% if you’d like to change status');
      return
    }

    var confirmed = await handleDotPress({newStatus, step, processType, processInstance, activeView, session, updateProcessInstance, setActiveView, involvedUsers, setAppData});

    //HINT intentionally disabled sound for now - volume was often wrong, bit of a delay, little annoying
    // if (soundIsLoaded) {
    //   sound.setPositionAsync(0);
    //   sound.playAsync();
    // }

    if (confirmed) {
      Animated.sequence([
        Animated.timing(this.state.diameterScale, {
          toValue: 1.2,
          duration: 100,
          useNativeDriver: true,
          easing: Easing.easeOutQuad,
        }),
        Animated.timing(this.state.diameterScale, {
          toValue: 1,
          duration: 300,
          useNativeDriver: true,
          easing: Easing.easeInQuad,
        })
      ]).start();
    }
  }

  handleCompletionPopupSubmit = () => {
    this.handleDotPress();
    this.setState({showingDotCompletionPopup: false});
  }

  circumPointFromAngle(cx, cy, r, a) {
    return [
      cx + r * Math.cos(a),
      cy + r * Math.sin(a)
    ];
  }

  get sizeKey() {
    var {contextKey} = this.props;
    var {level} = this.props.processStep;

    if (level === 2 && contextKey === 'processInstancesView') return 'smallest';
    if ((level === 2 && contextKey === 'projectPage') || (level === 1 && contextKey === 'processInstancesView')) return 'smaller';
    if ((level === 1 && contextKey === 'projectPage') || (level === 0 && contextKey === 'processInstancesView')) return 'small';
    if (level === 0 && contextKey === 'projectPage') return 'large';
  }

  get diameter() {
    var sizeKeyMap = {
      large: K.step.height,
      small: K.step.small.diameter,
      smaller: Math.round(K.step.small.diameter * 0.75),
      smallest: Math.round(K.step.small.diameter * 0.5)
    };

    return sizeKeyMap[this.sizeKey];
  }

  get containerWidth() {
    if (this.props.contextKey === 'projectPage') {
      return K.isWeb ? this.size : K.step.height;
    }
    else {
      return this.props.dotWidth - K.step.small.margin;
    }
  }

  get dotContainerHeight() {
    return this.props.contextKey === 'processInstancesView' ? K.step.small.diameter : K.step.height;
  }

  get assignees() {
    var {processInstance, processStep} = this.props;
    var teamData = _.get(processInstance, `steps.${processStep.id}.team`, []);

    return _.sortBy(_.filter(_.map(teamData, assigneeData => {
      return this.props.usersById[assigneeData.userId];
    }), user => !!user), [({id}) => id === this.props.session.user.id ? 1 : 0, 'id']);
  }

  pieChartDotFor({completions, assignedUserIds, percentComplete, color}) {
    //based on... https://codepen.io/grieve/pen/xwGMJp?editors=0010
    var radius = (this.diameter / 2) - 0.5;
    var center = this.diameter / 2;
    var theta = Math.PI * 1.5; //start angle

    var ratio = percentComplete ? percentComplete : (completions.length / assignedUserIds.length);

    if (ratio === 1 || ratio === 0) return null;

    // Get the angle of the segment (delta)
    var delta = ratio * 2 * Math.PI;

    // These are the points on the circumfrence that the arc starts and ends
    var startPos = this.circumPointFromAngle(center, center - 1, radius, theta);
    var endPos = this.circumPointFromAngle(center, center - 1, radius, theta + delta);

    // if the arc is greater than 180° we need to use the large arc flag
    var largeArc = delta > Math.PI ? '1 1 1' : '0 0 1';

    // for convenience store each path command in an array
    var actions = [];
    actions.push('M ' + center + ' ' + (center - 1));
    actions.push('L ' + startPos.join(' '));
    actions.push('A ' + radius + ' ' + radius + ' ' + largeArc + ' ' + endPos.join(' '));
    actions.push('Z');

    return (
      <Svg width={this.diameter} height={this.diameter} style={{top: K.isWeb ? 0 : 1}}>
        <Path
          fill={color}
          d={actions.join('\n')}
        />
      </Svg>
    );
  }

  get tooltipData() {
    var {processType, processInstance, processStep: step} = this.props;
    var {sizeKey} = this;
    var status = _.get(processInstance, `steps.${step.id}.status`, processType.defaultStepStatus);
    var percentComplete = _.get(processInstance, `steps.${step.id}.percentComplete`);
    var byWhenDate = _.get(processInstance, `steps.${step.id}.byWhen`);
    var tooltipData = {};

    tooltipData.text = sizeKey !== 'large' ? (step.title || 'Untitled Step') : '';

    if (status === 'complete') {
      var completedDate = _.get(processInstance, `steps.${step.id}.completedDate`);
      var completedByUserId = _.get(processInstance, `steps.${step.id}.completedByUserId`);
      var completedByUser = this.props.usersById[completedByUserId];

      if (sizeKey !== 'large') {
        tooltipData.text = `${tooltipData.text}\n✓ ${completedByUser ? `${completedByUser.firstName} on ` : ''}${moment.utc(completedDate).local().format('MMM D h:mma')}`;
      }
      else {
        tooltipData.text = `✓ ${completedByUser ? `${completedByUser.firstName} - ` : ''}${moment.utc(completedDate).local().format('MMM D h:mma')}`;
      }
    }
    else if (status === 'blocked') {
      tooltipData.text = sizeKey !== 'large' ? `Blocked - ${tooltipData.text}` : 'Blocked';

      if (percentComplete) {
        var percentage = `${Math.round(percentComplete * 100)}%`
        tooltipData.text = sizeKey !== 'large' ? `${percentage} complete - ${tooltipData.text}` : `Blocked - ${percentage} complete`;
      }
    }
    else if (status === 'notApplicable') {
      tooltipData.text = sizeKey !== 'large' ? `Not Applicable - ${tooltipData.text}` : 'Not Applicable';
    }
    else if (percentComplete) {
      var percentage = `${Math.round(percentComplete * 100)}%`
      tooltipData.text = sizeKey !== 'large' ? `${percentage} complete - ${tooltipData.text}` : `${percentage} complete`;
    }
    else if (byWhenDate && status !== 'complete') {
      tooltipData.text = `${tooltipData.text}\nDue: ${moment.utc(byWhenDate).local().format('MMM D')}`;
    }

    return tooltipData;
  }

  get laterStepIsComplete() {
    var processSteps = _.filter(this.props.processType.processSteps, {level: this.props.processStep.level});
    var index = _.map(processSteps, 'id').indexOf(this.props.processStep.id);
    var laterProcessSteps = _.filter(processSteps, (_processStep, index2) => index < index2);

    return _.some(laterProcessSteps, processStep => _.get(this.props.processInstance, `steps.${processStep.id}.status`, 'incomplete') === 'complete');
  }

  render() {
    var {processType, processStep, processInstance, session, appearAsNotApplicable, categoryViewSettings, isHome, updateProcessInstance, highlightColor, orderBy, nextDueDate} = this.props;
    var {showingDotCompletionPopup} = this.state;
    var {tooltipData, diameter, sizeKey} = this;
    var {user} = session;
    var radius = diameter / 2;

    var status = _.get(processInstance, `steps.${processStep.id}.status`, 'incomplete');

    var completions = _.get(processInstance, `steps.${processStep.id}.completions`, []);
    var assignedUserIds = _.get(processInstance, `steps.${processStep.id}.team`, []);
    var percentComplete = _.get(processInstance, `steps.${processStep.id}.percentComplete`, 0);
    var completedDate = _.get(processInstance, `steps.${processStep.id}.completedDate`);
    var byWhenDate = _.get(processInstance, `steps.${processStep.id}.byWhen`);
    var isOverdue = moment().isAfter(moment.utc(byWhenDate).local(), 'day');

    var isFilled = status === 'complete' || percentComplete === 1;// || (status === 'notApplicable' && this.laterStepIsComplete);
    var appearAsNotApplicable = (status === 'notApplicable' || appearAsNotApplicable);
    var color = (appearAsNotApplicable && !isFilled) ? '#ddd' : colorFor({processStep});

    var shouldRenderPieChartDot = ((processStep.requiresCollectiveCompletion && assignedUserIds.length > 1) || percentComplete > 0) && (status === 'incomplete' || status === 'blocked' || status === 'started');

    var isShowingSomeDates = _.get(categoryViewSettings, 'showCompletedDates', 0) || _.get(categoryViewSettings, 'showByWhenDates', 0);
    var showCompletedDate = !isHome && _.get(categoryViewSettings, 'showCompletedDates', 0) === 1 && completedDate && status === 'complete';
    var showByWhenDate = !isHome && _.get(categoryViewSettings, 'showByWhenDates', 0) === 1 && byWhenDate && status !== 'complete';
    var showDates = showCompletedDate || showByWhenDate;

    var showAssignees = !isHome && _.get(categoryViewSettings, 'showAssignees', 0) === 1;
    var assigneeDotSize = K.calc(25);

    if (showAssignees) {
      var {assignees} = this;

      if (assignees.length === 1) {
        var assignee = assignees[0];
      }
    }

    var stepHasCompletionPopup = _.includes([1017, 1018, 1019, 1020, 1021], processStep.id);

    var style = {
      width: diameter,
      height: diameter,
      borderRadius: diameter / 2,
      borderWidth: 1,
      borderColor: color,
      backgroundColor: (appearAsNotApplicable && !isFilled) ? 'transparent' : color + (isFilled ? '' : '11')
    };

    var isInAccessibleWorkspace = _.includes(_.map(this.props.session.orgs, 'id'), processInstance.orgId);
    var isInSharedWorkspace = isInAccessibleWorkspace && (processInstance.orgId !== user.personalOrgId);

    var message = `Your 30-day trial has expired, so filling in dots in a shared workspace is temporarily disabled.\n\nIf you're part of a team, you can ask them to set you up with a paid plan that unlocks this feature, or you can purchase it yourself for $4/month.`;

    var dotFontStyle = {fontSize: this.diameter / 3, position: 'relative', left: 0, opacity: isFilled ? 0.5 : 0.3};

    if (_.includes(['smaller', 'small', 'smallest'], sizeKey)) {
      dotFontStyle = {...dotFontStyle, fontSize: this.diameter / 2.4, top: -1};
    }

    if (status !== 'complete' && byWhenDate && orderBy === 'nextDueDate') {
      if (moment(nextDueDate).isSame(byWhenDate, 'day')){
        style.backgroundImage = `radial-gradient(circle, rgba(${isOverdue ? 255 : 0}, 0, 0, 0.5) 10%, transparent calc(10% + 1px))`;
      }
    }

    var isEnabled = _.includes(['active', 'trial'], getTrialStatus({session: this.props.session}));
    var DotView = Animated.View;

    var dot = (<View
      style={{height: this.dotContainerHeight, width: this.containerWidth, justifyContent: isShowingSomeDates ? 'flex-start' : 'center', alignItems: 'center', ...(this.props.contextKey === 'processInstancesView' ? {marginRight: K.step.small.margin} : {})}}
    >
        {showAssignees && assignees.length > 0 && (
          <View style={{height: K.calc(25), width: '100%', flexDirection: 'row', marginBottom: 5, justifyContent: 'center'}}>
            <Tooltip
              text={_.map(assignees, assignee => assignee.name).join(', ')}
              style={{width: assigneeDotSize, height: assigneeDotSize, position: 'relative', userSelect: 'none'}}
            >
              <View style={{...styles.dot, borderWidth: 0, position: 'absolute', borderRadius: K.borderRadius, backgroundColor: assignee ? (assignee.id === this.props.session.user.id ? 'black' : lib.colors.colorFor({user: assignee})) : '#ccc', textAlign: 'center', justifyContent: 'center', alignItems: 'center', width: assigneeDotSize, height: assigneeDotSize}}>
                <Text style={{color: assignee && assignee.id === this.props.session.user.id ? 'white' : 'black', letterSpacing: K.calcFont(1), fontSize: K.calcFont(10)}}>{assignee ? ((assignee.firstName[0] || '') + (assignee.lastName[0] || '')) : (`+${assignees.length}`)}</Text>
              </View>
            </Tooltip>
          </View>
        )}
        <Tooltip
          {...tooltipData}
          component={Pressable}
          dataSet={K.isWeb ? {'cursor-pointer': 1} : {}}
          onPress={((processType.id === 187) && stepHasCompletionPopup && status !== 'complete') ? () => this.setState({showingDotCompletionPopup: true}) : this.handleDotPress}
          focusable={false}
        >
          <DotView style={{transform: [{scale: this.state.diameterScale}], justifyContent: 'center', alignItems: 'center', ...style}}>
            {status === 'notApplicable' ? (
              <Text style={dotFontStyle}>n/a</Text>
            ) : (
              shouldRenderPieChartDot && this.pieChartDotFor({completions, assignedUserIds, percentComplete, color})
            )}
            {status === 'blocked' && <View style={{position: 'absolute', left: radius - 1, top: 0, width: 1, height: diameter - 1, backgroundColor: 'black', transform: [{rotate: '45deg'}]}}/>}
          </DotView>
        </Tooltip>
        {showDates && (
          <Text style={{marginTop: K.calc(5), textAlign: 'center', fontSize: K.calcFont(12), opacity: 0.5, color: (status !== 'complete' && byWhenDate && isOverdue) ? 'red' : 'black'}}>
            {moment.utc(status !== 'complete' ? byWhenDate : completedDate).local().format('M/DD')}
          </Text>
        )}
      </View>
    );

    return (
      <>
        {isEnabled ? dot : (
          <AccessManager behaviorMap={{inactive: isInSharedWorkspace ? 'showPopup' : 'show'}} webMessage={message}>
            {dot}
          </AccessManager>
        )}
        {showingDotCompletionPopup && (
          <DotCompletionPopup
            onClose={() => this.setState({showingDotCompletionPopup: false})}
            onSubmit={this.handleCompletionPopupSubmit}
            {...{processInstance, processStep, updateProcessInstance}}
          />
        )}
      </>
    );

    return ;
  }
}

export default connect({
  mapState: (state, ownProps) => {
    var processInstance = state.resources.processInstances.byId[ownProps.processInstanceId];

    return {
      activeView: state.activeView || {},
      session: state.session,
      usersById: state.resources.users.byId,
      processInstance,
      involvedUsers: involvedUsersFor({processInstance, usersById: state.resources.users.byId}),
    };
  },
  mapDispatch: {
    setActiveView, setAppData,
    ..._.pick(resourceActions.processInstances, ['updateProcessInstance']),
  }
})(ProcessInstanceStepDot);
