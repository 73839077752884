import { React, Component, _, View } from '~/components';
import { TextInput, PickerInput, CurrencyInput } from '@symbolic/rn-lib';

import K from '~/k';

class Input extends Component {
  render() {
    var {inputProps, type} = this.props;

    return (
      <View>
        {(type === 'text') && (
          <TextInput
            onChange={this.props.onChange}
            {...inputProps}
            style={{...(inputProps.multiline ? {paddingTop: 8, paddingBottom: 7} : {}), ...inputProps.style}}
          />
        )}
        {(type === 'dropdown') && (
          <PickerInput
            basic
            showDownArrow
            style={{height: K.inputHeight}}
            buttonStyle={{backgroundColor: K.colors.gray}}
            onChange={this.props.onChange}
            {...inputProps}
          />
        )}
        {(type === 'currency') && (
          <CurrencyInput
            onChange={this.props.onChange}
            {...inputProps}
						style={{height: K.inputHeight, ...inputProps.style}}
          />
        )}
        {(type === 'number') && (
          <TextInput
            onChange={this.props.onChange}
            {...inputProps}
          />
        )}
      </View>
    );
  }
}

export default Input;
