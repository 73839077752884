import { React, Component, _, View, resourceActions, Text, TextInput, PickerInput, Popup, LabelledView, Comments, DateInput, Tooltip } from '~/components/index.js'; //eslint-disable-line
import { withRouter } from 'react-router-native';
import {Fragment} from 'react';
import { TouchableOpacity } from 'react-native';
import { connect } from '@symbolic/redux';
import { Button, CheckboxInput, confirm, Label, SliderInput } from '@symbolic/rn-lib';
import { setActiveView, setEvent } from '~/redux/index.js';
import { triggerNotifiedIndicator } from '~/helpers/notified-indicator';
import { handleDotPress, colors, colorFor } from '~/helpers/process-steps';
import { userSuggestionsFor, activeInvolvedUsersFor, updateProcessInstanceStep } from '~/helpers/process-instances';
import { getAllSubsteps } from '~/helpers/process-types';

import lib from '@symbolic/lib';
import K from '~/k';
import moment from 'moment';
import ProcessInstanceStepDot from '~/components/process-instance-step-dot/process-instance-step-dot';
import TeamPickerInput from '~/components/team-picker-input/team-picker-input';
import DotCompletionPopup from '~/components/popups/dot-completion/dot-completion-popup';

class ProcessInstanceStepPopup extends Component {
  state = {showingDotCompletionPopup: false};

  //< processStep
  updateProcessStep = ({value, key}) => this.props.updateProcessStep({props: {[key]: value}, id: this.props.processStep.id})

  handleStatusChange = async ({value, percentComplete}) => {
    var {processStep: step, processType, processInstance, activeView, session, updateProcessInstance, setActiveView, involvedUsers} = this.props;

    if (percentComplete === 1){
      alert('Please change % complete to something other than 100% if you’d like to change status');
      return
    }

    if (value == 'complete' && _.includes([1017, 1018, 1019, 1020, 1021], step.id)){
      this.setState({showingDotCompletionPopup: true})
    }
    else {
      await handleDotPress({newStatus: value, step, processType, processInstance, activeView, session, updateProcessInstance, setActiveView, involvedUsers, programmatic: true});
    }
  }

  handleDeletePress = async () => {
    var {processStep, processType} = this.props;
    var {title} = processStep;

    if (await confirm('', 'Are you sure you want to permanently DELETE this step?')) {
      this.close();

      var children = getAllSubsteps({processStepId: processStep.id, processSteps: processType.processSteps});

      this.props.destroyProcessSteps({ids: [processStep.id, ..._.map(children, 'id')]});

      this.props.triggerUndoPopup({event: {
        message: `${title ? `The step "${title}"` : 'That step'} has been deleted`,
        action: () => {
          this.props.trackProcessSteps({processSteps: [processStep, ...children]});

          setTimeout(() => {
            this.props.updateProcessSteps({updates: _.map([processStep, ...children], ({id}) => ({where: {id}, props: {deleted: 0}}))});
          });
        }
      }});
    }
  }
  //>

  //< processInstance
  handleNotesChange = ({value}) => {
    var stepNotes = _.cloneDeep(this.props.processInstance.stepNotes || {});

    _.set(stepNotes, `${this.props.processStep.id}`, value);

    this.props.updateProcessInstance({id: this.props.processInstance.id, props: {stepNotes, wasModified: 1}});
  }

  handleDefaultNotesChange = ({value}) => {
    this.props.updateProcessStep({id: this.props.processStep.id, props: {defaultNotes: value}});
  }

  handleLinksChange = ({value}) => {
    var stepLinks = _.cloneDeep(this.props.processInstance.stepLinks || {});

    _.set(stepLinks, `${this.props.processStep.id}`, value);

    this.props.updateProcessInstance({id: this.props.processInstance.id, props: {stepLinks, wasModified: 1}});
  }

  handleDefaultLinksChange = ({value}) => {
    this.props.updateProcessStep({id: this.props.processStep.id, props: {defaultLinks: value}});
  }

  handlePercentCompleteChange = async ({value}) => {
    var {processStep: step, processType, processInstance, activeView, session, updateProcessInstance, setActiveView, involvedUsers} = this.props;

    if (value == 1 && _.includes([1017, 1018, 1019, 1020, 1021], step.id)) {
      this.setState({showingDotCompletionPopup: true})
    }
    else if (value == 1) {
      await handleDotPress({newStatus: 'complete', step, processType, processInstance, activeView, session, updateProcessInstance, setActiveView, involvedUsers, additionalStepProps: {percentComplete: value}, programmatic: true});
    }
    else if (value != 1 && (_.get(this.props.processInstance.steps, `${this.props.processStep.id}.status`)) === 'complete') {
      await handleDotPress({newStatus: 'incomplete', step, processType, processInstance, activeView, session, updateProcessInstance, setActiveView, involvedUsers, additionalStepProps: {percentComplete: value}, programmatic: true});
    }
    else {
      updateProcessInstanceStep({percentComplete: value}, this.props);
    }
  }

  handleCompletionPopupSubmission = async () => {
    var {processStep: step, processType, processInstance, activeView, session, updateProcessInstance, setActiveView, involvedUsers} = this.props;

    await handleDotPress({newStatus: 'complete', step, processType, processInstance, activeView, session, updateProcessInstance, setActiveView, involvedUsers, programmatic: true});
  }

  handleSizeChange = ({value}) => {
    updateProcessInstanceStep({size: value}, this.props);
  }

  onCreateComment = () => {
    var {processInstance, activeView} = this.props;

    if (!this.props.processInstance.wasModified) this.props.updateProcessInstance({id: this.props.processInstance.id, props: {wasModified: 1}});

    if (processInstance.isPublished) {
      var usersToNotify = _.reject(activeInvolvedUsersFor({usersById: this.props.usersById, processInstance}), {id: this.props.session.user.id});

      if (_.map(usersToNotify).length) {
        triggerNotifiedIndicator({setActiveView: this.props.setActiveView, activeView, notificationTriggerData: {type: 'comment', processInstanceId: processInstance.id, numberOfCollaborators: _.map(usersToNotify).length}});
      }
    }
  }

  onByWhenChange = ({value}) => {
    if (value) value = moment.utc(value);

    updateProcessInstanceStep({byWhen: value ? value.format('YYYY-MM-DD HH:mm:ss') : value}, this.props);
  }

  close = () => {
    this.props.history.push(`/projects/${this.props.processInstance.id}`);
  }

  handleNotifyOnCompletionChange = ({value}) => {
    this.props.updateProcessStep({props: {notifyOnCompletion: value ? 0 : 1}, id: this.props.processStep.id})
  }

  handleInputTypeChange = ({value}) => {
    this.props.updateProcessStep({props: {inputType: value ? 'text' : null}, id: this.props.processStep.id})
  }

  handleNewSubstepPress = () => {
    var {processType, processStep} = this.props;
    var substepCount = _.get(_.filter(this.props.processType.processSteps, {parentStepId: processStep.id}), 'length', 0);

    if (!this.props.processInstance.wasModified) this.props.updateProcessInstance({id: this.props.processInstance.id, props: {wasModified: 1}});

    this.props.handleCollapsePress({processStepId: processStep.id, forceExpand: true});

    this.props.createProcessStep({props: {
      processTypeId: processType.id,
      color: processStep.color,
      title: '',
      rank: substepCount + 1,
      parentStepId: processStep.id,
      orgId: processType.orgId || this.props.session.activeOrg.id
    }});

    if (!K.isWeb) {
      alert('Your substep has been added! You\'ll be able to see it when you close this popup');
    }
  }

  handleStepColorChange = ({value}) => {
    var {processType, processStep} = this.props;

    var childSteps = getAllSubsteps({processStepId: processStep.id, processSteps: processType.processSteps});

    this.props.updateProcessSteps({updates: _.map([processStep, ...childSteps], ({id}) => ({where: {id}, props: {color: value}}))});
  }

  render() {
    var {
      processInstance, processStep, processType,
      canModifyProcessType, isModifyingProcessType, hasMultipleInstancesOfType, updateProcessInstance
    } = this.props;

    var orgIsAccessible = _.includes(_.map(this.props.session.orgs, 'id'), processInstance.orgId);

    var status = _.get(processInstance, `steps.${processStep.id}.status`, 'incomplete');
    var notes = _.get(processInstance, `stepNotes.${processStep.id}`, '');
    var links = _.get(processInstance, `stepLinks.${processStep.id}`, '');
    var byWhen = _.get(processInstance, `steps.${processStep.id}.byWhen`);
    var percentComplete = _.get(processInstance, `steps.${processStep.id}.percentComplete`, 0);
    var timestamps = _.get(processInstance, `steps.${processStep.id}.timestamps`);
    var {outsideCollaboratorsCanEditSteps} = processInstance;
    var {showingDotCompletionPopup} = this.state;

    if (status === 'complete') {
      var completedDate = _.get(processInstance, `steps.${processStep.id}.completedDate`);
      var completedByUserId = _.get(processInstance, `steps.${processStep.id}.completedByUserId`);
      var completedByUser = this.props.usersById[completedByUserId];
    }

    var colorGroups = [
      ['red', 'darkRed', 'darkDarkRed'],
      ['yellow', 'orange', 'darkOrange'],
      ['green', 'darkGreen', 'darkDarkGreen'],
      ['blue', 'darkBlue', 'darkDarkBlue'],
      ['purple', 'darkPurple', 'darkDarkPurple'],
    ];

    return (
      <Popup
        scrollEnabled
        onClose={this.close}
        {...(K.orientation === 'landscape' ? {height: '75%'} : {})}
      >
        <View style={{flexDirection: 'row', marginBottom: K.spacing, paddingVertical: K.spacing, paddingHorizontal: K.spacing, alignItems: 'center'}}>
          <View style={{marginRight: K.spacing, flex: 1}}>
            {canModifyProcessType && !this.props.isViewingSharee ? (
              <TextInput
                multiline
                blurOnSubmit
                returnKeyType='done'
                style={{...K.fonts.pageHeader, paddingHorizontal: 0, paddingVertical: 0, backgroundColor: 'white'}}
                value={processStep.title || 'Untitled Step'}
                onChange={({value}) => this.updateProcessStep({value, key: 'title'})}
              />
            ) : (
              <Text style={{...K.fonts.pageHeader}}>{processStep.title || 'Untitled Step'}</Text>
            )}
            {status === 'complete' && completedByUser && (
              <Label style={{opacity: 0.4, marginTop: K.margin}}>
                {`${completedByUser.firstName} - ${lib.date.formatDate(moment.utc(completedDate).local())} ✓`}
              </Label>
            )}
          </View>
          <ProcessInstanceStepDot contextKey='projectPage' {...{processInstanceId: processInstance.id, processStep, processType}} />
        </View>
        {processType.id === 187 && (
          <TextInput
            grayLabelledView
            blurOnSubmit
            returnKeyType='done'
            label='Size (Hours)'
            value={`${_.get(processInstance, `steps.${processStep.id}.size`, 0)}`}
            onChange={({value}) => this.handleSizeChange({value})}
          />
        )}
        {(!!outsideCollaboratorsCanEditSteps || (orgIsAccessible && !this.props.isViewingSharee)) && (<Fragment><TextInput
          grayLabelledView
          multiline
          label='Instructions / Notes'
          value={notes}
          standardAutoheightStyles
          onChange={({value}) => this.handleNotesChange({value})}
        />
        <LabelledView label={'Due Date'} gray>
          <DateInput
            value={byWhen && moment(moment.utc(byWhen).local().format('YYYY-MM-DD'))}
            style={{marginTop: 1, borderRadius: K.borderRadius, backgroundColor: K.colors.gray}}
            textStyle={{...(byWhen && moment().isAfter(moment(moment.utc(byWhen).local()), 'day') ? {color: 'rgb(255, 0, 0)', opacity: 0.7} : {})}}
            prefix=''
            placeholder='MM/DD/YY'
            onChange={this.onByWhenChange}
          />
        </LabelledView>
        <LabelledView label='Assigned To' gray styles={{outerView: {zIndex: 2}, innerView: {paddingTop: 2}}}>
          <TeamPickerInput
            {..._.pick(this.props, ['processStep', 'processInstance'])}
            resourceKey={'processInstance'}
            autocompleteIsVisible={this.state.autocompleteIsVisible}
            setAutocompleteIsVisible={(value) => this.setState({autocompleteIsVisible: value})}
          />
        </LabelledView>
        <TextInput
          grayLabelledView
          multiline
          label='Links'
          placeholder='ONE PER LINE'
          value={links}
          standardAutoheightStyles
          onChange={({value}) => this.handleLinksChange({value: _.trim(value)})}
        />
        <LabelledView label='Percentage Complete' gray styles={{outerView: {zIndex: 1}, innerView: {paddingTop: 6}}}>
          <SliderInput
            color={colorFor({processStep})}
            value={status === 'complete' ? 1 : percentComplete}
            onChange={this.handlePercentCompleteChange}
            formatDisplayValue={({value}) => `${Math.round(value * 100)}%`}
            backgroundStyle={{borderTopLeftRadius: 0, borderTopRightRadius: 0}}
            fillStyle={{borderTopLeftRadius: 0, borderTopRightRadius: 0}}
          />
        </LabelledView>
        <LabelledView label='Status' gray styles={{outerView: {zIndex: 1}, innerView: {paddingTop: 6}}}>
          <PickerInput
            options={[
              {value: 'incomplete', title: 'Ready', style: {borderTopLeftRadius: 0, ...(K.isWeb ? {} : {flex: 1.3})}},
              {value: 'started', title: 'Started', style: K.isWeb ? {} : {}},
              {value: 'complete', title: 'Complete', style: K.isWeb ? {} : {flex: 1.1}},
              {value: 'blocked', title: 'Blocked'},
              ...(orgIsAccessible ? [{value: 'notApplicable', title: 'N/A', style: {borderTopRightRadius: 0}}] : [])
            ]}
            onChange={({value}) => this.handleStatusChange({value, percentComplete})}
            value={status}
            activeColor={colorFor({processStep})}
            textStyle={{color: 'black'}}
            inline
            basic
            showDownArrow
          />
        </LabelledView>
        {_.size(timestamps) > 0 && (
          <View style={{flexDirection: 'row', marginBottom: K.spacing}}>
            {_.map(['incomplete', 'started', 'complete', 'blocked', ...(orgIsAccessible ? ['notApplicable'] : [])], statusKey => (
              <Label style={{flex: 1, textAlign: 'center'}} key={statusKey}>{_.includes(['started', 'complete'], statusKey) && timestamps[statusKey] ? (moment.utc(timestamps[statusKey]).local().format('MMM D') + '\n' + moment.utc(timestamps[statusKey]).local().format('H:mma')) : ''}</Label>
            ))}
          </View>
        )}
        </Fragment>)}
        <Comments
          style={{zIndex: 1}}
          placeholder={processType.id === 187 ? 'LEAVE A COMMENT / HANDOFF NOTES' : 'LEAVE A COMMENT'}
          onCreate={this.onCreateComment}
          processInstanceStepId={processStep.id}
          isPublished={processInstance.isPublished}
          creationProps={{orgId: processInstance.orgId}}
          additionalUsers={userSuggestionsFor({
            ..._.pick(this.props, ['session', 'usersById', 'processInstance']),
            activeInvolvedUsersOnly: processInstance.isPrivate
          })}
          resourceId={processInstance.id}
          type={'processInstanceStep'}
          autoFocus={false}
        />
        {canModifyProcessType && !this.props.isViewingSharee && (
          <View>
            {canModifyProcessType && hasMultipleInstancesOfType && (
              <Tooltip left text={isModifyingProcessType ? undefined : `Edit color, default owners, and more. Affects everything in ${processType.pluralTitle}`} style={{flex: 1}}>
                <Button
                  onPress={this.props.toggleIsModifyingProcessType}
                  label={isModifyingProcessType ? `Done` : `Edit step configuration`}
                  style={{marginTop: K.spacing}}
                  mode='dark'
                />
              </Tooltip>
            )}
            {isModifyingProcessType && (<>
            {!processType.isSingleUse ? (
              <View style={{position: 'relative', borderTopWidth: 1, borderTopColor: `rgba(0, 0, 0, 0.1)`, height: K.spacing, marginTop: K.spacing * 2 + K.calcFont(8) + 2, marginBottom: K.spacing * 2}}>
                <Text style={{backgroundColor: 'white', ...K.fonts.su, color: `rgba(0, 0, 0, 0.6)`, position: 'absolute', width: K.calcFont(180), left: '50%', marginLeft: -K.calcFont(90), textAlign: 'center', top: K.calcFont(-8)}}>{`Category Settings`}</Text>
              </View>
            ) : (<View style={{marginTop: K.spacing }}/>)}
            <LabelledView label='Color' gray styles={{outerView: {marginBottom: K.spacing}, label: {marginBottom: 0}}}>
              <View style={{flexDirection: 'row', paddingHorizontal: K.spacing, paddingVertical: K.spacing}}>
                {_.map(colorGroups, (colorGroup, column) => (
                  <View key={column} style={{flex: 1}}>
                    {_.map(colorGroup, (key, index) => (
                      <TouchableOpacity
                        {...{key}}
                        onPress={() => this.handleStepColorChange({value: key})}
                        style={{alignSelf: 'stretch', backgroundColor: colors[key], marginBottom: index !== colorGroup.length - 1 ? K.margin : 0, marginRight: column === colorGroups.length - 1 ? 0 : K.margin, height: K.button.height, borderRadius: K.borderRadius, borderWidth: key === processStep.color ? 1 : 0}}
                      />
                    ))}
                  </View>
                ))}
              </View>
            </LabelledView>
            {!processType.isSingleUse && (
              <LabelledView label='Default Instructions / Notes' gray styles={{outerView: {zIndex: 1}, innerView: {paddingTop: 2}}}>
                <TextInput
                  grayLabelledView
                  multiline
                  value={processStep.defaultNotes || ''}
                  standardAutoheightStyles
                  onChange={({value}) => this.handleDefaultNotesChange({value})}
                />
              </LabelledView>
            )}
            {!processType.isSingleUse && (
              <LabelledView label='Default Assignments' gray styles={{outerView: {zIndex: 1}, innerView: {paddingTop: 2}}}>
                <TeamPickerInput
                  {..._.pick(this.props, ['processStep', 'processInstance', 'processType'])}
                  resourceKey={'processStep'}
                />
              </LabelledView>
            )}
            {!processType.isSingleUse && (
              <TextInput
                grayLabelledView
                multiline
                label='Default Links'
                placeholder='ONE PER LINE'
                value={processStep.defaultLinks || ''}
                standardAutoheightStyles
                onChange={({value}) => this.handleDefaultLinksChange({value: _.trim(value)})}
              />
            )}
            <View style={{zIndex: 0}}>
              <Tooltip left text='Choose whether this step involves using our decision-making app, Protagonist, to choose between various options'>
                <CheckboxInput
                  label='Involves a decision'
                  style={{marginBottom: 1}}
                  value={_.get(processStep, 'hasProtagonistDecision', 0)}
                  onChange={({value}) => this.updateProcessStep({value, key: 'hasProtagonistDecision'})}
                />
              </Tooltip>
              <Tooltip left text='Show a box to collect input, you will be notified when the the input is filled in!'>
                <CheckboxInput
                  label={`Requires collaborator response`}
                  style={{marginBottom: 1}}
                  value={_.get(processStep, 'inputType') === 'text'}
                  onChange={this.handleInputTypeChange}
                />
              </Tooltip>
              <Tooltip left text='Require this step to be completed by everyone who is assigned to it'>
                <CheckboxInput
                  label='Each assignee has to click dot'
                  style={{marginBottom: 1}}
                  value={_.get(processStep, 'requiresCollectiveCompletion', 0)}
                  onChange={({value}) => this.updateProcessStep({value, key: 'requiresCollectiveCompletion'})}
                />
              </Tooltip>
              <CheckboxInput
                label={`Don't notify on completion`}
                style={{marginBottom: 1}}
                value={_.get(processStep, 'notifyOnCompletion', 1) ? 0 : 1}
                onChange={this.handleNotifyOnCompletionChange}
              />
              <Tooltip style={{marginBottom: K.spacing * 2}} left text='If this step is completed, the whole project is considered complete'>
                <CheckboxInput
                  label={`Step marks process complete`}
                  value={_.get(processStep, 'isCompletionStep', 0)}
                  onChange={({value}) => this.updateProcessStep({value, key: 'isCompletionStep'})}
                />
              </Tooltip>
              {(processStep.level < 2) && (
                <Button mode='dark' onPress={this.handleNewSubstepPress} style={{marginBottom: K.margin}} label='Add Substep'/>
              )}
              <Button
                style={{backgroundColor: K.colors.deleteRed, width: 'auto'}}
                onPress={this.handleDeletePress}
                label='Delete'
              />
            </View>
            </>)}
          </View>
        )}
        {showingDotCompletionPopup && (
          <DotCompletionPopup
            onClose={() => this.setState({showingDotCompletionPopup: false})}
            onSubmit={this.handleCompletionPopupSubmission}
            {...{processInstance, processStep, updateProcessInstance}}
          />
        )}
      </Popup>
    );
  }
}

export default withRouter(connect({
  mapState: state => ({activeState: state.activeState || {}, usersById: state.resources.users.byId, session: state.session}),
  mapDispatch: {
    setActiveView, setEvent,
    ..._.pick(resourceActions.processInstances, ['trackProcessInstances', 'updateProcessInstance', 'destroyProcessInstance']),
    ..._.pick(resourceActions.processSteps, ['updateProcessStep', 'updateProcessSteps', 'createProcessStep', 'destroyProcessStep', 'destroyProcessSteps', 'trackProcessSteps']),
    ..._.pick(resourceActions.processTypes, ['updateProcessType'])
  }
})(ProcessInstanceStepPopup));
