var fuzz = require('fuzzball');

export function isSearchMatch(searchTerm, value) {
  if (searchTerm) {
    return fuzz.partial_ratio(searchTerm, value) > 80;
  }

  return true;
}

export function filterBySearchTerm(searchTerm, collection, key='title') {
  if (searchTerm) {
    collection = _.filter(collection, item => isSearchMatch(searchTerm, item[key]));
  }

  return collection;
}
