import K from '~/k';

var s = {};

s.notifiedIndicator = {
  backgroundColor: 'black',
  borderRadius: K.borderRadius,
  width: 300,
  zIndex: 10000000000000
};
s.notificationContainer = {
  marginBottom: K.margin,
  width: '100%'
};
  s.notificationHeader = {
    flexDirection: 'row',
    flex: 1
  };
    s.sourceUser = {
      paddingHorizontal: K.spacing,
      height: K.button.height,
      justifyContent: 'center',
      borderTopLeftRadius: K.borderRadius,
      borderBottomRightRadius: K.borderRadius
    }
      s.sourceUserText = {
        color: 'white'
      };
    s.closeButton = {
      paddingHorizontal: K.spacing,
      paddingVertical: K.margin,
      borderTopLeftRadius: 0,
      borderBottomRightRadius: 0,
    };
  s.notificationBody = {
    paddingHorizontal: K.spacing,
    paddingVertical: K.spacing,
    color: 'white',
    opacity: 0.8
  };

export default s;
