import K from '~/k';

var s = {};

s.container = {
  position: 'absolute',
  bottom: K.spacing,
  backgroundColor: 'black',
  borderRadius: K.borderRadius,
  padding: K.spacing,
  flexDirection: 'row',
  alignItems: 'center',
  left: K.spacing,
  ...(K.isWeb ? {} : {width: '90%', bottom: K.spacing * 2})
};
  s.message = {
    color: 'white',
    maxWidth: 250,
    flex: 1
  };
  s.undoButton = {
    marginLeft: K.spacing,
    width: 'auto',
    paddingHorizontal: K.spacing
  };
  s.closeButton = {
    marginLeft: K.margin
  };
  s.closeIcon = {
    width: K.calc(18),
    height: K.calc(18),
  };


export default s;
