import { React, Component, _, View, resourceActions, Button, Text, TextInput, PickerInput, Popup } from '~/components/index.js'; //eslint-disable-line
import { Tooltip, LabelledView, SliderInput, Link, Label } from '@symbolic/rn-lib';
import { colorFor, handleDotPress } from '~/helpers/process-steps';
import { withRouter } from 'react-router-native';
import { connect } from '@symbolic/redux';
import { setActiveView, setEvent } from '~/redux/index.js';
import { updateProcessInstanceStep } from '~/helpers/process-instances';
import { processTypeFor } from '~/helpers/process-types';

import K from '~/k';
import moment from 'moment';

class QuickEditPopup extends Component {
  state = {};

  isValid = async ({key, value}) => {
    var mode = 'proportion';

    if (key === 'startDate') return true;
    if (_.includes(['size', 'afterCompleteBufferSize'], key)) mode = 'hours';

    var isValid = true;

    if (mode === 'proportion') {
      isValid = !isNaN(parseFloat(value)) && parseFloat(value) >= 0 && parseFloat(value) <= 10;
    }
    else if (mode === 'hours') {
      isValid = !isNaN(parseFloat(value)) && (key === 'afterCompleteBufferSize' || parseFloat(value) >= 0);
    }

    if (!isValid) {
      alert(mode === 'hours' ? 'Please enter a valid positive number' : 'Please enter a number between 0 and 1');
    }

    return isValid;
  }

  handleProcessInstanceInputChange = ({key, value}) => {
    if (this.isValid({key, value})) {
      if (key === 'size') value = parseFloat(value);
      if (key === 'startDate') value = value ? value.format('YYYY-MM-DD HH:mm:ss') : value;

      this.props.updateProcessInstance({id: this.props.processInstance.id, props: {[key]: value, wasModified: 1}});
    }
  }

  handleOverrideInputChange = async ({key, value}) => {
    if (this.isValid({key, value})) {
      value = parseFloat(value);

      var steps = _.cloneDeep(this.props.processInstance.steps);

      await updateProcessInstanceStep({processStepOverrides: {
        ..._.get(steps, `${this.props.processStep.id}.processStepOverrides`),
        [key]: value
      }}, this.props);
    }
  }

  handleStepSizeChange = async ({value}) => {
    if (this.isValid({key: 'size', value})) {
      value = parseFloat(value);

      await updateProcessInstanceStep({size: value}, this.props);
    }
  }

  handleProcessStepInputChange = ({key, value}) => {
    if (this.isValid({key, value})) {
      value = parseFloat(value);

      this.props.updateProcessStep({id: this.props.processStep.id, props: {[key]: value}});
    }
  }

  handleStatusChange = async ({value}) => {
    var {processStep: step, processType, processInstance, activeView, session, updateProcessInstance, setActiveView} = this.props;

    await handleDotPress({newStatus: value, step, processType, processInstance, activeView, session, updateProcessInstance, setActiveView, programmatic: true});
  }

  handlePercentCompleteChange = async ({value}) => {
    var {processStep: step, processType, processInstance, activeView, session, updateProcessInstance, setActiveView} = this.props;

    if (value == 1) {
      await handleDotPress({newStatus: 'complete', step, processType, processInstance, activeView, session, updateProcessInstance, setActiveView, additionalStepProps: {percentComplete: value}, programmatic: true});
    }
    else if (value != 1 && (_.get(this.props.processInstance.steps, `${this.props.processStep.id}.status`)) === 'complete') {
      await handleDotPress({newStatus: 'incomplete', step, processType, processInstance, activeView, session, updateProcessInstance, setActiveView, additionalStepProps: {percentComplete: value}, programmatic: true});
    }
    else {
      await updateProcessInstanceStep({percentComplete: value}, this.props);
    }
  }

  getSizePlaceholderForProcessInstance = () => {
    var {processInstance} = this.props;

    var productionProjectValueFieldId = 1;
    var productionProjectBrandFieldId = 110;

    var processInstanceValue = _.get(processInstance.fields, productionProjectValueFieldId, 0);
    var processInstanceBrand = _.get(processInstance.fields, productionProjectBrandFieldId);

    var isHB = processInstanceBrand === "3"; // HB = "3", ST = "2"

    var denominator = isHB ? 15.5 : 10;
    var dollarAmountInTenThousands = processInstanceValue / 100000;
    var multiplier = this.getDifficultyFactorForProcessInstance();

    return `${Math.ceil((dollarAmountInTenThousands / denominator) * multiplier)}`;
  };

  getDifficultyFactorForProcessInstance = () => {
    var {processInstance} = this.props;

    var productionProjectDifficultyFieldId = 111;

    var processInstanceDifficultyFactor = _.get(processInstance.fields, productionProjectDifficultyFieldId, 1);

    var difficultyFactor = parseFloat(processInstanceDifficultyFactor);

    return isNaN(difficultyFactor) ? 1 : difficultyFactor;
  };

  render() {
    var {processInstance, processStep} = this.props;

    var stepSize = _.get(processInstance.steps, `${processStep.id}.size`);
    var stepSizePlaceholder = this.getSizePlaceholderForProcessInstance();

    var processStepOverrides = _.get(processInstance.steps, `${processStep.id}.processStepOverrides`);
    var stepOffset = _.get(processStepOverrides, 'previousStepPercentCompleteRequiredToStart');
    var stepOffsetPlaceholder = _.get(processStep, `previousStepPercentCompleteRequiredToStart`, 1) + '';
    var stepBuffer = _.get(processStepOverrides, 'afterCompleteBufferSize');
    var stepBufferPlaceholder = _.get(processStep, `afterCompleteBufferSize`, 'HOURS') + '';

    var minLabelWidth = K.calc(150);

    var status = _.get(processInstance, `steps.${processStep.id}.status`, 'incomplete');
    var percentComplete = _.get(processInstance, `steps.${processStep.id}.percentComplete`, 0);

    return (
      <Popup scrollEnabled onClose={this.props.onClose}>
        <View style={{marginBottom: K.spacing, padding: K.spacing}}>
          <Text style={{...K.fonts.pageHeader}}>{processInstance.title} - {processStep.title}</Text>
        </View>
        <LabelledView label={'Project-Specific Overrides'} styles={{outerView: {marginBottom: K.spacing * 2}}}>
          <Tooltip left maxWidth={300} text={'Size of this step relative to project size\n\ni.e. Project size = 8 hours, Scale = 0.5 => Step takes 4 hours'}>
            <TextInput
            {...{minLabelWidth}}
              grayLabelledView
              inlineLabelledView
              label='Size (Hours)'
              value={stepSize}
              placeholder={stepSizePlaceholder}
              onChange={this.handleStepSizeChange}
            />
          </Tooltip>
          <Tooltip left maxWidth={300} text={'Proportion of previous step required to be complete to start this step\n\ni.e 0.75 => Starts when previous step is 75% complete'}>
            <TextInput
              {...{minLabelWidth}}
              grayLabelledView
              inlineLabelledView
              label='Offset (Factor)'
              placeholder={stepOffsetPlaceholder}
              value={stepOffset}
              onChange={({value}) => this.handleOverrideInputChange({key: 'previousStepPercentCompleteRequiredToStart', value})}
            />
          </Tooltip>
          <Tooltip left maxWidth={300} text='Amount of time after step is completed until next project can start this step'>
            <TextInput
              {...{minLabelWidth}}
              grayLabelledView
              inlineLabelledView
              label='Buffer (hours)'
              placeholder={stepBufferPlaceholder}
              value={stepBuffer}
              onChange={({value}) => this.handleOverrideInputChange({key: 'afterCompleteBufferSize', value})}
            />
          </Tooltip>
        </LabelledView>
        <LabelledView label='Percentage Complete' gray styles={{outerView: {zIndex: 1}, innerView: {paddingTop: 6}}}>
          <SliderInput
            color={colorFor({processStep})}
            value={status === 'complete' ? 1 : percentComplete}
            onChange={this.handlePercentCompleteChange}
            formatDisplayValue={({value}) => `${Math.round(value * 100)}%`}
            backgroundStyle={{borderTopLeftRadius: 0, borderTopRightRadius: 0}}
            fillStyle={{borderTopLeftRadius: 0, borderTopRightRadius: 0}}
          />
        </LabelledView>
        <LabelledView label='Status' gray styles={{outerView: {zIndex: 1}, innerView: {paddingTop: 6}}}>
          <PickerInput
            options={[
              {value: 'incomplete', title: 'Ready', style: {borderTopLeftRadius: 0, ...(K.isWeb ? {} : {flex: 1.3})}},
              {value: 'started', title: 'Started', style: K.isWeb ? {} : {}},
              {value: 'complete', title: 'Complete', style: K.isWeb ? {} : {flex: 1.1}},
              {value: 'blocked', title: 'Blocked'},
              {value: 'notApplicable', title: 'N/A', style: {borderTopRightRadius: 0}}
            ]}
            onChange={({value}) => this.handleStatusChange({value})}
            value={status}
            activeColor={colorFor({processStep})}
            textStyle={{color: 'black'}}
            inline
            basic
            showDownArrow
          />
        </LabelledView>
        {/* <LabelledView label={'Defaults'}>
          <Tooltip left maxWidth={300} text={'Proportion of previous step required to be complete to start this step\n\ni.e 0.75 => Starts when previous step is 75% complete'}>
            <TextInput
              {...{minLabelWidth}}
              editable={false}
              grayLabelledView
              inlineLabelledView
              label='Offset (Factor)'
              placeholder='0-1x'
              value={processStep.previousStepPercentCompleteRequiredToStart}
              onChange={({value}) => this.handleProcessStepInputChange({key: 'previousStepPercentCompleteRequiredToStart', value})}
            />
          </Tooltip>
          <Tooltip left maxWidth={300} text='Amount of time after step is completed until next project can start this step'>
            <TextInput
              {...{minLabelWidth}}
              editable={false}
              grayLabelledView
              inlineLabelledView
              label='Buffer (hours)'
              placeholder='HOURS'
              value={processStep.afterCompleteBufferSize}
              onChange={({value}) => this.handleProcessStepInputChange({key: 'afterCompleteBufferSize', value})}
            />
          </Tooltip>
        </LabelledView> */}
        <Link
          textStyle={{flex: 1}}
          style={{...K.button, width: 'auto', backgroundColor: 'black', marginTop: K.spacing * 2, flexDirection: 'row', paddingHorizontal: K.spacing}}
          to={`/projects/${processInstance.id}`}
        >
          <Label style={{opacity: 1, color: 'white'}}>Go to Project</Label>
        </Link>
      </Popup>
    );
  }
}

export default withRouter(connect({
  mapState: (state, ownProps) => {
    var {resources, session, activeView} = state;

    var processInstance = _.get(resources.processInstances.byId, ownProps.processInstanceId);
    var processStep = _.get(resources.processSteps.byId, ownProps.processStepId);
    var processType = processTypeFor({state, processTypeId: processInstance.processTypeId});

    return {processInstance, processStep, processType, session, activeView};
  },
  mapDispatch: {
    setActiveView, setEvent,
    ..._.pick(resourceActions.processInstances, ['updateProcessInstance']),
    ..._.pick(resourceActions.processSteps, ['updateProcessStep']),
  }
})(QuickEditPopup));
