import _ from 'lodash';
import moment from 'moment';

var sharingUnlocked = user => {
  var activeSubscription = (_.get(user, 'licenseStatuses.weflowLite.status', 'inactive') === 'active') || (_.get(user, 'licenseStatuses.allApps.status', 'inactive') === 'active');
  var trialEndDate = _.get(user, 'licenseStatuses.weflowLite.trialEndDate', moment());
  var daysLeft = moment(trialEndDate).diff(moment(), 'days');
  var activeTrial = daysLeft > 0;

  return activeSubscription || activeTrial;
}

export {sharingUnlocked};
