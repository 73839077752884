import { React, _, TextInput, View, Text, Popup, TouchableOpacity, Image} from '~/components';
import { updateProcessInstanceField } from '~/helpers/process-instances';

import Input from '../input/input.js'
import K from '~/k';
import settingsIcon from '~/assets/settings-icon.png';

export default class ProcessInstanceField extends React.Component {
  state = {
    multilineTextPopupIsShowing: false,
  };

  handleInputChange = (value) => {
    updateProcessInstanceField(value, {..._.pick(this.props, ['processField', 'processInstance', 'updateProcessInstance'])});
  }

  render() {
    var {processInstance, processField, isModifyingProcessType, contextKey} = this.props;
    var {type, sizeMultiplier} = processField;

    var inputProps = {
      value: _.get(processInstance, `fields.${processField.id}`, processField.defaultValue),
      data: processField.data
    };

    if (_.get(processField, 'data.multiline')) inputProps.multiline = true;

    if (type === 'dropdown') inputProps.options = _.filter(_.get(processField, 'data.options', []), {isDeleted: 0});

    if (contextKey === 'project') {
      // var isNumeric = _.includes(['number', 'currency'], processField.type);

      inputProps.style = {textAlign: 'left'};

      if (_.get(processField, 'data.multiline')) inputProps.style.maxHeight = 95;

      return (
        <View style={{width: K.isWeb ? `${100/6 * sizeMultiplier}%` : '100%', paddingBottom: K.margin, paddingRight: K.isWeb ? K.margin : 0}}>
          <View style={{backgroundColor: K.colors.gray, borderRadius: K.borderRadius, opacity: processField.isDisabled ? 0.5 : 1}}>
            {contextKey === 'project' && (
              <View style={{flexDirection: 'row', position: 'relative', marginTop: K.margin, zIndex: 1}}>
                <Text style={{opacity: 0.6, paddingHorizontal: K.spacing}}>{processField.title}</Text>
                {isModifyingProcessType && (
                  <TouchableOpacity onPress={this.props.toggleShowingFieldPopup} style={{position: 'absolute', top: 0, right: 5}}>
                    <Image source={settingsIcon} style={{opacity: 0.5, width: K.calc(20), height: K.calc(20)}}/>
                  </TouchableOpacity>
                )}
              </View>
            )}
            {processField.isDisabled === 1 ? (
              <Text style={{height: K.inputHeight, paddingLeft: 12, paddingTop: 8, paddingBottom: 7}}>
                {type === 'currency' && (inputProps.value / 100)
                || type === 'dropdown' && (inputProps.options[inputProps.value].title)
                || inputProps.value
                }
              </Text>
            ) : (
              <Input
                {...{type, inputProps}}
                onChange={({value}) => this.handleInputChange(value)}
              />
            )}
          </View>
        </View>
      );
    }
    else if (contextKey === 'category')	{
      if (K.isWeb) {
        inputProps.style = inputProps.buttonStyle = {
          backgroundColor: 'transparent', width: 100 * sizeMultiplier, paddingRight: K.margin, borderRadius: K.borderRadius
        };

        inputProps.dataSet = {categoryField: 1};
      }

      if (processField.type === 'text' && inputProps.data.multiline === 1) {
        var displayCharacters = _.truncate(processInstance.fields[processField.id], {
          'length': 15 * sizeMultiplier,
          'separator': '\n'
        });

        return (
          <>
            <TouchableOpacity
              label={displayCharacters}
              style={{...inputProps.style, paddingHorizontal: K.spacing, paddingRight: K.spacing + K.margin}}
              onPress={() => {this.setState({multilineTextPopupIsShowing: true})}}
            >
              <Text numberOfLines={1}>{displayCharacters}</Text>
            </TouchableOpacity>
            {this.state.multilineTextPopupIsShowing &&
              <Popup onClose={() => this.setState({multilineTextPopupIsShowing: false})}>
                <TextInput
                  multiline
                  autoFocus
                  value={processInstance.fields[processField.id]}
                  onChange={({value}) => this.handleInputChange(value)}
                  style={{paddingTop: K.spacing, paddingBottom: 12}}
                />
              </Popup>
            }
          </>
        );
      }
      else {
        return (
          <Input
            {...{type, inputProps}}
            onChange={({value}) => this.handleInputChange(value)}
          />
        );
      }
    }
  }
}
