import { React, Component, _, View, TouchableOpacity, styleSpread, resourceActions, Text, Tooltip, AccessManager } from '~/components';

import { setActiveView, setEvent } from '~/redux/index.js';
import { connect, setAppData } from '@symbolic/redux';
import { Button, TextInput, OrgIcon, confirm} from '@symbolic/rn-lib';
import { withSafeAreaInsets } from 'react-native-safe-area-context';
import { AttentionIndicator, CopilotStepView, Label} from '@symbolic/rn-lib';
import { categoryTitleFor } from '~/helpers/process-types';
import { createProcessInstance } from '~/helpers/process-instances';
import { withRouter } from 'react-router-native';

import ProcessInstancePopup from '~/components/popups/process-instance/process-instance-popup';
import PublishPopup from '~/components/popups/publish-popup/publish-popup';
import K from '~/k';
import checkIcon from '~/assets/check-icon.png';
import editIcon from '~/assets/edit-icon.png';
import settingsIcon from '~/assets/settings-icon.png';
import pinIcon from '~/assets/pin-icon.png';
import unpinIcon from '~/assets/unpin-icon.png';
import createIcon from '~/assets/create-icon-dark.png';
import privateIcon from '~/assets/privacy-eye-closed.png';
import publicIcon from '~/assets/privacy-eye-open.png';
import styles from './secondary-header.styles';

var s = styleSpread(styles);

class SecondaryHeader extends Component {
  state = {
    isViewingSharee: false,
  };

  componentDidMount() {
    setTimeout(this.considerFocusingTitle);
  }

  componentDidUpdate(prevProps) {
    if (_.get(prevProps, 'processInstance.id') !== _.get(this.props, 'processInstance.id')) {
      this.considerFocusingTitle();
    }
  }

  considerFocusingTitle = () => {
    if (this.titleInputRef && this.autoFocus) this.titleInputRef.focus();
  }

  createProcessInstance = async () => {
    var org = _.find(this.props.session.orgs, {id: this.props.processInstance.orgId});

    await createProcessInstance({
      org,
      ..._.pick(this.props, ['processType', 'session', 'history', 'trackProcessTypes', 'createProcessSteps', 'trackProcessInstances', 'updateProcessType'])
    });
  }

  handleProcessInstanceTitleChange = ({value}) => {
    this.props.updateProcessInstance({props: {title: value, wasModified: 1}, id: this.props.processInstance.id});

    if (this.props.activeView.data.showingTutorial && this.props.activeView.data.tutorialStep === 2) this.props.setActiveView({data: {tutorialStep: 3}});
  }

  get processInstanceOwner() {
    return this.props.usersById[this.props.processInstance.ownerId];
  }

  toggleIsPrivate = () => {
    var {processInstance} = this.props;

    var {isPrivate} = processInstance;

    this.props.updateProcessInstance({id: this.props.processInstance.id, props: {isPrivate: isPrivate ? 0 : 1}});
  }

  toggleIsPinned = () => {
    var {processInstance} = this.props;
    var pinData = _.get(this.props.session.user, `appData.weflowLite.pinData`, {});
    var isPinned = _.get(pinData, `processInstances.${processInstance.id}`, false);

    pinData.processInstances = pinData.processInstances || {};

    if (isPinned) {
      _.unset(pinData, `processInstances.${processInstance.id}`);
    }
    else {
      _.set(pinData, `processInstances.${processInstance.id}`, {rank: _.size(_.get(pinData, 'processInstances'))});
    }

    this.props.setAppData({appKey: 'weflowLite', key: 'pinData', value: pinData});
  }

  get autoFocus() {
    return this.props.activeView.data.showingTutorial || _.includes(this.props.processInstance.title, 'Untitled');
  }

  get isSharee() {
    var role = this.props.processInstance.involvedUsers[this.props.session.user.id];

    return role && role !== 'owner';
  }

  render() {
    if (!this.props.processInstance) return null;

    var {autoFocus} = this;
    var {processInstance, processType, insets, session, toggleIsViewingSharee} = this.props;
    var {showingTutorial, tutorialStep} = this.props.activeView.data;
    var {user} = session;
    var {showingPublishPopup, isViewingSharee} = this.state;
    var showToggleIsViewingShareeButton = !this.isSharee;
    var isOwner = processInstance.ownerId === user.id;
    var pinData = _.get(this.props.session.user, `appData.weflowLite.pinData`, {});
    var isPinned = _.get(pinData, `processInstances.${processInstance.id}`, false);
    var hideExit = !global.isShowingHelpCopilot;
    var isInAccessibleWorkspace = _.includes(_.map(this.props.session.orgs, 'id'), processInstance.orgId);
    var org = _.find(this.props.session.orgs, {id: processInstance.orgId});

    var subTitle = _.join(_.filter([
      `${isInAccessibleWorkspace && !processType.isSingleUse ? `Category: ${processType.pluralTitle}` : ``}`,
      `${processInstance.ownerId !== this.props.session.user.id ? `Owner: ${_.get(this.processInstanceOwner, 'name')}` : ''}`
    ], string => !!string), ', ');

    var canEditProcessInstance = (processInstance.isPrivate ? isOwner : _.find(this.props.session.orgs, {id: processInstance.orgId})) && !this.props.isViewingSharee;
    var showEditProcessType = this.props.canModifyProcessType && this.props.hasMultipleInstancesOfType;
    var {isSingleUse} = processType;

    var isInSharedWorkspace = isInAccessibleWorkspace && (processInstance.orgId !== user.personalOrgId);

    var orgIsAccessible = _.includes(_.map(session.orgs, 'id'), processInstance.orgId);
    var hasPublishAccess = isOwner || (!processInstance.isPrivate && orgIsAccessible);

    var editingMessage = `
Your 30-day trial has expired, so editing information in a shared workspace is disabled.

If you're part of a team, you can ask them to set you up with a paid plan that unlocks this feature, or you can purchase it yourself for $4/month.
    `;

    var displayRow = K.orientation === 'landscape';

    return (
      <View {...s.secondaryHeader} onLayout={this.props.onLayout}>
        <View style={{...styles.meta, paddingVertical: K.spacing, ...(displayRow ? {flexDirection: 'row', alignItems: 'center'} : {})}}>
          <View style={{paddingHorizontal: K.spacing + insets.left, ...(K.isWeb ? {maxWidth: 600, width: '100%'} : {}), ...(displayRow ? {flex: 1} : {})}}>
            <View {...s.processInstanceTitleContainer} dataSet={{hover: 1}}>
              {canEditProcessInstance ? (
                <TextInput
                  multiline
                  blurOnSubmit
                  returnKeyType='done'
                  getInputRef={ref => this.titleInputRef = ref}
                  style={{...styles.processInstanceTitle, borderRadius: 0, paddingHorizontal: 0, height: 'auto', top: K.isWeb  ? 0 : -3}}
                  value={processInstance.title}
                  onChange={this.handleProcessInstanceTitleChange}
                  selectTextOnFocus={autoFocus}
                />
              ) : (
                <View style={{marginRight: K.spacing}}>
                  <Text style={{...styles.processInstanceTitle}}>{processInstance.title}</Text>
                </View>
              )}
              {showingTutorial && tutorialStep === 2 && (<AttentionIndicator radius={10} style={{left: 20, marginTop: 0}}/>)}
            </View>
            {!!subTitle && (<Text style={{opacity: 0.6, marginTop: K.isWeb ? 4 : 2}}>{subTitle}</Text>)}
          </View>
          <View style={{flexDirection: 'row', alignItems: 'center', position: 'relative', ...(displayRow ? {} : {paddingLeft: insets.left + 5, marginTop: K.spacing, width: '100%'}), ...(K.isWeb ? {maxWidth: 600} : {})}}>
            {/* {org && (
              <Tooltip text={`New ${isSingleUse ? 'blank ' : ''}project in ${categoryTitleFor({processType, isSingleUse: processType.isSingleUse, org, orgs: this.props.session.orgs})}`}>
                <AccessManager behaviorMap={{inactive: isInSharedWorkspace ? 'showPopup' : 'show'}}>
                  <Button style={{marginLeft: K.margin}} icon={createIcon} onPress={this.createProcessInstance}/>
                </AccessManager>
              </Tooltip>
            )} */}
            <Tooltip text={!isPinned ? 'Pin to home screen' : 'Unpin'}>
              <Button icon={isPinned ? unpinIcon : pinIcon} onPress={this.toggleIsPinned}/>
            </Tooltip>
            {isOwner && (
              <Tooltip text={processInstance.isPrivate ? `Private to just you and collaborators` : 'Visible in your workspace and to collaborators'}>
                <AccessManager behaviorMap={{inactive: isInSharedWorkspace ? 'showPopup' : 'show'}}>
                  <Button icon={processInstance.isPrivate ? privateIcon : publicIcon} onPress={this.toggleIsPrivate}/>
                </AccessManager>
              </Tooltip>
            )}
            {showEditProcessType && (
              <Tooltip text={this.props.stateIsModifyingProcessType ? 'Done editing' : `Edit steps configuration (affects all projects in ${processType.pluralTitle})`}>
                <AccessManager
                  behaviorMap={{inactive: isInSharedWorkspace ? 'showPopup' : 'show'}}
                  webMessage={editingMessage}
                >
                  <Button
                    onPress={this.props.toggleIsModifyingProcessType}
                    icon={this.props.stateIsModifyingProcessType ? checkIcon : editIcon}
                  />
                </AccessManager>
              </Tooltip>
            )}
            {org && (
              <Tooltip text={canEditProcessInstance ? `Delete, archive & more` : 'Copy menu'}>
                <AccessManager behaviorMap={{inactive: isInSharedWorkspace ? 'showPopup' : 'show'}}>
                  <Button icon={settingsIcon} onPress={() => this.setState({isEditingProcessInstance: true})}/>
                </AccessManager>
              </Tooltip>
            )}
            {org && (
              <Tooltip text={`${org.title} Workspace`}>
                <OrgIcon {...{org}} style={{height: K.inputHeight, width: K.inputHeight, marginLeft: K.margin, ...(K.isWeb ? {cursor: 'pointer'} : {})}}/>
              </Tooltip>
            )}
            {hasPublishAccess && !isViewingSharee && (
              <CopilotStepView
                key={hideExit}
                {...{hideExit}}
                name='publish'
                order={15}
                text={`Press here when you're ready to share with others.`}
                style={{position: 'absolute', right: 0, ...(K.isWeb ? {} : {right: K.spacing + insets.right})}}
              >
                <AccessManager>
                  <Button
                    label={processInstance.isPublished ? 'Share' : 'Publish'}
                    onPress={() => this.setState({showingPublishPopup: true})}
                    mode={processInstance.isPublished ? 'light' : 'dark'}
                    style={{backgroundColor: processInstance.isPublished ? K.colors.doubleGray : 'black'}}
                  />
                </AccessManager>
              </CopilotStepView>
            )}
          </View>
        </View>
        {this.state.isEditingProcessInstance && (
          <ProcessInstancePopup
            id={processInstance.id}
            {...{processType, processInstance}}
            back={this.props.back}
            triggerUndoPopup={this.props.triggerUndoPopup}
            onClose={() => this.setState({isEditingProcessInstance: false})}
          />
        )}
        {showingPublishPopup && (
          <PublishPopup onClose={() => this.setState({showingPublishPopup: false})} {...{processInstance, processType, showToggleIsViewingShareeButton, toggleIsViewingSharee, isViewingSharee}}/>
        )}
      </View>
    );
  }
}

export default withRouter(withSafeAreaInsets(connect({
  mapState: (state) => {
    return {
      ..._.pick(state, ['activeView']),
      usersById: state.resources.users.byId
    };
  },
  mapDispatch: {
    setActiveView, setEvent, setAppData,
    ..._.pick(resourceActions.processInstances, ['trackProcessInstances', 'updateProcessInstance']),
    ..._.pick(resourceActions.processTypes, ['trackProcessTypes', 'updateProcessType']),
    ..._.pick(resourceActions.processSteps, ['createProcessSteps'])
  }
})(SecondaryHeader)));
