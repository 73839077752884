import { React, Component, _, View, Text, TouchableOpacity, Image, resourceActions } from '~/components/index.js'; //eslint-disable-line
import { CheckboxInput, LabelledView } from '@symbolic/rn-lib';
import { connect } from '@symbolic/redux';

import deleteIcon from '~/assets/x-icon.png';
import lib, { api } from '@symbolic/lib';

import K from '~/k';

class Subscriptions extends Component {

  subscribeToProcessType = async () => {
    var {data} = await api.request({uri: '/process-types/subscribe', body: {processTypeId: this.props.processType.id}});

    await this.props.trackProcessTypes({processTypes: [data.processType]});
  }

  unsubscribeFromProcessType = async () => {
    var {data} = await api.request({uri: '/process-types/unsubscribe', body: {processTypeId: this.props.processType.id}});

    await this.props.trackProcessTypes({processTypes: [data.processType]});
  }

  get subscribedUsers() {
    return _.at(this.props.usersById, _.keys(this.props.processType.involvedUsers));
  }

  render() {
    var {processType, session, label} = this.props;
    var {subscribedUsers} = this;
    var {user} = session;

    return processType.isSingleUse !== 1 && <LabelledView label={label || 'Subscriptions'} styles={{outerView: {marginVertical: K.spacing * 2}}}>
      <CheckboxInput
        style={{marginBottom: K.spacing}}
        label={'Subscribe to this category'}
        value={_.get(processType.involvedUsers, user.id, 'unsubscribed') === 'subscribed' ? 1 : 0}
        onChange={({value}) => value === 1 ? this.subscribeToProcessType() : this.unsubscribeFromProcessType()}
      />
      {_.map(subscribedUsers, (subscriber, index) => (
        <View key={`${subscriber.id}-${index}`} style={{
          ...K.button,
          flexDirection: 'row',
          paddingHorizontal: K.spacing,
          justifyContent: 'space-between',
          width: 'auto',
          borderRadius: 0,
          marginBottom: index === subscribedUsers.length - 1 ? 0 : 1,
          backgroundColor: lib.colors.colorFor({user: subscriber}),
          ...(index === subscribedUsers.length - 1 ? {borderBottomRightRadius: K.borderRadius, borderBottomLeftRadius: K.borderRadius} : {marginBottom: 1}),
          ...(index === 0 ? {borderTopRightRadius: K.borderRadius, borderTopLeftRadius: K.borderRadius} : {})
        }}>
          <Text>{subscriber.name}{user.id === subscriber.id ? ' (You)' : ''}</Text>
          {user.id === subscriber.id && <TouchableOpacity onPress={this.unsubscribeFromProcessType} style={{position: 'absolute', right: 15}}>
            <Image source={deleteIcon} style={{height: K.calc(20), width: K.calc(20)}}/>
          </TouchableOpacity>}
        </View>
      ))}
    </LabelledView>
  }
};


export default connect({
  mapState: state => ({session: state.session, usersById: state.resources.users.byId}),
  mapDispatch: {
    ..._.pick(resourceActions.processTypes, ['trackProcessTypes', 'updateProcessType', 'destroyProcessType'])
  }
})(Subscriptions);
