import { React, Component, _, View, resourceActions, Button, Text, TouchableOpacity, TextInput, PickerInput, Image, Popup } from '~/components/index.js'; //eslint-disable-line
import { confirm, Tooltip, CheckboxInput, LabelledView } from '@symbolic/rn-lib';
import Subscriptions from '~/components/subscriptions/subscriptions.js';
import { connect, setAppData, updateOrg } from '@symbolic/redux';
import { setActiveView, setEvent } from '~/redux/index.js';
import { withRouter } from 'react-router-native';
import { api } from '@symbolic/lib';

import K from '~/k';

class CategorySettingsPopup extends Component {
  state = {};

  handleInputChange = ({key, value}) => {
    this.props.updateProcessType({id: this.props.processType.id, props: {[key]: value, wasModified: 1}});
  }

  handleDeletePress = async () => {
    var {processType} = this.props;
    var {id, title, pluralTitle} = processType;

    if (await confirm('', 'Are you sure you want to permanently DELETE this category?')) {
      this.props.setActiveView({data: {deletedProcessTypeId: processType.id}}); //WARNING hack to avoid 404 redirect

      setTimeout(() => {
        var processInstances = _.filter(this.props.processInstancesById, {processTypeId: processType.id});

        this.props.destroyProcessType({id: processType.id});
        this.props.destroyProcessInstances({ids: _.map(processInstances, 'id')});

        setTimeout(() => {
          this.props.history.push('/');

          this.props.triggerUndoPopup({event: {
            message: `${pluralTitle ? `The category "${pluralTitle}"` : 'That category'} has been deleted`,
            action: () => {
              this.props.trackProcessInstances({processInstances});
              this.props.trackProcessTypes({processTypes: [processType]});

              setTimeout(() => {
                this.props.updateProcessType({id, props: {deleted: 0}});
                this.props.updateProcessInstances({ids: _.map(processInstances, 'id'), props: {deleted: 0}});
              });
            }
          }});
        });
      });
    }
  }

  updateUserSettings = ({value, key}) => this.props.setAppData({key, value, appKey: 'weflowLite'})

  close = () => {
    this.props.onClose();
  }

  transferCategory({newOrgId}) {
    var {processType} = this.props;

    setTimeout(async () => {
      if (await confirm('', 'Are you sure you want to transfer this category to another workspace? All assignments will be removed.')) {
        await api.request({uri: '/process-types/transfer', body: {newOrgId, processTypeId: processType.id}});

        await this.props.trackProcessTypes({processTypes: [{...processType, orgId: newOrgId}]});
      }
    });
  }

  render() {
    var {processType, categoryOrg, usersById, session} = this.props;
    var {user} = session;

    if (processType) {
      var userIdsWhoCanEdit = [
        processType.createdByUserId,
        ..._.map(_.filter([..._.values(usersById), user], [`rolesByOrgId.${processType.orgId}`, 'owner']), 'id')
      ];

      var usersWhoCanEdit = _.chain(userIdsWhoCanEdit)
        .uniq()
        .map(id => usersById[id])
        .filter(user => !!user)
        .value();
    }

    var categoryViewSettings = [
      {key: 'showSubsteps', title: 'substeps'},
      {key: 'showByWhenDates', title: 'by when dates'},
      {key: 'showCompletedDates', title: 'completed dates'},
      {key: 'showAssignees', title: 'assignees'},
      {key: 'showOwners', title: 'owner'},
    ];

    if (processType) categoryViewSettings = [{key: 'showStepTitles', title: 'step titles'}, ...categoryViewSettings];

    return (
      <Popup
        scrollEnabled
        onClose={this.close}
        {...(K.orientation === 'landscape' ? {height: '75%'} : {})}
      >
        {processType && (<>
          <View style={{flexDirection: 'row', width: 'auto', marginBottom: K.spacing}}>
            <Button
              style={{backgroundColor: K.colors.deleteRed, flex: 1, marginRight: K.margin}}
              onPress={this.handleDeletePress}
              label='Delete'
            />
            {/* <Button label='Done' style={{backgroundColor: 'black', flex: 1}} textStyle={{color: 'white'}} onPress={this.close}/> */}
          </View>
          <TextInput
            grayLabelledView
            label='Category Title'
            onChange={({value}) => this.handleInputChange({key: 'pluralTitle', value})}
            value={_.get(processType, 'pluralTitle', '')}
          />
          <TextInput
            grayLabelledView
            label='Individual Project name'
            onChange={({value}) => this.handleInputChange({key: 'title', value})}
            value={_.get(processType, 'title', '')}
          />
        </>)}
        <LabelledView label='View Settings (Just affects you)' styles={{outerView: {marginVertical: K.spacing}}}>
          {_.map(categoryViewSettings, (setting, index) => {
            return (
              <CheckboxInput
                key={index}
                label={`Show ${setting.title}`}
                style={{marginBottom: 1}}
                value={_.get(this.props.session.user, `appData.weflowLite.categoryViewSettings.${setting.key}`, 0)}
                onChange={({value}) => this.updateUserSettings({value, key: `categoryViewSettings.${setting.key}`})}
              />
            );
          })}
        </LabelledView>
        {processType && (<>
          <Subscriptions {...{processType}}/>
          <LabelledView label='Shared Category Settings' styles={{outerView: {marginVertical: K.spacing}}}>
            <Tooltip left text={'Saves time if projects in this category are always published right away'}>
              <CheckboxInput
                label={`Always Publish Automatically`}
                value={_.get(processType, 'autoPublish', 0)}
                onChange={({value}) => this.handleInputChange({key: 'autoPublish', value})}
              />
            </Tooltip>
            <Tooltip left text={'Choose whether collaborators outside this workspace can edit step properties (instructions, by when, assignees, links), or just fill in dots and provide responses. You can always override this setting for specific projects.'}>
              <CheckboxInput
                label={`Collaborators Can Edit Step Properties`}
                style={{marginTop: 1}}
                value={_.get(processType, 'outsideCollaboratorsCanEditSteps', 0)}
                onChange={({value}) => this.handleInputChange({key: 'outsideCollaboratorsCanEditSteps', value})}
              />
            </Tooltip>
            <Tooltip left text={'Choose whether or not flat prioritization is enabled in this category'}>
              <CheckboxInput
                label={`Prioritization Enabled`}
                style={{marginTop: 1}}
                value={_.get(processType, 'usesProcessInstanceRank', 0)}
                onChange={({value}) => this.handleInputChange({key: 'usesProcessInstanceRank', value})}
              />
            </Tooltip>
            <Tooltip left text={'Hide all n/a dots in category view'}>
              <CheckboxInput
                label={`Hide N/A steps`}
                style={{marginTop: 1}}
                value={_.get(processType, 'hideNotApplicable', 0)}
                onChange={({value}) => this.handleInputChange({key: 'hideNotApplicable', value})}
              />
            </Tooltip>
            <Tooltip left text={'New projects are created with all steps marked n/a'}>
              <CheckboxInput
                label={`Default new projects to N/A`}
                style={{marginTop: 1}}
                value={_.get(processType, 'defaultStepStatus', 'incomplete') === 'incomplete' ? 0 : 1 }
                onChange={() => _.get(processType, 'defaultStepStatus', 'incomplete') === 'incomplete' ? this.handleInputChange({key: 'defaultStepStatus', value: 'notApplicable'}) : this.handleInputChange({key: 'defaultStepStatus', value: 'incomplete'})}
              />
            </Tooltip>
          </LabelledView>
          {usersWhoCanEdit.length && (
            <View style={{zIndex: 0}}>
              <Text style={K.explanationText}>
                {`${usersWhoCanEdit.length === 1 ? 'Only you' : _.join(_.map(usersWhoCanEdit, user => user.id === this.props.session.user.id ? 'You' : user.name), ', ')} can manage this category.`}
              </Text>
            </View>
          )}
          {(session.orgs.length > 1) && <LabelledView label='Transfer to another Workspace' styles={{outerView: {marginTop: K.spacing}}}>
            <Tooltip left text={'Choose another workspace you are a part of to move this category to - all assignments will be removed'}>
              <PickerInput
                basic
                onChange={({value}) => this.transferCategory({newOrgId: value})}
                options={_.map(session.orgs, ({id, title}) => ({value: id, title}))}
                value={processType.orgId}
                showDownArrow
              />
            </Tooltip>
          </LabelledView>}
        </>)}
        {!processType && categoryOrg && (
          <LabelledView label='Shared Category Settings' styles={{outerView: {marginTop: K.spacing}}}>
            <Tooltip left text={'Choose whether or not flat prioritization is enabled in this category'}>
              <CheckboxInput
                label={`Prioritization Enabled`}
                value={_.get(categoryOrg, 'usesProcessInstanceRank', 0)}
                onChange={({value}) => this.props.updateOrg({id: categoryOrg.id, props: {usesProcessInstanceRank: value}})}
              />
            </Tooltip>
          </LabelledView>
        )}
      </Popup>
    );
  }
}

export default withRouter(connect({
  mapState: state => {
    var {resources, session, activeView} = state;

    return {
      session, activeView,
      processInstancesById: resources.processInstances.byId,
      usersById: resources.users.byId,
    };
  },
  mapDispatch: {
    setActiveView, setEvent, setAppData, updateOrg,
    ..._.pick(resourceActions.processTypes, ['trackProcessTypes', 'updateProcessType', 'destroyProcessType']),
    ..._.pick(resourceActions.processInstances, ['trackProcessInstances', 'updateProcessInstances', 'destroyProcessInstances']),
    ..._.pick(resourceActions.users, ['trackUsers'])
  }
})(CategorySettingsPopup));
