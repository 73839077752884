import K from '~/k';

var s = {};

s.header = {
  backgroundColor: 'black',
  position: 'relative',
  flexDirection: 'row',
};
  s.tutorialInfo = {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1
  };
    s.tutorialText = {
      color: 'white',
      marginHorizontal: K.spacing,
      flex: 1,
      textAlign: 'center',
      opacity: 1
    };
    s.leftContent = {
      // width: K.calc(50),
      flex: 1,
      alignContent: 'center',
      justifyContent: 'flex-start',
      flexDirection: 'row'
    };
      s.trialButton = {
        ...K.button,
        width: 'auto',
        backgroundColor: K.colors.blackGray,
        paddingHorizontal: K.spacing,
        marginLeft: K.margin
      };
        s.trialButtonTextContainer = {
          alignItems: 'flex-start',
          justifyContent: 'center'
        };
          s.trialText = {
            ...K.fonts.su,
            opacity: 1,
            color: 'white'
          };
      s.addButton = {
        backgroundColor: 'white',
        borderRadius: K.calc(70 / 2),
        width: K.calc(40),
        height: K.calc(40),
        ...(!K.isWeb && {
          top: -1,
          left: -1
        })
      };
    s.centeredContent = {
      width: '100%',
      height: K.button.height,
      position: 'absolute',
      alignContent: 'center',
      justifyContent: 'center',
    };
      s.titleContainer = {
        marginLeft: K.button.width - K.margin,
      };
        s.protagonistTitle = {
          color: K.inverseThemeColor,
          fontSize: K.calcFont(14),
          letterSpacing: K.calcFont(3),
          textTransform: 'uppercase',
          // padding: K.calc(4),
          marginBottom: K.calc(3),
          textAlign: 'center',
        };
        s.subheader = {
          color: K.inverseThemeColor,
          fontSize: K.calcFont(12),
          opacity: 0.7,
          letterSpacing: K.calcFont(0.8),
          textAlign: 'center'
        };
    s.rightButtonsContainer = {
      flexDirection: 'row',
      justifyContent: 'flex-end',
      alignItems: 'center'
    };
    s.backImage = {
      width: K.calc(14),
      height: K.calc(18)
    };
    s.shareImage = {
      width: K.calc(18),
      height: K.calc(18),
    };
    s.button = {
      width: K.button.width,
      height: K.button.height,
      backgroundColor: K.colors.blackGray,
      borderRadius: K.borderRadius,
      justifyContent: 'center',
      alignItems: 'center'
    };

export default s;
