import _ from 'lodash';
import { api } from '@symbolic/lib';

async function createProcessTypeAndSteps({orgId, session, trackProcessTypes, createProcessSteps}) {
  var processType = await api.create('processType', {
    title: 'Project',
    pluralTitle: 'Untitled Category',
    createdByUserId: session.user.id,
    instanceCount: 1,
    isSingleUse: 1,
    orgId: orgId || processType.orgId
  });

  await trackProcessTypes({processTypes: [processType]});

  await createProcessSteps({propsSets: _.map([
    {color: 'purple'},
    {color: 'darkPurple'},
    {color: 'blue'}
  ], ({color}, index) => ({
    title: '',
    color,
    orgId,
    rank: index,
    processTypeId: processType.id,
    notifyOnCompletion: 1,
    hasProtagonistDecision: 0,
    requiresCollectiveCompletion: 0
  }))});

  return processType;
}

function orderProcessSteps({processSteps}) {
    var orderedChildrenOf = (processStep) => {
      var directChildren = _.filter(processSteps, {parentStepId: processStep.id});

      if (!directChildren.length) {
        return [];
      }
      else {
        return _.flatMap(_.orderBy(directChildren, 'rank'), (child, index) => {
          child = {...child, parentSteps: [processStep, ...processStep.parentSteps], level: processStep.level + 1, siblingRelativeIndex: index + 1, rankString: processStep.rankString + `.${index + 1}`};

          return [child, ...orderedChildrenOf(child)];
        });
      }
    }

    var topLevelSteps = _.filter(processSteps, processStep => {
      return processStep.parentStepId === null || !_.includes(_.map(processSteps, 'id'), processStep.parentStepId);
    });

    var orderedProcessSteps = _.flatMap(_.orderBy(topLevelSteps, 'rank'), (topLevelStep, index) => {
      topLevelStep = {...topLevelStep, parentSteps: [], level: 0, siblingRelativeIndex: index + 1, rankString: `${index + 1}`};

      return [topLevelStep, ...orderedChildrenOf(topLevelStep)];
    });

    return orderedProcessSteps;
  }

function getAllSubsteps({processStepId, processSteps}) {
  var initialStep = _.find(processSteps, {id: processStepId});

  var childrenOf = (processStep) => {
    var directChildren = _.filter(processSteps, {parentStepId: processStep.id});

    if (!directChildren.length) {
      return [];
    }
    else {
      return _.flatMap(directChildren, child => [child, ...childrenOf(child)]);
    }
  }

  return childrenOf(initialStep);
}

function processTypeFor({processTypeId, state}) {
  var processTypes = state.resources.processTypes.byId;
  var processSteps = state.resources.processSteps.byFieldKeyIndex.processTypeId;

  return {
    ...processTypes[processTypeId],
    processSteps: orderProcessSteps({processSteps: processSteps[processTypeId]})
  };
}

function processTypesFor({state}) {
  var processTypes = state.resources.processTypes.byId;
  var processSteps = state.resources.processSteps.byFieldKeyIndex.processTypeId;

  return {
    ..._.mapValues(processTypes, processType => ({
      ...processType,
      processSteps: orderProcessSteps({processSteps: processSteps[processType.id]})
    }))
  };
}

function categoryTitleFor({processType, isSingleUse, org, orgs}) {
  if (!org) {
    return `Shared Projects`;
  }
  else {
    if (isSingleUse) {
      return `${org.type === 'personal' ? (orgs.length > 1 ? 'My Private' : 'My') : org.title} Projects`;
    }
    else {
      return processType.pluralTitle;
    }
  }
}

function getCanModifyProcessType({processType={}, session}) {
  return _.get(_.find(session.orgs, {id: processType.orgId}), 'role') === 'owner' || processType.createdByUserId === session.user.id;
}

export { processTypesFor, processTypeFor, getCanModifyProcessType, categoryTitleFor, createProcessTypeAndSteps, orderProcessSteps, getAllSubsteps }
