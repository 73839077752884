import React, { Component } from 'react';
import { Text, Loading, Button, TouchableOpacity } from '~/components';
import { View, Image, Pressable } from 'react-native';
import { Popup, LabelledView } from '@symbolic/rn-lib';
import { connect, createOrg } from '@symbolic/redux';
import { Video } from 'expo-av';
import { setActiveView } from '~/redux/active-view/active-view';
import { withRouter } from 'react-router-native';

import * as Linking from 'expo-linking';
import K from '~/k';
import lib from '@symbolic/lib';
import backIcon from '~/assets/left-arrow.png';
import videoIcon from '~/assets/help-popup/video-icon.png';
import tourIcon from '~/assets/help-popup/tour-icon.png';
import documentIcon from '~/assets/help-popup/document-icon.png';
import keyIcon from '~/assets/help-popup/key-icon-black.png';
import incompleteIcon from '~/assets/help-popup/dot-language-key/incomplete-dot.png';
import completeIcon from '~/assets/help-popup/dot-language-key/complete-dot.png';
import partialIcon from '~/assets/help-popup/dot-language-key/partial-dot.png';
import notApplicableIcon from '~/assets/help-popup/dot-language-key/not-applicable-dot.png';
import blockedIcon from '~/assets/help-popup/dot-language-key/blocked-dot.png';
import userIcon from '~/assets/help-popup/dot-language-key/user-icon.png';
import workspaceIcon from '~/assets/help-popup/dot-language-key/workspace-icon.png';
import helpList from './help-list';
import _ from 'lodash';

var openUrl = (url) => K.isWeb ? window.open(url, '_blank') : Linking.openURL(url)

var dotLanguageKey = [
  {label: 'Incomplete', icon: incompleteIcon},
  {label: 'Complete', icon: completeIcon},
  {label: 'Partially complete', icon: partialIcon},
  {label: 'Not applicable', icon: notApplicableIcon},
  {label: 'Blocked', icon: blockedIcon},
  {label: 'User icon', icon: userIcon},
  {label: 'Workspace icon', icon: workspaceIcon}
];

var colorCodes = {
  '': 'black',

  red: '#F4AAAA',
  darkRed: '#ED9191',
  darkDarkRed: '#EC7979',

  yellow: '#FACD55',
  orange: '#FAB855',
  darkOrange: '#FA9A55',

  green: '#C8D4A3',
  darkGreen: '#A9CC9D',
  darkDarkGreen: '#99CDBA',

  blue: '#ABC0DB',
  darkBlue: '#97ABC5',
  darkDarkBlue: '#839EC0',

  purple: '#C3ABDB',
  darkPurple: '#A5A1DA',
  darkDarkPurple: '#8486B9'
};

class HelpPopup extends Component {
  state = {};

  constructor(props) {
    super(props);

    this.state = {
      viewKey: null,
      loadingVideo: true
    };

    this.getVideoRef = ref => this.videoRef = ref;
  }

  handleOrgCreate = async () => {
    if (!this.state.isCreatingOrg) {
      this.setState({isCreatingOrg: true});

      await this.props.createOrg({props: {type: 'business', title: 'Untitled Workspace'}});

      setTimeout(() => {
        var newOrg = _.last(this.props.session.orgs);

        this.close();

        this.setState({isCreatingOrg: false});

        this.props.history.push(`/edit-workspace/${newOrg.id}`);
      }, 1000);
    }
  }

  getOrgsWithKeys = () => {
    var orgsWithKeys = [];
    var indexCounter = 0;

    _.map(this.props.session.orgs, (org, groupIndex) => {
      if (org.colorScheme) {
        orgsWithKeys.push(org.colorScheme);
        orgsWithKeys[indexCounter].orgName = org.title;

        indexCounter++;
      }
    });

    return orgsWithKeys;
  }

  startTour = () => {
    this.close();

    global.isShowingHelpCopilot = true;

    this.props.startCopilot({startArgs: [false, global.processInstanceScrollViewRef], key: 'processInstanceShowView'});
  }

  startTutorial = () => {
    this.props.history.push('/');

    this.props.setActiveView({data: {showingTutorial: true, tutorialStep: 1, tutorialComplete: false}});

    setTimeout(() => this.close());
  }

  close = () => {
    this.props.setActiveView({data: {helpPopupIsVisible: false}});
  }

  render() {
    var {viewKey, loadingVideo} = this.state;
    var orgsWithKeys = this.getOrgsWithKeys();

    return (
      <Popup scrollEnabled height={K.orientation === 'landscape' ? '75%' : 400} onClose={this.close}>
        {viewKey && (
          <Button icon={backIcon} style={{marginBottom: K.spacing}} onPress={() => this.setState({viewKey: null, loadingVideo: true})}/>
        )}
        {!viewKey && (
          <View>
            {_.map([

              {key: 'tutorial', icon: tourIcon, title: 'Tutorial', color: lib.colors.fun[1], description: 'An interactive, step-by-step tutorial.', onPress: this.startTutorial},
              {key: 'tour', icon: tourIcon, title: 'Tooltips', color: lib.colors.fun[1], description: 'Tooltips that just give you the info.', onPress: this.startTour, shouldHide: !(this.props.activeProcessInstance || this.props.editingProcessType)},
              {key: 'demoVideo', icon: videoIcon, title: 'Video Tour', color: lib.colors.fun[0], description: 'A walk-through of the basic functions and features.',},
              {key: 'ecosystem', icon: documentIcon, title: 'Learn about our other apps', color: lib.colors.fun[2], description: 'Polydot is just one piece of the puzzle - we have an ecosystem of apps, including "Protagonist."'},
              // {key: 'useCases', icon: documentIcon, title: 'What is this app for?', color: lib.colors.fun[2]},
              {key: 'list', icon: documentIcon, title: 'Features and tips list', color: lib.colors.fun[2], description: 'A summary of what Polydot is capable of and intermediate/advanced info.'},
              {key: 'keys', icon: keyIcon, title: 'Key', color: lib.colors.fun[6], description: 'Descriptions and defintions.'}
            ], (view) => (!view.shouldHide) && (
              <TouchableOpacity
                style={{flexDirection: 'row', backgroundColor: K.colors.gray, borderRadius: K.borderRadius, marginBottom: K.margin, ...view.style}}
                onPress={view.onPress || (() => this.setState({viewKey: view.key}))}
                key={view.key}
              >
                <View style={{flex: 1, padding: K.spacing}}>
                  <Text style={{textTransform: 'uppercase', opacity: 1, letterSpacing: K.calcFont(1.5), fontWeight: 'bold'}}>{view.title}</Text>
                  {view.description && (
                    <Text style={{marginTop: K.margin, opacity: 0.6}}>{view.description}</Text>
                  )}
                </View>
                <View style={{justifyContent: 'center', alignItems: 'center', alignSelf: 'stretch', width: K.button.width, borderTopRightRadius: K.borderRadius, borderBottomRightRadius: K.borderRadius, backgroundColor: view.color}}>
                  <Image source={view.icon} style={{width: K.calc(16), height: K.calc(16)}}/>
                </View>
              </TouchableOpacity>
            ))}
            <View style={{marginTop: K.spacing}}>
              <Button mode='dark' label='New Team Workspace' onPress={this.handleOrgCreate} style={{alignItems: 'flex-start', paddingHorizontal: K.spacing, marginBottom: K.margin}}/>
              <Button mode='dark' label='Our company website' onPress={() => openUrl('https://www.symbolicframeworks.com')} style={{alignItems: 'flex-start', paddingHorizontal: K.spacing, marginBottom: K.margin}}/>
            </View>
          </View>
        )}
        {viewKey === 'demoVideo' && (
          <>
            {loadingVideo && (
              <Loading text={'Loading Video'}/>
            )}
            <Video
              ref={this.getVideoRef}
              resizeMode='contain'
              style={{width: '100%', height: loadingVideo ? 0 : 300}}
              source={{uri: `https://symbolic-public.s3.amazonaws.com/polydot/help/${K.isWeb ? 'web' : 'mobile'}-demo-video.mp4`}}
              onLoad={() => {
                this.setState({loadingVideo: false});
                if (this.videoRef && !K.isWeb) this.videoRef.presentFullscreenPlayer();
              }}
              useNativeControls
              shouldPlay
              isMuted
            />
          </>
        )}
        {viewKey === 'sharingVideo' && (
          <>
            {loadingVideo && (<Loading text={'Loading Video'}/>)}
            <Video
              ref={this.getVideoRef}
              resizeMode='contain'
              style={{width: '100%', height: 300}}
              source={{uri: 'https://symbolic-public.s3.amazonaws.com/protagonist/help/protagonist-demo.mp4'}}
              onLoad={() => {
                this.setState({loadingVideo: false});
                if (this.videoRef && !K.isWeb) this.videoRef.presentFullscreenPlayer();
              }}
              useNativeControls
              shouldPlay
              isMuted
            />
          </>
        )}
        {viewKey === 'list' && (
          <Pressable dataSet={{nohover: 1}}>
            {_.map(helpList, category => (
              <View key={category.title} style={{marginTop: K.spacing, marginBottom: K.spacing * 2}}>
                <View style={{marginBottom: K.spacing}}>
                  <Text style={{marginLeft: K.spacing, textTransform: 'uppercase', fontSize: K.calcFont(16), letterSpacing: K.calcFont(2), fontWeight: 'bold'}}>{category.title}</Text>
                </View>
                <View>
                  {_.map(category.items, (item, i) => (
                    <View key={item.title} style={{marginBottom: K.spacing, backgroundColor: K.colors.gray, borderRadius: K.borderRadius}}>
                      <View style={{backgroundColor: lib.colors.fun[i], borderTopLeftRadius: K.borderRadius, borderTopRightRadius: K.borderRadius, padding: K.spacing}}>
                        <Text style={{textTransform: 'uppercase', opacity: 1, fontWeight: 'bold'}}>{item.title}</Text>
                      </View>
                      <Text style={{opacity: 0.9, padding: K.spacing}}>{item.description}</Text>
                    </View>
                  ))}
                </View>
              </View>
            ))}
          </Pressable>
        )}
        {viewKey === 'ecosystem' && (
          <View>
            <Pressable dataSet={{nohover: 1}}>
              <View style={{padding: K.spacing, backgroundColor: K.colors.gray, borderRadius: K.borderRadius}}>
                <Text>We (Symbolic Frameworks) are developing a system of apps that help solve a range of problems people run into at work - from brainstorming and strategy, to decision-making and project-execution. Two of those apps are complete, and more are on the way.</Text>

                <Text style={{marginTop: K.spacing * 2, marginBottom: K.margin, fontSize: K.calcFont(16), letterSpacing: K.calcFont(2), fontWeight: 'bold', textTransform: 'uppercase'}}>Protagonist</Text>
                <Text>{`Protagonist, now joined by Polydot, was the first of those apps. It was launched in Spring 2021. It is a decision-structuring and prioritization app that enables individuals and teams to make better decisions by seeing into the process more clearly.

Polydot and Protagonist work together. For example, say you're hiring someone:\n\n1. You'd set up your hiring process as a project here. \n\n2. Then you'd associate a step like "choose final candidates" with a Protagonist decision.\n\n3. As you moved through the process, you'd fill in dots that correspond to the steps in Polydot. You'd use Protagonist to help you evaluate candidates based on the factors you value.`}</Text>
              </View>
              <Button style={{marginTop: K.spacing * 2}} mode='dark' label='Check out Protagonist' onPress={() => openURL('https://protag.app')}/>
              <Button style={{marginTop: K.margin}} mode='dark' label='Symbolic Frameworks Website' onPress={() => openURL('https://www.symbolicframeworks.com')}/>
            </Pressable>
          </View>
        )}
        {viewKey === 'keys' && (
          <View style={{marginTop: K.spacing}}>
            {_.map(orgsWithKeys, (colorScheme, schemeIndex) => (
              <View key={schemeIndex} style={{marginBottom: K.spacing * 2}}>
                <Text style={{marginLeft: K.margin, marginBottom: K.spacing, textTransform: 'uppercase', opacity: 1, fontWeight: 'bold'}}>{colorScheme.orgName} Color Scheme</Text>
                {_.map(colorScheme.groups, (group, groupIndex) => (
                  <View key={groupIndex} style={{ marginTop: K.margin,flexDirection:'row', alignItems: 'center', justifyContent:'space-between'}}>
                    <Text style={{...K.fonts.sl, marginLeft: K.margin, flex: 1}}>{group.label}</Text>
                    <View style={{marginLeft: K.spacing * 2, flexDirection:'row', flexWrap: 'wrap'}}>
                      {_.map(group.colors, (key, index) => (
                        <TouchableOpacity
                        {...{key}}
                        style={{alignSelf:'flex-end', backgroundColor: colorCodes[key],
                          marginLeft: K.margin, height: K.button.height, width: K.button.width,
                          borderRadius: K.borderRadius, borderWidth: 0}}
                        />
                      ))}
                    </View>
                  </View>
                ))}

              </View>
            ))}
            <Text style={{marginLeft: K.margin, marginBottom: K.spacing, textTransform: 'uppercase', opacity: 1, fontWeight: 'bold'}}>Dot Language</Text>
            {_.map(dotLanguageKey, (group, index) => (
              <View key={index} style={{flexDirection: 'row', alignItems: 'center', marginBottom: K.margin}}>
                <Text style={{...K.fonts.sl, marginLeft: K.margin, flex: 1}}>{group.label}</Text>
                <Image source={group.icon} style={{width: K.calc(40), height: K.calc(40)}}/>
              </View>
            ))}
          </View>
        )}
      </Popup>
    );
  }
}

export default withRouter(connect({mapDispatch: {setActiveView, createOrg}})(HelpPopup));
