import lib from '@symbolic/lib';

import {
  React, Component, _, View, styleSpread, resourceActions,
  Text, TouchableOpacity, Image, Tooltip, logAnalyticsEvent
} from '~/components'; //eslint-disable-line

import moment from 'moment';
import { setActiveView, setEvent } from '~/redux/index.js';
import { connect, setAppData } from '@symbolic/redux';
import { Label, TextInput, OrgIcon, prepareToAnimate, Link } from '@symbolic/rn-lib';
import { categoryTitleFor, createProcessTypeAndSteps } from '~/helpers/process-types';
import { createProcessInstance } from '~/helpers/process-instances';
import { withRouter } from 'react-router-native';
import { withSafeAreaInsets } from 'react-native-safe-area-context';
import { Dimensions } from 'react-native';

import StickyTable from '~/components/sticky-table/sticky-table';
import ProcessInstanceStepDot from '~/components/process-instance-step-dot/process-instance-step-dot';
import ProcessInstanceField from '~/components/process-instance-field/process-instance-field';


import K from '~/k';
import styles from './process-instances-view.styles';

import pinIcon from '~/assets/pin-icon.png';
import unpinIcon from '~/assets/unpin-icon.png';
import leftArrow from '~/assets/left-arrow.png';
import privateIcon from '~/assets/privacy-eye-closed.png';
import publicIcon from '~/assets/privacy-eye-open.png';
import createIcon from '~/assets/create-icon-dark.png';

var fuzz = require('fuzzball');

var s = styleSpread(styles);

class ProcessInstancesView extends React.Component {
  state = {
    searchTerm: '',
  };

  constructor(props) {
    super(props);

    this.handleSearch = _.debounce(this.handleSearch.bind(this), 400);
  }

  shouldComponentUpdate(nextProps, nextState) {
    var changedPropKeys = [];

    for (const propKey in nextProps) {
      if (nextProps[propKey] !== this.props[propKey] && !_.includes(['session', 'match', 'processType'], propKey) && !(propKey === 'categoryViewSettings' && _.isEqual(this.props.categoryViewSettings, nextProps.categoryViewSettings))) {
        changedPropKeys.push(propKey);
      }
    }

    return nextState !== this.state || changedPropKeys.length > 0;
  }

  createProcessInstance = async ({processType, org}) => {
    await createProcessInstance({
      processType, org,
      ..._.pick(this.props, ['session', 'history', 'trackProcessTypes', 'createProcessSteps', 'trackProcessInstances', 'updateProcessType'])
    });
  }

  get visibleProcessSteps() {
    var {processType} = this.props;
    var showSubsteps = _.get(this.categoryViewSettings, 'showSubsteps', 0) === 1;

    var visibleProcessSteps = _.get(processType, 'processSteps');

    if (!showSubsteps) {
      visibleProcessSteps = _.filter(_.get(processType, 'processSteps'), {parentStepId: null});
    }

    return visibleProcessSteps;
  }

  renderItem = ({item, isStickyColumn, isScrollHeader}) => {
    var {processType, visibleProcessFields, dotRowWidth, updateProcessInstance} = this.props;
    var {key, processInstance, org, isSingleUse, processType} = item;
    var {searchTerm} = this.state;
    var {insets} = this.props;
    var categoryTitle = categoryTitleFor({processType, isSingleUse, org, orgs: this.props.session.orgs});
    var orgIsAccessible = processInstance && _.includes(_.map(this.props.session.orgs, 'id'), processInstance.orgId);

    var url;

    if (!org) url = '/categories/shared';
    else if (isSingleUse) url = `/categories/single-use/${org.id}`;
    else url = `/categories/${processType.id}`;

    var isHome = this.props.location.pathname === '/';
    var mobileRadius = K.isWeb ? K.borderRadius : 0;
    var stickyColumnWidth = styles.K.stickyColumnWidth + insets.left;

    if (item.key === 'scrollHeader' && isScrollHeader) {
      return item.render();
    }
    // if (item.key === 'header') {
    //   return <View style={{width: 50, height: 50, backgroundColor: 'red'}} />
    // }
    if (item.key === 'header') {
      var {showStepTitles} = this;
      var height = showStepTitles ? (K.calc(150) + K.spacing * 2) : K.calc(60);

      if (isStickyColumn) {
        return (
          <View style={{width: stickyColumnWidth, height, flexDirection: 'row', alignItems: 'flex-end', paddingBottom: K.spacing, paddingHorizontal: K.spacing}}>
            <Text style={{opacity: 0.6}}>Title</Text>
          </View>
        );
      }
      else {
        var width = K.calc(150);
        var textHeight = 0;

        return (
          <View style={{flexDirection: 'row', alignItems: 'flex-end', paddingLeft: this.props.dotRowLeftWidth, paddingBottom: K.spacing, width: dotRowWidth, height, paddingRight: K.spacing}}>
            {_.map(visibleProcessFields, processField => {
              var width = 100 * processField.sizeMultiplier;

              return (
                <Text
                  key={processField.id}
                  style={{opacity: 0.6, width, paddingLeft: K.spacing, paddingRight: K.spacing + K.margin, ...(_.includes(['currency', 'number'], processField.type) ? {textAlign: 'right'} : {})}}
                >
                  {processField.title}
                </Text>
              );
            })}
            {visibleProcessFields.length > 0 && (
              <View style={{width: K.spacing}}/>
            )}
            <View style={{flexDirection: 'row', justifyContent: 'flex-end', flex: 1}}>
              {showStepTitles && _.map(this.visibleProcessSteps, (processStep, index) => (
                <View
                  key={`step-title-${index}`}
                  style={{width: this.props.dotWidth, justifyContent: 'flex-end'}}
                >
                  <View style={{width: K.calc(150)}}>
                    <Text
                      style={{
                        opacity: 0.6,
                        transform: [
                          {translateX: this.props.dotWidth / 2 - width / 2 - K.calc(2)},
                          {translateY: K.fonts.standard.fontSize / 2 - textHeight / 2},
                          {rotate: `-90deg`},
                          {translateX: width / 2},
                          {translateY: textHeight / 2}
                        ]
                      }}
                      numberOfLines={1}
                    >
                      {processStep.title || ' '}
                    </Text>
                  </View>
                </View>
              ))}
            </View>
          </View>
        );
      }
    }
    if ((item.type === 'header' || item.type === 'processTypeHomeHeader') && isStickyColumn) {
      return (
        <View
          dataSet={{'conditional-opacity-parent': 1}}
          style={{width: stickyColumnWidth, height: styles.K.rowHeight, flexDirection: 'row', alignItems: 'center', backgroundColor: isHome ? 'white' : K.colors.gray}}>
          {isHome && (
            <View style={{paddingHorizontal: K.spacing, paddingLeft: insets.left + K.spacing, borderTopLeftRadius: mobileRadius, borderBottomLeftRadius: mobileRadius, alignSelf: 'stretch', marginBottom: 1, flex: 1, backgroundColor: K.colors.gray, alignItems: 'stretch', flexDirection: 'row'}}>
              <Link
                dataSet={{nohover: 1}}
                style={{flexDirection: 'row', alignItems: 'center', flex: 1, maxWidth: stickyColumnWidth - (K.isWeb ? 50 : 0)}}
                to={url}
              >
                <Text style={{fontWeight: 'bold', ...(!item.processInstancesAreVisible ? {flex: 1} : {})}} numberOfLines={2}>{categoryTitle}</Text>
                {(<Text dataSet={{'conditional-opacity': 1}} style={{marginLeft: K.spacing}}>→</Text>)}
              </Link>
            </View>
          )}
        </View>
      );
    }
    else if (item.type === 'header' || item.type === 'processTypeHomeHeader') {
      var showOrgIcon = org && item.orgs.length > 1;
      var showingOrgIcons = item.orgs.length > 1;
      var windowWidth = !K.isWeb && Dimensions.get('window').width; //[K.orientation === 'portrait' ? 'width' : 'height'];

      return (
        <View style={{flexDirection: 'row', alignItems: 'center', height: styles.K.rowHeight, backgroundColor: isHome ? 'white' : K.colors.gray}}>
          {isHome && (
            <View style={{
              ...(!K.isWeb ? {
                minWidth: windowWidth - stickyColumnWidth + insets.left - K.spacing,
                paddingLeft: (showOrgIcon ? 0 : K.calc(38)) + (item.hasVisibleProcessInstances ? 0 : K.calc(42)) + (windowWidth - stickyColumnWidth - 165) - insets.right
              } : {}),
              flexDirection: 'row', alignItems: 'center', justifyContent: K.isWeb ? 'flex-end' : 'flex-start',
              borderTopRightRadius: mobileRadius, borderBottomRightRadius: mobileRadius,
              alignSelf: 'stretch', marginBottom: 1, flex: 1,
              paddingRight: insets.right + K.spacing + K.calc(9) + (!org && showingOrgIcons ? 18 + K.spacing : 0),
              backgroundColor: K.colors.gray
            }}>
              {org && (
                <Tooltip text={`New project in ${categoryTitle}`}>
                  <TouchableOpacity style={{paddingHorizontal: K.calc(8), marginRight: K.calc(6)}} onPress={() => this.createProcessInstance({processType, org, isSingleUse})}>
                    <Image source={createIcon} style={{opacity: 0.4, width: K.calc(16), height: K.calc(16)}}/>
                  </TouchableOpacity>
                </Tooltip>
              )}
              {item.hasVisibleProcessInstances && (
                <Tooltip text={!item.isExpanded ? 'Show Projects' : 'Collapse'}>
                  <TouchableOpacity style={{paddingHorizontal: K.calc(8), marginRight: K.calc(10)}} onPress={item.toggleIsExpanded}>
                    <Image source={leftArrow} style={{transform: [{rotate: item.isExpanded ? '-90deg' : '180deg'}], opacity: 0.4, width: K.calc(16), height: K.calc(16)}}/>
                  </TouchableOpacity>
                </Tooltip>
              )}
              <Tooltip text={item.isUserRanked ? `You\'ve pinned this project to position ${item.processTypeRank + 1}` : 'Pin category to a specific position on your home screen'}>
                <TextInput
                  style={{opacity: item.isUserRanked ? 1 : 0.3, marginRight: showOrgIcon ? K.calc(15) : 2}}
                  inputStyle={{width: K.calc(20), textAlign: 'center', borderRadius: 0, paddingHorizontal: 0, backgroundColor: 'transparent'}}
                  value={`${item.processTypeRank + 1}`}
                  onChange={item.handleProcessTypeRankChange}
                  blurOnEnter
                  blurOnSubmit
                  returnKeyType='done'
                  selectTextOnFocus
                />
              </Tooltip>
              {showOrgIcon && (
                <Tooltip text={org.type === 'personal' ? 'My Private Workspace' : `${org.title} Workspace`}>
                  <OrgIcon {...{org}} style={{width: 20, height: 20}} initialsStyle={{opacity: 0}} hideBorder/>
                </Tooltip>
              )}
            </View>
          )}
        </View>
      );
    }
    else if (isStickyColumn) {
      if (item.type === 'sectionTitle') {
        return (
          <View style={{...styles.processInstancesLabelContainer, width: styles.processInstancesLabelContainer.width + insets.left, paddingLeft: insets.left}}>
            <Label {...s.processInstancesLabel}>{item.title}</Label>
          </View>
        );
      }
      if (item.type === 'placeholder') {
        return (
          <View style={{...styles.processInstancesPlaceholder, width: styles.processInstancesPlaceholder.width + insets.left, paddingLeft: insets.left + K.spacing}}>
            <Text {...s.processInstancesPlaceholderText}>
              {item.sectionIndex === 0 ? `Projects you create will appear here.` : `Projects you can see but aren't involved in you will appear here.`}
            </Text>
          </View>
        );
      }
      if (item.type === 'more') {
        return (
          <Link onPress={item.onPress} to={url} style={{height: styles.K.rowHeight, paddingHorizontal: K.spacing, paddingLeft: insets.left + K.spacing, justifyContent: 'center'}}>
            <Label>{`+ ${item.moreQuantity} more`}</Label>
          </Link>
        );
      }
      if (item.type === 'processInstance') {
        var {history, dotWidth} = this.props;
        var {categoryViewSettings} = this;

        return (
          <ProcessInstanceTitle
            {...{
              isHome: this.props.isHome,
              stickyColumnWidth, dotWidth,
              item, history, insets, processInstance, searchTerm, categoryViewSettings
            }}
          />
        )
      }
    }
    else {
      if (item.type === 'sectionTitle' || item.type === 'placeholder' || item.type === 'more') {
        return <View style={{height: styles.K.rowHeight}}/>
      }
      if (item.type === 'processInstance') {
        var pinData = _.get(this.props.session.user, `appData.weflowLite.pinData`, {});
        var isPinned = _.get(pinData, `processInstances.${processInstance.id}`, false);
        var showPinRankInput = isPinned && item.isPin;
        var {history, setAppData, dotWidth, usersById, session, orderBy} = this.props;
        var {categoryViewSettings, instanceRowHeight} = this;

        return (
          <ProcessInstanceRest
            orderBy={orderBy}
            {...{
              isHome: this.props.isHome,
              isPinned, showPinRankInput, updateProcessInstance,
              item, processInstance, processType, searchTerm, setAppData, pinData,
              categoryViewSettings, instanceRowHeight, orgIsAccessible, dotWidth,
              processInstanceId: processInstance.id, visibleProcessFields, usersById, session
            }}
          />
        );
      }
    }

    if (item.type === 'spacer') {
      return (
        <View style={{height: 20}}/>
      );
    }
    if (item.type === 'custom') {
      return (
        <View style={{height: styles.K.rowHeight, justifyContent: 'center', ...(isStickyColumn ? {width: stickyColumnWidth} : {})}}>
          {isStickyColumn && item.renderStickyColumn && item.renderStickyColumn()}
        </View>
      );
    }
  }

  get categoryViewSettings() {
    return this.props.categoryViewSettings;
  }

  get instanceRowHeight() {
    var {instanceRowHeight} = styles.K;
    var {categoryViewSettings} = this;

    if (!this.props.isHome) {
      var showDates = _.get(categoryViewSettings, 'showCompletedDates', 0) === 1 || _.get(categoryViewSettings, 'showByWhenDates', 0) === 1;
      var showAssignees = _.get(categoryViewSettings, 'showAssignees', 0) === 1;

      if (showAssignees) instanceRowHeight += K.calc(30);
      if (showDates) instanceRowHeight += K.calc(20);
    }

    return instanceRowHeight;
  }

  handleSearch = (value) => {
    prepareToAnimate();

    this.setState({searchTerm: value});
  }

  filterBySearchTerm = ({processInstances, filters=[]}) => {
    var searchTerm = _.trim(_.lowerCase(this.props.activeView.data.searchTerm || ''));

    if (searchTerm || filters.length > 0) {
      var {processFields} = this.props;

      processInstances = _.filter(processInstances, processInstance => {
        var passedFilters = _.every(filters, ({fieldId, operator, criterion}) => {
          var field = processFields[fieldId];
          var passed = false;

          if (field.type === 'dropdown') {
            passed = _.get(processInstance.fields, fieldId) === criterion;
          }
          else if (field.type === 'text') {
            passed = _.get(processInstance.fields, fieldId) === criterion;
          }
          else if (field.type === 'currency') {
            passed = _.get(processInstance.fields, fieldId) === (parseInt(criterion) * 100);
          }

          if (!criterion) passed = true;

          return passed;
        });

        return (searchTerm ? fuzz.partial_ratio(searchTerm, processInstance.title) > 80 : true) && passedFilters;
      });
    }

    return processInstances;
  }

  onRefresh = (callback) => {
    this.props.considerLoadingResources({forceReload: true, callback});
  }

  get showStepTitles() {
    return !!this.categoryViewSettings.showStepTitles && !!this.props.processType;
  }

  render() {
    var {filterBySearchTerm, showStepTitles} = this;
    var {insets, dotRowWidth, showTitleHeader} = this.props;
    var lowerSearchTerm = _.trim(_.lowerCase(_.get(this.props.activeView.data, 'searchTerm' ,'')));
    var data = this.props.dataFor({searchTerm: lowerSearchTerm, filterBySearchTerm});

    this.data = data;

    var firstItem = {
      key: 'scrollHeader',
      isScrollHeader: true,
      height: K.inputHeight + K.spacing * 2,
      render: () => (
        <View key='scrollHeader'>
          {this.props.renderScrollHeader && this.props.renderScrollHeader()}
        </View>
      )
    };

    data = _.map(data, item => ({...item, height: item.type === 'spacer' ? 20 : (item.type === 'processInstance' ? this.instanceRowHeight : styles.K.rowHeight)}));

    if (showStepTitles || showTitleHeader) {
      data = [{
        key: 'header',
        height: showStepTitles ? (K.calc(150) + K.spacing * 2) : K.calc(60)
      }, ...data];
    }

    data = [firstItem, ...data];

    return (
      <View style={{flex: 1, alignSelf: 'stretch'}}>
        <StickyTable
          stickyHeaderCount={this.props.stickyHeaderCount ?? 0}
          scrollHeaderCount={1}
          renderItem={this.renderItem}
          stickyColumnWidth={styles.K.stickyColumnWidth + insets.left}
          restWidth={K.isWeb ? dotRowWidth : undefined}
          onRefresh={this.onRefresh}
          {...{data}}
        />
      </View>
    );
  }
}

function ProcessInstanceTitle({
  isHome, stickyColumnWidth,
  history, insets, processInstance, categoryViewSettings
}) {
  return (
    <View style={{flexDirection: 'row', alignItems: !isHome && categoryViewSettings.isActuallyShowingDates ? 'flex-start' : 'center', height: '100%'}} dataSet={{'conditional-opacity-parent': 1}}>
      <Link
        onPress={() => global.cameFromHome = !!isHome}
        to={`/projects/${processInstance.id}`}
        style={{...styles.processInstanceLink, minHeight: K.fonts.standard.fontSize * 2, justifyContent: 'center', width: stickyColumnWidth, paddingLeft: insets.left + K.spacing}}
      >
        <Text style={{...styles.processInstanceTitle}} numberOfLines={2}>{processInstance.title}</Text>
      </Link>
    </View>
  );
}

function ProcessInstanceRest({
  isHome, showPinRankInput,
  item, processInstance, processType, visibleProcessFields, searchTerm, setAppData,
  categoryViewSettings, instanceRowHeight, orgIsAccessible, dotWidth, updateProcessInstance,
  usersById, session, orderBy
}) {
  var pinData = _.get(session.user, `appData.weflowLite.pinData`, {});
  var isPinned = _.get(pinData, `processInstances.${processInstance.id}`, false);
  var showPriorityInput = !isHome && !searchTerm && ((item.isSingleUse && item.org && !!item.org.usesProcessInstanceRank) || (processType && !!processType.usesProcessInstanceRank)) && orderBy === 'priority';
  var showNextDueDate = orderBy === 'nextDueDate';
  var showOwners = _.get(categoryViewSettings, 'showOwners', 0) === 1 || orderBy === 'owner';
  var owner = usersById[processInstance.ownerId];
  var tooltipStyle = {minHeight: K.step.small.diameter, justifyContent: 'center'};
  var fieldsWidth = _.sum(_.map(visibleProcessFields, processField => K.calc(100 * processField.sizeMultiplier)));

  var isOverdue = (date) => moment().isAfter(moment.utc(date).local(), 'day');

  var priorityValueFor = (item) => {
    var prioityValue = `${item.processInstanceIndex + 1}`;

    if (item.processInstance.rank || item.processInstance.rank === 0) prioityValue = `${item.processInstance.rank + 1}`;

    return prioityValue;
  }

  return (
    <View dataSet={{'conditional-opacity-parent': 1}} style={{flexDirection: 'row', height: instanceRowHeight, alignItems: !isHome && categoryViewSettings.isActuallyShowingDates ? 'flex-start' : 'center', paddingRight: K.spacing}}>
      {K.isWeb && (
        <Tooltip text={!isPinned ? 'Pin to home screen' : 'Unpin'} style={tooltipStyle}>
          <TouchableOpacity
            style={{marginRight: K.spacing, opacity: isPinned ? 1 : 0.3}}
            dataSet={{...(!isPinned || item.isPin ? {'conditional-opacity': 1} : {})}}
            onPress={() => {
              pinData.processInstances = pinData.processInstances || {};

              if (isPinned) {
                _.unset(pinData, `processInstances.${processInstance.id}`);
              }
              else {
                _.set(pinData, `processInstances.${processInstance.id}`, {rank: _.size(_.get(pinData, 'processInstances'))});
              }

              setAppData({appKey: 'weflowLite', key: 'pinData', value: pinData});
            }}
          >
            <Image source={isPinned ? unpinIcon : pinIcon} style={{width: K.calc(20), height: K.calc(20)}}/>
          </TouchableOpacity>
        </Tooltip>
      )}
      {showPinRankInput && (
        <Tooltip text={'Edit priority on your home screen'} style={tooltipStyle}>
          <TextInput
            dataSet={item.isPin ? {} : {'conditional-opacity': 1}}
            style={{opacity: 0.3, alignSelf: 'center', borderRadius: 0, height: 16, width: K.calc(30), paddingHorizontal: 0, backgroundColor: 'transparent'}}
            value={`${item.pinRank + 1}`}
            onChange={item.handlePinRankChange}
            blurOnEnter
            blurOnSubmit
            returnKeyType='done'
            selectTextOnFocus
          />
        </Tooltip>
      )}
      {showPriorityInput && (
        <Tooltip text={'Edit priority for everyone in this category'} style={tooltipStyle}>
          <TextInput
            style={{opacity: 0.3, textAlign: 'center', alignSelf: 'center', borderRadius: 0, height: 16, width: K.calc(30), paddingHorizontal: 0, marginRight: K.spacing, backgroundColor: 'transparent'}}
            value={priorityValueFor(item)}
            onChange={item.handleProcessInstanceRankChange}
            blurOnEnter
            blurOnSubmit
            returnKeyType='done'
            selectTextOnFocus
          />
        </Tooltip>
      )}
      {(showNextDueDate && !isHome) && (
        <View style={{paddingLeft: K.margin, width: K.calc(55)}}>
          {item.processInstance.nextDueDate && (
            <Tooltip text={`Next Due Date - ${moment.utc(item.processInstance.nextDueDate).local().format('MMM D')}`} style={{...tooltipStyle, cursor: 'default'}}>
              <Text style={{fontSize: K.calcFont(12), opacity: 0.5, color: isOverdue(item.processInstance.nextDueDate) ? 'red' : 'black'}}>
                {moment.utc(item.processInstance.nextDueDate).local().format('M/DD')}
              </Text>
            </Tooltip>
          )}
        </View>
      )}
      {(showOwners && owner && !isHome) && (
        <View style={{marginRight: K.spacing, width: K.calc(32)}}>
          <Tooltip text={`Owner: ${owner.name}`} style={{...tooltipStyle, cursor: 'default'}}>
            <View style={{borderWidth: 0, borderRadius: 4, backgroundColor: owner.id === session.user.id ? 'black' : lib.colors.colorFor({user: owner}), textAlign: 'center', justifyContent: 'center', alignItems: 'center', width: K.calc(32), height: K.calc(25)}}>
              <Text style={{position: 'relative', color: owner.id === session.user.id ? 'white' : 'black', letterSpacing: K.calcFont(1), fontSize: K.calcFont(12)}}>{`${owner.firstName[0] || ''}${owner.lastName[0] || ''}`}</Text>
            </View>
          </Tooltip>
        </View>
      )}
      {visibleProcessFields && visibleProcessFields.length > 0 && (
        _.map(_.orderBy(visibleProcessFields, 'rank', 'asc'), (processField) => (
          <ProcessInstanceField
            {...{processInstance, processField}}
            key={processField.id}
            toggleShowingFieldPopup={()=> this.setState({editingProcessFieldId: processField.id, processFieldPopupMode: 'update', isShowingProcessFieldsPopup: true})}
            contextKey='category'
            updateProcessInstance={updateProcessInstance}
          />
        ))
      )}
      <View style={{paddingLeft: K.isWeb ? 0 : K.spacing, flex: 1, flexDirection: 'row', justifyContent: K.isWeb ? 'flex-end' : 'flex-start'}}>
        {_.map(processType.processSteps, (processStep) => {
          var hideSubstep = processStep.level > 0 && (isHome || _.get(categoryViewSettings, 'showSubsteps', 0) === 0);

          var isNotApplicable = _.get(processInstance, `steps.${processStep.id}.status`, 'incomplete') === 'notApplicable';
          var hideNotApplicableStep = isNotApplicable && !orgIsAccessible;

          var hideStep = (hideSubstep || hideNotApplicableStep) || (isNotApplicable && processType.hideNotApplicable == 1);

          return !hideStep && (
            <ProcessInstanceStepDot
              contextKey='processInstancesView'
              key={processStep.id}
              {...{processInstanceId: processInstance.id, isHome, processStep, processType, categoryViewSettings, dotWidth, orderBy, nextDueDate: item.processInstance.nextDueDate}}
            />
          );
        })}
      </View>
    </View>
  );
}

ProcessInstanceRest = connect({
  mapState: (state, ownProps) => {
    return {
      processInstance: state.resources.processInstances.byId[ownProps.processInstanceId]
    };
  }
})(ProcessInstanceRest);

export default withRouter(withSafeAreaInsets(connect({
  mapState: (state) => {
    return {
      ..._.pick(state.activeView.data, ['showingTutorial', 'searchTerm','searchIsFocused']),
      processFields: state.resources.processFields.byId,
      usersById: state.resources.users.byId,
      activeView: state.activeView
    };
  },
  mapDispatch: {
    setActiveView, setAppData, setEvent,
    ..._.pick(resourceActions.processInstances, ['trackProcessInstances', 'updateProcessInstance', 'destroyProcessInstance']),
    ..._.pick(resourceActions.processTypes, ['updateProcessType', 'trackProcessTypes']),
    ..._.pick(resourceActions.processSteps, ['createProcessSteps'])
  }
})(ProcessInstancesView)));
