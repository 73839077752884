import App from './src/app/app';
import { store } from './src/components';
import { Platform } from 'react-native';
import { Provider } from 'react-redux';

import React from 'react';
import './global.js';

if (__DEV__ && Platform.OS !== 'web') {
  var { LogBox } = require('react-native');

  LogBox.ignoreAllLogs(true);
}

if (__DEV__) {
  process.env.REACT_APP_LOCAL_IP = '192.168.86.120' //uncomment for api access from local phone
}

export default function() {
  return (
    <Provider store={store}>
      <App/>
    </Provider>
  );
};

//HINT inject web css
if (Platform.OS === 'web') {
  const webCss = `
* {
  appearance: none;
  -webkit-appearance: none;
  outline: none !important;
  box-sizing: border-box;
}
select {
  border-width: 0px;
  font-size: 11.9px;
  letter-spacing: 0.5px;
  min-width: 0px !important;
}
img {
  image-rendering: -webkit-optimize-contrast;
  z-index: initial !important;
}
#root > div {
  border-width: 0px !important;
}
div::-webkit-scrollbar {
  background-color: #eee;
}
div::-webkit-scrollbar-track {
  box-shadow: none;
  border: none;
}
div::-webkit-scrollbar-thumb {
  background-color: #ccc;
}
div[data-word-break-all] {
  -ms-word-break: break-all;
  word-break: break-all;

  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;
}

/* HINT constrain app inside window */
html, body, #root {
  height: 100%;
}
div[data-transition-opacity] {
  transition: opacity 0.5s;
}
div[data-intro-slider] {
  overflow-x: hidden !important;
  transition: opacity 1s;
}
#root > div > div > div > div {
  flex-shrink: initial;
  overflow: hidden;
}
#root > div > div > div > div > div {
  height: 100%;
}
div[data-intro-slider] > div > div > div {
  flex-grow: 1;
}
/* HINT fix dash */
.css-view-1dbjc4n, .css-1dbjc4n {
  z-index: initial;
}
div[data-hover]:hover {
  opacity: 0.7 !important;
}
div[data-cursor-pointer] {
  cursor: pointer !important;
}
div[data-grab]:hover {
  cursor: grab;
}
div[data-grabbing] {
  cursor: grabbing;
}
div[data-hover-outline]:hover {
  border: 1px solid black !important;
}
div[data-focusable="true"]:not([data-nohover]):not([role="button"]):hover, div[data-pressable="1"]:not([data-nohover]):hover {
  opacity: 0.85;
}
div[data-conditional-opacity-parent="1"]:not(:hover) *[data-conditional-opacity="1"] {
  opacity: 0 !important;
}
div[data-report="1"] {
  page-break-after: always;
  display: block;
}
div[data-popup="1"] {
  transform: translateZ(0);
}
*[data-category-field="1"]:hover {
  background-color: #f5f5f5;
}
  `;

  const style = document.createElement('style');

  style.textContent = webCss;

  document.head.append(style);
}
