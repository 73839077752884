import React, { Component } from 'react';
import { Text, Loading, Button, TouchableOpacity, styleSpread } from '~/components';
import { View, Image, Pressable } from 'react-native';
import { setActiveView } from '~/redux/active-view/active-view';
import { connect } from '@symbolic/redux';
import closeIconWhite from '~/assets/x-icon-white.png';
import K from '~/k';
import styles from './notified-indicator.styles.js';

import _ from 'lodash';

var s = styleSpread(styles);

class NotifiedIndicator extends Component {
  close = () => {
    this.props.setActiveView({data: {notificationTriggerData: null}});
  }

  render() {
    var {activeView} = this.props;
    var {type, numberOfCollaborators} = activeView.data.notificationTriggerData || {};

    return activeView.data.notificationTriggerData ? (
      <View style={{position: 'absolute', zIndex: 10000, ...(K.isWeb ? {right: K.spacing, bottom: K.spacing * 3} : {bottom: K.spacing * 2, width: '100%', alignItems: 'center'})}}>
        <View {...s.notifiedIndicator}>
          <View {...s.notificationHeader}>
            <View style={{...styles.sourceUser}}>
              <Text {...s.sourceUserText}>Notification Sent</Text>
            </View>
            <View style={{flex: 1}}/>
            <View style={{alignItems: 'flex-end'}}>
              <Button onPress={this.close} icon={closeIconWhite} style={{...styles.closeButton, backgroundColor: K.colors.blackGray}}/>
            </View>
          </View>
          <Text {...s.notificationBody}>{`${type === 'dotPress' ? 'Nice job! ' : ''}We’ll let ${type === 'stepAssignment' ? 'them' : numberOfCollaborators > 1 ? 'your collaborators' : 'your collaborator'} know${type === 'comment' ? ' you commented' : type === 'stepAssignment' ? ' you assigned them' : ''}.`}</Text>
        </View>
      </View>
    ) : null;
  }
}

export default connect({
  mapState: state => ({activeView: state.activeView || {}, session: state.session}),
  mapDispatch: {setActiveView}
})(NotifiedIndicator);
