import K from '~/k';

var dotMargin = 0;

var s = {};
  s.dot = {
    width: K.step.height - dotMargin * 2,
    height: K.step.height - dotMargin * 2,
    borderRadius: K.step.height / 2,
    margin: dotMargin,
    borderWidth: 1
  };
  s.assignees = {
    // flex: 1,
    flexDirection: 'row'
  };

export default s;
