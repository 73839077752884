import { React, Component, _, View, styleSpread, Popup, Text, resourceActions, logAnalyticsEvent } from '~/components/index.js'; //eslint-disable-line
import { setActiveView, setEvent } from '~/redux/index.js';
import { DecisionTemplatePicker, Loading } from '@symbolic/rn-lib';
import { Linking } from 'react-native';
import { connect } from '@symbolic/redux';
import { api } from '@symbolic/lib';
import K from '~/k';

class ProtagonistPopup extends Component {
  state = {isLoading: false}

  async componentDidMount() {
    this.hasCreatedDecision = await sessionStore.get('hasCreatedDecision');

    var decisionsResponse = await api.request({uri: '/decisions/get', body: {}});

    await this.props.trackDecisions({decisions: _.get(decisionsResponse, 'data.decisions'), reset: true});
  }

  beforeCreateDecision = () => {
    this.setState({isLoading: true});
  }

  afterCreateDecision = async ({decision}) => {
    var {activeView, activeProcessInstance} = this.props;
    var {protagStepId} = activeView.data;

    await this.props.updateProcessInstance({id: activeProcessInstance.id, props: {
      steps: _.set(activeProcessInstance.steps, `${protagStepId}.decisionId`, decision.id)
    }});

    setTimeout(() => {
      var url = `http${process.env.NODE_ENV === 'production' ? 's://protag.app' : '://localhost:19007'}/decisions/${decision.id}`;

      if (K.isWeb) {
        window.open(url, '_blank');
      }
      else {
        Linking.openURL(url);
      }

      this.props.setActiveView({data: {protagPopupIsVisible: false}});
    });
  }

  render() {
    var {session, usersById} = this.props;
    var hasDecisions = _.size(this.props.decisionsById) > 0;

    return (
      <Popup scrollEnabled height={500} onClose={() => {
          this.props.setActiveView({data: {protagPopupIsVisible: false, protagStepId: null}});
          this.setState({isCreating: false});
        }}>
        {this.state.isLoading ? (
          <View style={{flex: 1, paddingTop: K.spacing * 2}}>
            <Loading text={'Creating decision\n\nthis can take a few seconds\n\nHold tight...'}/>
          </View>
        ) : (
          <View
            style={{marginTop: K.spacing, alignItems: 'center'}}
          >
            <DecisionTemplatePicker
              destroyDecisionTemplate={this.props.destroyDecisionTemplate}
              {...{session, sessionStore, usersById, logAnalyticsEvent}}
              processStepId={_.get(this.props.activeView, 'data.protagStepId')}
              trackDecisionSheets={this.props.trackDecisionSheets}
              showBlank={hasDecisions || this.hasCreatedDecision}
              beforeCreateDecision={this.beforeCreateDecision}
              processId={this.props.activeProcessInstance.id}
              afterCreateDecision={this.afterCreateDecision}
              trackDecisions={this.props.trackDecisions}
              onSelect={this.handleTemplateSelect}
            />
          </View>
        )}
      </Popup>
    );
  }
}

export default connect({
  mapState: ({session, activeView, resources}) => ({
    session, usersById: resources.users.byId, decisionsById: resources.decisions.byId, activeView
  }),
  mapDispatch: {
    setActiveView, setEvent,
    ..._.pick(resourceActions.processInstances, ['updateProcessInstance']),
    ..._.pick(resourceActions.decisions, ['updateDecision', 'trackDecisions']),
    ..._.pick(resourceActions.decisionSheets, ['trackDecisionSheets']),
    ..._.pick(resourceActions.decisionTemplates, ['destroyDecisionTemplate'])
  }
})(ProtagonistPopup);
