import { React, Component, _, View, TouchableOpacity , Image, Text, TextInput, Tooltip, styleSpread, Button, logAnalyticsEvent, resourceActions} from '../index.js';
import { connect, setAppData } from '@symbolic/redux';
import { Link, Label, TrialStatusButton, prepareToAnimate, NetworkIndicator, AttentionIndicator, Popup, ProcessTypePicker, Loading } from '@symbolic/rn-lib';
import { withRouter } from 'react-router-native';
import { setActiveView } from '~/redux/active-view/active-view';
import { withSafeAreaInsets } from 'react-native-safe-area-context';
import { categoryTitleFor } from '~/helpers/process-types';

import K from '~/k';
import HelpPopup from '../popups/help/help-popup';
import UrlPattern from 'url-pattern';
import homeImage from '../../assets/home-icon-white.png';
import shareImage from '../../assets/share.png';
import searchIcon from '../../assets/search-icon-white.png';
import downloadIcon from '~/assets/download-icon-white.png';
import closeIconWhite from '~/assets/x-icon-white.png';
import backIcon from '~/assets/left-arrow-white.png';
import styles from './header-content.styles.js';
import createIcon from '~/assets/create-icon-dark.png';

var s = styleSpread(styles);

class HeaderContent extends Component {
  state = {
    isCreating: false,
    isShowingSearchOnMobile: false
  };

  constructor(props) {
    super(props);

    this.handleSearch = _.debounce(this.handleSearch.bind(this), 400);
  }

  openCollaboratorsMenu = () => {
    this.props.setActiveView({data: {sharePopupIsVisible: true, isViewingCollaborators: true}});
  }

  finishTutorial = () => {
    this.props.setActiveView({data: {showingTutorial: false, tutorialStep: undefined, tutorialComplete: false}, spreadData: false});

    this.props.setAppData({key: 'hasSeenTutorial', value: true, appKey: 'weflowLite'});
  }

  beforeCreateProcessInstanceFromType = () => {
    this.setState({isLoading: true});
  }

  afterCreateProcessInstanceFromType = ({processInstance}) => {
    setTimeout(() => {
      this.setState({isLoading: false, isCreating: false});

      this.props.history.push(`/projects/${processInstance.id}`);
    });
  }

  breforeCreateProcessType = async () => {
    sessionStore.set('hasCreatedProcessType', true);
  }

  handleSearch = (value) => {
    prepareToAnimate();

    this.props.setActiveView({data: {searchTerm: value}});
  }

  render() {
    var {session, activeView, setActiveView, activeProcessInstance, activeProcessInstanceType} = this.props;
    var isHome = _.includes(['', '/'], this.props.location.pathname);

    var {showingTutorial, tutorialStep, tutorialComplete} = activeView.data;
    var showShareLink = !K.isWeb && _.some(['categories', 'projects'], keyword => _.includes(this.props.location.pathname, keyword));
    var isCategoryPage = _.includes(this.props.location.pathname, 'categories');
    var customProcessTypes = this.state.customProcessTypes;
    var showSearch = isCategoryPage || isHome;

    return (
      <View style={{...styles.header}}>
        {showingTutorial && !isHome ? (
          <View {...s.tutorialInfo}>
            <Button label='Exit' style={{...styles.button, width: 'auto'}} textStyle={{color: 'white'}} onPress={this.finishTutorial}/>
            <Label {...s.tutorialText}>{`${tutorialStep}/4: ${['Press + to start', 'Enter a title', 'Name your steps', 'More info'][tutorialStep - 1] || 'Tutorial'} `}</Label>
          </View>
        ) : (<>
        <View {...s.leftContent}>
          {!isHome && (
            <Tooltip left text='Home'>
              <Link to='/' style={{...styles.button, marginRight: K.margin}}>
                <Image {...s.backImage} source={homeImage}/>
              </Link>
            </Tooltip>
          )}
          {activeProcessInstance && (
            <Tooltip left text={categoryTitleFor({processType: activeProcessInstanceType, isSingleUse: _.get(activeProcessInstanceType, 'isSingleUse', 1), orgs: this.props.session.orgs, org: _.find(this.props.session.orgs, {id: activeProcessInstance.orgId})})}>
              <Link to={this.props.getBackLink({ignoreCameFromHome: true})} style={{...styles.button, marginRight: K.margin}}>
                <Image {...s.backImage} source={backIcon}/>
              </Link>
            </Tooltip>
          )}
          {!K.isWeb && showSearch && (
            <Button
              mode='dark'
              icon={this.state.isShowingSearchOnMobile ? closeIconWhite : searchIcon}
              style={{...styles.button, marginRight: K.margin}}
              onPress={() => this.setState({isShowingSearchOnMobile: !this.state.isShowingSearchOnMobile})}
            />
          )}
          {((K.isWeb && showSearch) || this.state.isShowingSearchOnMobile) && (
            <TextInput
              style={{paddingHorizontal: K.spacing, backgroundColor: K.colors.blackGray,color: '#fff', ...(K.isWeb ? {width: 200, marginRight: K.margin} : {flex: 1})}}
              onChangeText={this.handleSearch}
              value={this.props.activeView.data.searchTerm}
              placeholder='SEARCH...'
              returnKeyType={'done'}
              autoFocus={K.isWeb}
              onSubmitEditing={() => {
                prepareToAnimate();

                this.props.setActiveView({data: {searchTerm: ''}});
              }}
              onFocus={() => {
                prepareToAnimate();

              }}
              onBlur={() => {
                prepareToAnimate();
              }}
            />
          )}
          <TrialStatusButton/>
        </View>
        {!this.state.isShowingSearchOnMobile && isHome && (
          <View style={{flex: 1, alignItems: 'center'}}>

            <View style={{position: 'relative'}}>
              <Button
                style={{...styles.addButton}}
                onPress={() => this.setState({isCreating: true})}
                icon={createIcon}
                iconSize={{width: K.calc(20), height: K.calc(20)}}
              />
              {showingTutorial && tutorialStep === 1 && (<AttentionIndicator radius={20} style={{...(K.isWeb ? {} : {marginTop: -1, marginLeft: -1})}}/>)}
            </View>
            {this.state.isCreating && (
              <Popup scrollEnabled height={500} onClose={() => this.setState({isCreating: false})}>
                {this.state.isLoading ? (
                  <View style={{flex: 1, paddingTop: K.spacing * 2}}><Loading text={'Creating project\n\nthis can take a few seconds\n\nHold tight...'}/></View>
                  ) : (
                  <View style={{alignItems: 'center'}}>
                    <ProcessTypePicker
                      beforeCreateProcessInstanceFromType={this.beforeCreateProcessInstanceFromType}
                      afterCreateProcessInstanceFromType={this.afterCreateProcessInstanceFromType}
                      beforeCreateProcessType={this.beforeCreateProcessType}
                      {...{session, setActiveView, customProcessTypes, logAnalyticsEvent}}
                      {..._.pick(this.props, ['trackProcessTypes', 'trackProcessSteps', 'trackProcessInstances', 'updateProcessType'])}
                      showBlank={true}
                    />
                  </View>
                )}
              </Popup>
            )}
          </View>
        )}
        <View style={{...styles.rightContent, flex: 1, width: 'auto', marginLeft: -K.margin - K.button.width}}>
          <View {...s.rightButtonsContainer}>
            {(K.isWeb || !isHome) && (
              <View style={{marginRight: K.spacing + K.margin}}>
                <NetworkIndicator />
              </View>
            )}
            <Tooltip text='Help Menu'>
              <TouchableOpacity
                style={{...styles.button, width: 'auto', paddingHorizontal: K.spacing}}
                onPress={() => setActiveView({data: {helpPopupIsVisible: true}})}
              >
                <Label style={{color: 'white', opacity: 1}}>Help</Label>
              </TouchableOpacity>
            </Tooltip>
            {(this.props.isProcessTypeShowView || showShareLink) && (
              <Tooltip text={(K.isWeb && isCategoryPage) ? 'Download Menu' : 'Share Menu'}>
                <TouchableOpacity
                  style={{...styles.button, marginLeft: K.margin}}
                  onPress={() => setActiveView({data: {sharePopupIsVisible: true}})}
                >
                  <Image {...s.shareImage} source={(K.isWeb && isCategoryPage) ? downloadIcon : shareImage}/>
                </TouchableOpacity>
              </Tooltip>
            )}
          </View>
        </View>
        </>)}
        {(showingTutorial && tutorialComplete) && (
          <Popup closeDisabled>
            <Text style={{marginTop: K.spacing}}>{`Well done! Those are the basics.\n\nYour team may already be using Polydot for various projects.\n\nDon't be afraid to try using it for new projects too!`}</Text>
            <Button style={{marginTop: K.spacing * 3}} onPress={this.finishTutorial} text='Finish'/>
          </Popup>
        )}
        {this.props.activeView.data.helpPopupIsVisible && (
          <HelpPopup startCopilot={this.props.startCopilot} {...{activeProcessInstance}}/>
        )}
      </View>
    );
  }
}

export default withSafeAreaInsets(withRouter(connect({
  mapState: (state, ownProps) => {
    var processInstanceIdPattern = new UrlPattern('/projects/:processInstanceId(/*)');
    if (processInstanceIdPattern.match(ownProps.location.pathname)) {
      var {processInstanceId} = processInstanceIdPattern.match(ownProps.location.pathname);
      var activeProcessInstance = _.get(state.resources.processInstances.byId, parseInt(processInstanceId));
    }

    return {
      activeProcessInstance,
      isProcessTypeShowView: _.includes(ownProps.location.pathname, '/categories/'),
      activeProcessInstanceType: activeProcessInstance ? _.get(state.resources.processTypes.byId, activeProcessInstance.processTypeId) : null,
      users: state.resources.users.byId,
      activeView: state.activeView,
      session: state.session,
      customProcessTypes: _.values(state.resources.processTypes.byId),
    };
  },
  mapDispatch: {
    setActiveView, setAppData,
    ..._.pick(resourceActions.processTypes, ['trackProcessTypes', 'updateProcessType']),
    ..._.pick(resourceActions.processSteps, ['trackProcessSteps']),
    ..._.pick(resourceActions.processInstances, ['trackProcessInstances'])
  }
})(HeaderContent)));
