import K from '~/k';

var s = {};

s.shareLeftText = {
  width: '100%',
};
s.deleteImage = {
  height: K.calc(20),
  width: K.calc(20)
};

export default s;
