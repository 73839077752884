import _ from 'lodash';

function triggerNotifiedIndicator({notificationTriggerData, activeView, setActiveView, users, session}) {
  if (_.get(activeView, 'data.notificationTriggerData.timeout')) {
    clearTimeout(activeView.data.notificationTriggerData.timeout);
  }

  notificationTriggerData.timeout = setTimeout(() => {
    setActiveView({data: {notificationTriggerData: null}});
  }, 5 * 1000);

  setActiveView({data: {notificationTriggerData}});
}

export { triggerNotifiedIndicator }
