import { triggerNotifiedIndicator } from './notified-indicator';
import { prepareToAnimate, confirm } from '@symbolic/rn-lib';
import { activeInvolvedUsersFor, updateProcessInstanceStep } from './process-instances';
import moment from 'moment';
import _ from 'lodash';

//WARNING this is not just used for dot press - its used for any change to status
var handleDotPress = async ({newStatus, step, processType, processInstance, activeView, session, updateProcessInstance, additionalStepProps, setActiveView, programmatic}) => {
  prepareToAnimate();

  var processStepId = step.id;
  var steps = _.cloneDeep(processInstance.steps || {});
  var activeUserId = _.get(session, 'user.id', 0);
  var team = _.get(steps, `${step.id}.team`, []);

  var oldStatus = _.get(processInstance, `steps.${step.id}.status`, 'incomplete');

  //check if not involved user and hasn't made a change in 14 days
  var lastUpdateKey = processType.isSingleUse ? `inSingleUse${processType.orgId}` : `inCategory${processType.id}`;
  var lastProjectActivityByCategory = await sessionStore.get('lastProjectActivityByCategory')
  var lastUpdate = _.get(lastProjectActivityByCategory, lastUpdateKey, moment.utc().subtract(14, 'days').format("YYYY-MM-DD HH:mm:ss"));
  var isInvolvedUser = _.includes(_.keys(processInstance.involvedUsers), _.toString(activeUserId));
  var isWorkspaceOwner = _.get(_.find(session.orgs, {id: processType.orgId}), 'role') === 'owner';
  var isCategoryOwner = processType.createdByUserId === activeUserId;
  var userWhoDoesntNeedPopup = isWorkspaceOwner || isCategoryOwner || isInvolvedUser;
  var hasntUpdatedCategoryInAWhile = !userWhoDoesntNeedPopup && lastUpdate <= moment.utc().subtract(14, 'days').format('YYYY-MM-DD HH:mm:ss');
  var percentComplete = _.get(processInstance, `steps.${step.id}.percentComplete`);

  var requiresConfirmation = !programmatic && (hasntUpdatedCategoryInAWhile || (
    (oldStatus === 'complete' && _.get(processInstance.steps[step.id], 'completedDate', moment.utc().format("YYYY-MM-DD HH:mm:ss")) <= moment.utc().subtract(10, 'seconds').format('YYYY-MM-DD HH:mm:ss')) ||
    (oldStatus === 'notApplicable')
  ));

  var currentDate = moment.utc().format("YYYY-MM-DD HH:mm:ss");

  if ((!requiresConfirmation || percentComplete === 1) || await confirm('', `Are you sure you want to mark this dot as ${newStatus}?`)) {
    var stepProps = {};

    if (step.requiresCollectiveCompletion && !_.isEmpty(team)) {
      var completions = _.get(steps, `${step.id}.completions`, []);
      var assignedUserIds = _.map(team, ({userId}) => userId);

      newStatus = programmatic ? newStatus : 'incomplete';

      //HINT if using team completions and NOT using picker, newStatus is not guaranteed to be correct
      if ((programmatic && newStatus === 'incomplete') || _.find(completions, ['userId', activeUserId])) {
        _.remove(completions, _.find(completions, ['userId', activeUserId]));

        if (!programmatic) newStatus = 'incomplete';
      }
      else if (!programmatic || newStatus === 'complete') {
        completions.push({userId: activeUserId, date: moment.utc().format("YYYY-MM-DD HH:mm:ss")});
      }

      if (_.every(assignedUserIds, userId => _.find(completions, ['userId', userId]))) {
        newStatus = 'complete';

        stepProps = {
          completedByUserId: activeUserId,
          completedDate: currentDate
        };
      }

      stepProps = {status: newStatus, completions, ...stepProps, ...additionalStepProps};
    }
    else {
      stepProps = {
        status: newStatus,
        completedByUserId: activeUserId,
        completedDate: currentDate,
        ...additionalStepProps
      }
    }

    var props = {};

    if (step.isCompletionStep) {
      props.isComplete = newStatus === 'complete' ? 1 : 0;
    }
    else {
      props.isComplete = _.every(_.filter(processType.processSteps, processStep => !processStep.parentStepId), step => _.includes(['complete', 'notApplicable'], (step.id === processStepId ? newStatus : _.get(steps[step.id], 'status')))) ? 1 : 0;
    }

    if (newStatus) {
      stepProps.timestamps = {..._.get(processInstance, `steps.${step.id}.timestamps`), [newStatus]: currentDate};

      props.collapsedStepIds = _.uniq([...processInstance.collapsedStepIds || [], step.id]);
    }

    await updateProcessInstanceStep(stepProps, {processInstance, processStep: step, updateProcessInstance}, props);

    if (processInstance.isPublished) {
      var usersToNotify = _.reject(activeInvolvedUsersFor({processInstance}), {id: session.user.id});

      if (_.map(usersToNotify).length && newStatus === 'complete') {
        triggerNotifiedIndicator({setActiveView: setActiveView, activeView, notificationTriggerData: {type: 'dotPress', processInstanceId: processInstance.id, step, numberOfCollaborators: _.map(usersToNotify).length}});
      }
    }

    if (!(processType.isSingleUse && isInvolvedUser) && !programmatic) {
      lastProjectActivityByCategory = {...lastProjectActivityByCategory, [lastUpdateKey]: moment.utc().format("YYYY-MM-DD HH:mm:ss")};

      sessionStore.set('lastProjectActivityByCategory', lastProjectActivityByCategory);
    }

    return true;
  }

  return false;
}

var colors = {
  '': 'black',

  red: '#F4AAAA',
  darkRed: '#ED9191',
  darkDarkRed: '#EC7979',

  yellow: '#FACD55',
  orange: '#FAB855',
  darkOrange: '#FA9A55',

  green: '#C8D4A3',
  darkGreen: '#A9CC9D',
  darkDarkGreen: '#99CDBA',

  blue: '#ABC0DB',
  darkBlue: '#97ABC5',
  darkDarkBlue: '#839EC0',

  purple: '#C3ABDB',
  darkPurple: '#A5A1DA',
  darkDarkPurple: '#8486B9'
};

function colorFor({key, processStep}) {
  return colors[key || processStep.color] || 'black';
}

export { handleDotPress, colorFor, colors };
