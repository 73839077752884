import { React, Component, _, resourceActions, View, styleSpread, Popup } from '~/components/index.js';
import { connect } from '@symbolic/redux';
import { setActiveView } from '~/redux/active-view/active-view';
import { withRouter } from 'react-router-native';
import { Button } from '@symbolic/rn-lib';

import * as Clipboard from 'expo-clipboard';
import K from '~/k';
import styles from './share-popup.styles';

var s = styleSpread(styles);

class SharePopup extends Component {
  handleCopyURLPress = async () => {
    alert(`URL has been copied to clipboard, but sharing this url will only work if the person you share it with has access already.`);

    Clipboard.setString(`https://polydot.app${this.props.location.pathname}`);
  }

  render() {
    return (
      <Popup scrollEnabled onClose={this.props.hideSharePopup}>
        <View {...s.sharingPopupContainer}>
          {this.props.handleShareCsvPress && (
            <Button
              style={{marginBottom: K.margin}}
              onPress={this.props.handleShareCsvPress}
              text={`Export to Excel-compatible CSV file`}
            />
          )}
          {this.props.hasCompletionPopup && (
            <Button
              style={{marginBottom: K.margin}}
              onPress={this.props.exportCompletionSummary}
              text={`Export Completion Summary`}
            />
          )}
          {!K.isWeb && (
            <Button
              onPress={this.handleCopyURLPress}
              text={`Copy URL to clipboard`}
            />
          )}
        </View>
      </Popup>
    );
  }
}

export default withRouter(connect({
  mapState: state => ({
    session: state.session,
    activeView: state.activeView,
    users: _.get(state, 'resources.users.byId')
  }),
  mapDispatch: {
    ..._.pick(resourceActions.processInstances, ['updateProcessInstance']),
    ..._.pick(resourceActions.users, ['trackUsers']),
    setActiveView
  }
})(SharePopup));
