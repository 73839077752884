import K from '~/k';

var dotMargin = 0;

var s = {};
  s.stepRow = {
    // borderRadius: K.step.height / 2,

    marginBottom: K.margin,
    // backgroundColor: K.colors.gray,
    // flexDirection: 'row',
    // alignItems: 'flex-start',
    alignSelf: 'stretch'
  };
    s.dot = {
      width: K.step.height - dotMargin * 2,
      height: K.step.height - dotMargin * 2,
      borderRadius: K.step.height / 2,
      margin: dotMargin,
      borderWidth: 1
    };
    s.title = {
      paddingLeft: 0,
      paddingRight: 5,
      flexDirection: 'row',
      alignItems: 'center',
      marginRight: K.spacing,
    };
    s.rightContainer = {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-end',
      alignSelf: 'stretch',
      position: 'relative'
    };
    s.assignees = {
      // flex: 1,
      flexDirection: 'row',
      alignItems: 'center'
    };
      s.protagonistLink = {
        // display: 'flex',
        // borderRadius: 100,
        // paddingHorizontal: K.spacing,
        // backgroundColor: '#ABC0DB', height: K.step.height - dotMargin * 2, justifyContent: 'center'
      };
        // s.protagonistLinkText = {
        //   display: 'flex', alignItems: 'center', justifyContent: 'center'
        // };
        s.protagonistIcon = {
          opacity: 1,
          width: K.calc(36),
          height: K.calc(36),
          borderRadius: K.borderRadius
        };
      s.linkIcon = {
        width: K.step.height / 3,
        height: K.step.height / 3,
      };

export default s;
