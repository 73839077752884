import lib from '@symbolic/lib';

import { React, Component, View, styleSpread, resourceActions, Text, Tooltip, TouchableOpacity, AccessManager } from '~/components';
import { Image, Linking } from 'react-native';
import { setActiveView, setEvent } from '~/redux/index.js';
import { CopilotStepView, Label, TextInput, AttentionIndicator } from '@symbolic/rn-lib';
import { connect, setAppData } from '@symbolic/redux';
import { withSafeAreaInsets } from 'react-native-safe-area-context';
import { withRouter } from 'react-router-native';
import { sharingUnlocked } from '~/helpers/sharing-unlocked';
import { getAllSubsteps } from '~/helpers/process-types';

import _ from '@symbolic/lodash';
import K from '~/k';
import styles from './step-row.styles';
import moment from 'moment';
import settingsIcon from '~/assets/settings-icon.png';
import leftArrow from '~/assets/left-arrow.png';
import protagonistIcon from '~/assets/protagonist-icon-square.png';
import ProcessInstanceStepDot from '~/components/process-instance-step-dot/process-instance-step-dot';
import linkIcon from '~/assets/link-icon.png';
import commentIcon from '~/assets/comment-icon.png';

var s = styleSpread(styles);

class StepRow extends Component {
  handleInputChange = ({key, value}) => {
    this.props.updateProcessStep({id: this.props.processStep.id, props: {[key]: value}});

    this.props.updateProcessInstance({id: this.props.processInstance.id, props: {wasModified: 1}});
  }

  handleInputValueChange = ({value}) => {
    var stepInputValues = _.cloneDeep(this.props.processInstance.stepInputValues || {});

    _.set(stepInputValues, `${this.props.processStep.id}`, value);

    this.props.updateProcessInstance({id: this.props.processInstance.id, props: {stepInputValues, wasModified: 1}});
  }

  handleTitleChange = ({value}) => {
    // if (value !== undefined && value !== '') {
    //   this.props.setActiveView({data: {tutorialComplete: true}});
    // }

    this.handleInputChange({key: 'title', value});

    setTimeout(() => {
      if (this.props.activeView.data.showingTutorial && this.props.activeView.data.tutorialStep === 3 && _.every(this.props.processType.processSteps, step => !!step.title)) {
        this.props.setActiveView({data: {tutorialStep: 4}});

        //HINT wait for keyboard to dismiss
        setTimeout(() => this.props.startCopilot({key: 'processInstanceShowView', startArgs: [false, global.processInstanceScrollViewRef]}), 300);
      }
    });
  }

  handleRankInputChange = ({value}) => {
    var {processStep, processInstance} = this.props;
    var {processSteps} = this.props.processType;

    var ranks = _.split(value, '.');

    if (ranks.length > 3) {
      alert('Only 3 levels of steps are allowed');

      this.forceUpdate();
    }
    else {
      value = _.toNumber(_.last(ranks));
      var newParentStepIdIsValid = true;
      var newDepthIsValid = true;

      var newParentStepId = null;
      var children = getAllSubsteps({processStepId: processStep.id, processSteps});

      //HINT trying to set to a substep
      if (ranks.length !== 1) {
        var newParentStep = _.find(processSteps, {rankString: _.join(_.dropRight(ranks), '.')});
        newParentStepId = _.get(newParentStep, 'id');

        if (!newParentStepId || newParentStepId === processStep.id) {
          newParentStepIdIsValid = false;
        }
        else {
          //HINT check if this step has children that would now be depth > 3
          if ((_.max(_.map(children, 'level')) + 1 + _.dropRight(ranks).length - processStep.level) > 3) newDepthIsValid = false;
        }
      }

      if (newParentStepIdIsValid && newDepthIsValid) {
        if (ranks.length !== 1) {
          this.props.updateProcessSteps({updates: _.map([processStep, ...children], ({id}) => {
            var props = {color: newParentStep.color};

            if (id === processStep.id) props.parentStepId = newParentStepId;

            return {where: {id}, props};
          })});

          this.props.handleCollapsePress({processStepId: newParentStepId, forceExpand: true});
        }
        else {
          this.props.updateProcessStep({id: processStep.id, props: {parentStepId: newParentStepId}})
        }

        setTimeout(() => this.handleRankChange({value}));
      }
      else {
        alert(newDepthIsValid ? 'Please enter a valid rank' : 'Only 3 levels of steps are allowed');

        this.forceUpdate();
      }
    }
  }


  handleRankChange = ({value}) => {
    if (value) {
      var {processStep, processInstance} = this.props;
      var {processSteps} = this.props.processType;

      var isTopLevelStep = processStep.parentStepId === null || !_.includes(_.map(processSteps, 'id'), processStep.parentStepId);

      var siblings = _.orderBy(_.filter(processSteps, potentialSibling => {
        return potentialSibling.parentStepId === processStep.parentStepId || (isTopLevelStep && !_.includes(_.map(processSteps, 'id'), potentialSibling.parentStepId));
      }), 'siblingRelativeIndex');

      var oldIds = _.map(siblings, 'id');
      var oldIndex = _.indexOf(oldIds, processStep.id);

      var newIndex = value - 1;

      if (newIndex > oldIds.length - 1) newIndex = oldIds.length - 1;
      if (oldIndex !== newIndex) {
        if (!processInstance.wasModified) this.props.updateProcessInstance({id: processInstance.id, props: {wasModified: 1}});

        var newIds = _.arrayMove([...oldIds], oldIndex, newIndex);

        this.props.updateProcessSteps({updates: _.map(newIds, (id, rank) => ({props: {rank}, where: {id}}))});
      }
      else {
        this.forceUpdate();
      }
    }
    else {
      this.forceUpdate();
    }
  }

  openPopup = () => {
    this.props.history.push(`/projects/${this.props.processInstance.id}/steps/${this.props.processStep.id}`);
  }

  handleProtagonistButtonPress = step => {
    var {processInstance, session} = this.props;

    if (_.get(session.user, 'appData.weflowLite.hasSeenProtagIntegration', false)) {
      alert('This allows you to connect this step to a Protagonist decision. This is especially useful if the completion of that decision is what needs to be accomplished in this step. Protagonist is another Symbolic Frameworks app - check it out at Protag.app!');

      this.props.setAppData({key: 'hasSeenProtagIntegration', value: true, appKey: 'weflowLite'});
    }

    if (_.get(processInstance.steps, `${step.id}.decisionId`, null)) {
      var url = `http${process.env.NODE_ENV === 'production' ? 's://protag.app' : '://localhost:19007'}/decisions/${processInstance.steps[step.id].decisionId}`;

      if (K.isWeb) {
        window.open(url, '_blank');
      }
      else {
        Linking.openURL(url);
      }
    }
    else {
      this.props.setActiveView({data: {protagPopupIsVisible: true, protagStepId: step.id}});
    }
  }

  get assignees() {
    var {processInstance, processStep} = this.props;
    var teamData = _.get(processInstance, `steps.${processStep.id}.team`, []);

    return _.sortBy(_.filter(_.map(teamData, assigneeData => {
      return this.props.usersById[assigneeData.userId];
    })), [({id}) => id === this.props.session.user.id ? 1 : 0, 'id']);
  }

  get userIsAssignedToSomeStep () {
    return _.some(this.props.processInstance.steps, stepData => {
      return !!_.find(stepData.team, {userId: this.props.session.user.id});
    });
  }

  get userIsAssignedToThisStep () {
    var teamData = _.get(this.props.processInstance, `steps.${this.props.processStep.id}.team`);

    return !!_.find(teamData, {userId: this.props.session.user.id});
  }

  render() {
    var {userIsAssignedToThisStep} = this;
    var {processInstance, processStep: step, processType, index, isModifyingProcessType, isSharee, appearAsNotApplicable} = this.props;

    var status = _.get(processInstance, `steps.${step.id}.status`, 'incomplete');
    var links = _.get(processInstance, `stepLinks.${step.id}`);
    var byWhen = _.get(processInstance, `steps.${step.id}.byWhen`);
    var notes = _.get(processInstance, `stepNotes.${step.id}`);
    var inputValue = _.get(processInstance, `stepInputValues.${step.id}`);
    var isOverdue = moment().isAfter(moment.utc(byWhen).local(), 'day');
    var isShowingResponseInput = status !== 'notApplicable' && _.get(step, 'inputType') === 'text';
    var isShowingNotes = !!notes && status !== 'notApplicable';
    var hasSubsteps = _.get(_.filter(processType.processSteps, {parentStepId: step.id}), 'length', 0) > 0;
    var {outsideCollaboratorsCanEditSteps} = processInstance;
    var orgIsAccessible = _.includes(_.map(this.props.session.orgs, 'id'), processInstance.orgId);
    var completedDate = _.get(processInstance, `steps.${step.id}.completedDate`);

    var assignees = this.assignees;

    var TitleComponent = isModifyingProcessType ? View : TouchableOpacity;
    var titleProps = isModifyingProcessType ? {} : {onPress: this.openPopup};
    var hideExit = !global.isShowingHelpCopilot; //HINT help menu shows exit
    var isShowingIcons = !!(assignees.length || step.hasProtagonistDecision || links);
    var alignTop = !K.isWeb && isShowingIcons && !isShowingResponseInput && !isShowingNotes;

    var rankInputWidth = K.spacing * 2 + (_.replace(`${step.rankString}`, /[.]/g, '').length * 5) + _.replace(`${step.rankString}`, /\d/g, '').length * 4;
    var stepIndentation = K.isWeb ? K.step.height + K.margin : 0;

    return (
      <View style={{...styles.stepRow, marginLeft: step.level * stepIndentation}}>
        <View style={{alignItems: 'center', flexDirection: 'row', minHeight: K.step.height}}>
        <CopilotStepView key={hideExit + (isSharee ? 'dot-sharee' : 'dot')} {...{hideExit}} disabled={index !== 0} name='processInstanceDot' order={11} text={!isSharee ? 'Fill in the dot when the step is completed.' : `Welcome to Polydot - a simple app to help people complete a process together.\n\nFill in a dot to mark a step as complete.`}>
          <ProcessInstanceStepDot contextKey='projectPage' {...{processInstanceId: processInstance.id, processStep: step, processType, appearAsNotApplicable}} />
        </CopilotStepView>
        <CopilotStepView
          key={'grayAreaView-' + hideExit + (isSharee ? 'dot-sharee' : 'dot')}
          hideExit
          disabled={!isSharee || !userIsAssignedToThisStep} //TODO handle multiple step ownership
          order={12}
          text={`Steps you have a role in completing are highlighted with a black border`}
          style={{marginLeft: K.margin, flex: 1, borderWidth: userIsAssignedToThisStep ? 1 : 0, borderRadius: K.step.height / 2}}
        >
          <View
            dataSet={((global.copilotRunning && index === 0) ? {} : {'conditional-opacity-parent': 1})}
            style={{borderRadius: K.step.height / 2, minHeight: K.step.height, ...(alignTop ? {alignItems: 'flex-start'} : {alignItems: 'center'}), borderRadius: K.step.height / 2, backgroundColor: K.colors.gray, flexDirection: 'row', flex: 1, opacity: appearAsNotApplicable ? 0.3 : 1}}
          >
            <View style={{flex: 1, ...(alignTop ? {marginTop: 5} : {})}}>
              <TitleComponent {...titleProps} {...s.title}>
                {isModifyingProcessType ? (<>
                  <CopilotStepView key={'rank' + hideExit + (isSharee ? 'dot-sharee' : 'dot')} {...{hideExit}} order={12} disabled={isSharee || index !== 0} text={`Reorder steps by editing this number`}>
                    <TextInput
                      style={{opacity: 0.3, alignSelf: 'stretch', borderRadius: 0, paddingLeft: 0, marginLeft: K.margin, width: rankInputWidth, textAlign: 'center', paddingHorizontal: 0, backgroundColor: 'transparent'}}
                      onChange={this.handleRankInputChange}
                      value={`${step.rankString}`}
                      onChange={this.handleRankInputChange}
                      blurOnEnter
                      blurOnSubmit
                      returnKeyType='done'
                      selectTextOnFocus
                    />
                  </CopilotStepView>
                  <TextInput
                    style={{height: 'auto', alignSelf: 'center', paddingHorizontal: 0, paddingTop: 8, paddingBottom: 8, backgroundColor: 'transparent', flex: 1, borderRadius: 0}}
                    value={step.title}
                    onChange={this.handleTitleChange}
                    multiline
                    blurOnEnter
                    blurOnSubmit
                    returnKeyType='done'
                    placeholder={`UNTITLED STEP`}
                  />
                  {this.props.activeView.data.showingTutorial && this.props.activeView.data.tutorialStep === 3 && (!step.title || !processType.pluralTitle.includes('Untitled')) && (<AttentionIndicator radius={10} style={{left: 60}}/>)}
                </>) : (<>
                  <Text style={{width: rankInputWidth, marginLeft: K.margin, textAlign: 'center', opacity: 0.3}}>{step.rankString}</Text>
                  <Text style={{paddingVertical: 8, flex: 1, alignSelf: 'center'}}>{step.title || 'Untitled Step'}</Text>
                </>)}
              </TitleComponent>
              {isShowingResponseInput && (
                <TextInput
                  multiline
                  placeholder='Enter response here'
                  placeholderTextColor={'rgb(186, 121, 121)'}
                  blurOnSubmit
                  returnKeyType='done'
                  value={inputValue}
                  style={{marginLeft: rankInputWidth + K.margin, marginBottom: 6, top: -2, position: 'relative', paddingTop: 0, paddingBottom: 0, paddingHorizontal: 0, borderRadius: 0, backgroundColor: 'transparent', height: 'auto', fontStyle: 'italic', color: '#666'}}
                  onChange={({value}) => this.handleInputValueChange({value})}
                />
              )}
              {isShowingNotes && (
                <TouchableOpacity
                  onPress={this.openPopup}
                  style={{flexDirection: 'row', paddingLeft: rankInputWidth + K.margin, marginBottom: 6, top: -2}}
                  focusable={false}
                >
                  <Text style={{opacity: 0.5}}>{notes}</Text>
                </TouchableOpacity>
              )}
            </View>
            <TouchableOpacity
              onPress={this.openPopup}
              dataSet={{}}
              focusable={false}
              {...s.rightContainer}
            >
              <View style={{...(K.isWeb ? {flexDirection: 'row-reverse', alignItems: 'center'} : {alignItems: 'flex-end', paddingVertical: K.calc(12)})}}>
                <View style={{flexDirection: 'row', alignItems: 'center'}}>
                  {this.props.hasComments && (
                    <Image source={commentIcon} style={{...K.defaultIconSize, opacity: 0.5, marginLeft: K.margin, marginRight: K.calc(5)}}/>
                  )}
                  {hasSubsteps && (
                    <Tooltip text={this.props.collapsed ? 'Show Substeps' : 'Collapse'}>
                      <TouchableOpacity
                        style={{justifyContent: 'center', paddingLeft: K.spacing / 2, paddingRight: K.spacing / 2, height: K.step.height - K.calc(12) * 2}}
                        onPress={() => this.props.handleCollapsePress({processStepId: step.id})}
                        focusable={false}
                      >
                        <Image source={leftArrow} style={{...K.defaultIconSize, transform: [{rotate: !this.props.collapsed ? '0deg' : '-90deg'}], opacity: 0.7, width: K.calc(16), height: K.calc(16)}}/>
                      </TouchableOpacity>
                    </Tooltip>
                  )}
                  {status !== 'complete' && byWhen && (
                    <Label style={{marginHorizontal: K.spacing / 2, color: isOverdue ? 'red' : 'black'}}>{lib.date.formatDate(moment.utc(byWhen).local())}</Label>
                  )}
                  {status === 'complete' && completedDate && (
                    <Label style={{marginHorizontal: K.spacing / 2, color: isOverdue ? 'red' : 'black'}}>{/*<Text style={{opacity: 0.5}}>{'✓ '}</Text>*/}{lib.date.formatDate(moment.utc(completedDate).local())}</Label>
                  )}
                  <Tooltip text={(!!outsideCollaboratorsCanEditSteps || orgIsAccessible) ? 'Assign owners, dates and more' : 'View and leave comments'}>
                    <CopilotStepView
                      key={'assignment' + hideExit + (isSharee ? 'dot-sharee' : 'dot')}
                      {...{hideExit}}
                      disabled={isSharee || index !== 0}
                      text={`Assign owners, dates and more`}
                      order={13}
                      style={{alignSelf: 'stretch', justifyContent: 'center', paddingLeft: K.spacing / 2, paddingRight: K.spacing + 3}}
                    >
                      <Image source={settingsIcon} style={{...K.defaultIconSize, opacity: 0.5}}/>
                    </CopilotStepView>
                  </Tooltip>
                </View>
                {isShowingIcons && (
                  <View style={{flexDirection: 'row', alignItems: 'center', marginRight: K.spacing / 2, ...(K.isWeb ? {} : {marginTop: K.calc(10), marginRight: K.calc(8)})}}>
                    <View {...s.assignees}>
                      {_.map(assignees, (assignee, index) => (
                        <Tooltip
                          text={assignee.name}
                          key={`${assignee.id}-${index}`}
                          style={{width: K.calc(36) - (index !== assignees.length - 1 ? 6 : 0), height: K.calc(36), position: 'relative', top: -1, marginVertical: 2, justifyContent: 'center'}}
                        >
                          <View style={{...K.shadow, borderWidth: 0, borderRadius: 4, backgroundColor: assignee.id === this.props.session.user.id ? 'black' : lib.colors.colorFor({user: assignee}), textAlign: 'center', justifyContent: 'center', alignItems: 'center', width: K.calc(34), height: K.calc(28)}}>
                            <Text style={{position: 'relative', top: -1, left: 1, color: assignee.id === this.props.session.user.id ? 'white' : 'black', letterSpacing: K.calcFont(1), fontSize: K.calcFont(13)}}>{`${assignee.firstName[0] || ''}${assignee.lastName[0] || ''}`}</Text>
                          </View>
                        </Tooltip>
                      ))}
                    </View>
                    {!!step.hasProtagonistDecision && (
                      <CopilotStepView
                        key={'protagonist' + hideExit + (isSharee ? 'dot-sharee' : 'dot')}
                        {...{hideExit}}
                        disabled={!step.hasProtagonistDecision}
                        name='processInstanceProtagonistDot'
                        order={14}
                        text={`This step involves a decision. \n\nPress here to use our decision-making app, "Protagonist."`}
                        style={{marginLeft: 5}}
                      >
                        <Tooltip text='This step involves using our decision-making app, Protagonist, to choose between various options'>
                          <TouchableOpacity onPress={() => this.handleProtagonistButtonPress(step)} {...s.protagonistLink} focusable={false}>
                            <Image source={protagonistIcon} {...s.protagonistIcon}/>
                          </TouchableOpacity>
                        </Tooltip>
                      </CopilotStepView>
                    )}
                    {!!links && _.map(_.split(links, '\n'), (link, index) => {
                      var url = link;
                      var prefix = 'http://';
                      var prefix2 = 'https://';

                      if (url.substr(0, prefix.length) !== prefix && url.substr(0, prefix2.length) !== prefix2) url = prefix + url;

                      return (
                        <Tooltip text={link} key={index}>
                          <TouchableOpacity
                            style={{...styles.dot, borderWidth: 0, marginLeft: 5, borderRadius: K.borderRadius, textAlign: 'center', justifyContent: 'center', alignItems: 'center', width: K.calc(38), height: K.calc(36), backgroundColor: K.colors.doubleGray}}
                            onPress={() => K.isWeb ? window.open(url, '_blank') : Linking.openURL(url)}
                            focusable={false}
                          >
                            <Image source={linkIcon} {...s.linkIcon}/>
                          </TouchableOpacity>
                        </Tooltip>
                      );
                    })}
                  </View>
                )}
              </View>
            </TouchableOpacity>
          </View>
        </CopilotStepView>
        </View>
      </View>
    );
  }
}

export default withRouter(withSafeAreaInsets(connect({
  mapState: (state, ownProps) => {
    var stepComments = _.chain(state.resources.comments.byId)
      .filter({resourceId: ownProps.processInstance.id})
      .filter(['data.processInstanceStepId', ownProps.processStep.id])
      .value();

    return {
      ..._.pick(state, ['session', 'activeView']),
      usersById: state.resources.users.byId,
      hasComments: stepComments.length > 0
    }
  },
  mapDispatch: {
    setActiveView, setEvent, trackUsers: resourceActions.users.trackUsers, setAppData,
    ..._.pick(resourceActions.processInstances, ['updateProcessInstance']),
    ..._.pick(resourceActions.processSteps, ['updateProcessStep', 'updateProcessSteps'])
  }
})(StepRow)));
