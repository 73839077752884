import { styleSpread } from '@symbolic/rn-lib';
import { Animated, View, ScrollView } from 'react-native';
import { RefreshControl } from 'react-native';

import React from 'react';
import _ from 'lodash';
import styles from './sticky-table.styles';
import K from '~/k';

var s = styleSpread(styles);

var HorizontalScrollView = ({sideKey, sticky, children}) => {
  return (
    <Animated.ScrollView
      onScrollBeginDrag={sticky[sideKey].handleBeginDrag}
      onScroll={sticky[sideKey].handleScroll}
      showsHorizontalScrollIndicator={false}
      showsVerticalScrollIndicator={false}
      scrollEventThrottle={16}
      ref={sticky[sideKey].getRef}
      {...s.horizontalScrollView}
      contentContainerStyle={{flexDirection: 'column', position: 'relative'}}
      bounces={false}
      horizontal
    >
      {children}
    </Animated.ScrollView>
  );
}

class Item extends React.Component {
  shouldComponentUpdate(nextProps) {
    var changedVisibility = nextProps.isVisible !== this.props.isVisible;
    var eventOtherThanScrollTriggeredUpdate = nextProps.scrollY === this.props.scrollY; //BUG What if react combines state update

    return eventOtherThanScrollTriggeredUpdate || changedVisibility;
  }

  render() {
    var {item, renderItem} = this.props;

    return (
      <View style={{height: item.height, width: this.props.width}}>
        {item.isVisible !== false && renderItem(this.props)}
      </View>
    );
  }
}

export default class StickyTable extends React.Component {
  state = {scrollViewHeight: 0, isRefreshing: false};

  constructor(props) {
    super(props);

    this.scrollHelper = {lastScroll: Date.now()};
    this.scrollY = 0;

    //WARNING this sticky logic is pretty tricky
    //IMPORTANT to useNativeDriver and Animated.ScrollView
    //IMPORTANT to use Animated.event and track activeSideKey on begin drag
    this.sticky = _.mapValues({top: {}, bottom: {}}, (_value, sideKey) => {
      var oppositeSideKey = sideKey === 'top' ? 'bottom' : 'top';
      var avScrollX = new Animated.Value(0);
      var handleScroll = Animated.event([{nativeEvent: {contentOffset: {x: avScrollX}}}], {useNativeDriver: true});

      var handleBeginDrag = () => this.sticky.activeSideKey = sideKey;
      var getRef = ref => this.sticky[sideKey].ref = ref;

      avScrollX.addListener(({value: x}) => {
        if (this.sticky.activeSideKey === sideKey || (K.isWeb && this.sticky[oppositeSideKey].lastX !== x)) {
          //WARNING animated true won't help - it's buggy
          this.sticky[oppositeSideKey].ref.scrollTo({y: 0, x, animated: false});
        }

        this.sticky[sideKey].lastX = x;
      });

      return {getRef, avScrollX, handleScroll, handleBeginDrag};
    });
  }

  onRefresh = () => {
    this.setState({isRefreshing: true});

    this.props.onRefresh(() => {
      this.setState({isRefreshing: false})
    });
  }

  handleVerticalScroll = (event) => {
    this.scrollY = event.nativeEvent.contentOffset.y;

    this.forceUpdate(); //Instead of setState which can cause state batching - didn't want scroll renders to be mixed with other renders - see Item.shouldComponentUpdate

    this.scrollHelper.lastScroll = Date.now();
  }

  render() {
    var {data, renderItem, stickyHeaderCount=1, scrollHeaderCount=0} = this.props;
    var {sticky} = this;
    var {scrollViewHeight} = this.state;

    var scrollHeaderItems = data.slice(0, scrollHeaderCount);
    var stickyHeaderItems = data.slice(scrollHeaderCount, stickyHeaderCount + scrollHeaderCount);
    var restItems = data.slice(stickyHeaderCount + scrollHeaderCount);

    var innerHeight = _.sumBy(scrollHeaderItems, 'height') || 0;
    var {scrollY} = this;

    var threshold = 500;

    _.forEach(restItems, (item) => {
      var itemY = innerHeight;

      innerHeight += item.height;

      item.isVisible = itemY >= scrollY - threshold && itemY + item.height < scrollY + scrollViewHeight + threshold;
    });

    //TODO potential perf improvement
    //create a view above and below visible items instead of <Item>
    //the main benefit of <Item is to prevent renderItem from being called again rather than to change from visible to invisible
    //value: 1000 views => 10 visible => 12 views total with 2 empty rather than 990 empty

    //TODO figure out what's up with textinput at top of scrollview causing incorrect scroll behavior

    return (
      <View {...s.table}>
        <View {...s.stickyHeader}>
          <View {...s.stickyCorner}>
            {_.map(stickyHeaderItems, (item, index) => (
              <Item key={item.key} {...{item, index, width: this.props.stickyColumnWidth, renderItem, isStickyColumn: true, isStickyHeader: true}}/>
            ))}
          </View>
          <HorizontalScrollView sideKey='top' {...{sticky}}>
            {_.map(stickyHeaderItems, (item, index) => (
              <Item key={item.key} {...{item, index, width: this.props.restWidth, renderItem, isStickyHeader: true}}/>
            ))}
          </HorizontalScrollView>
        </View>
        <ScrollView
          style={{...styles.verticalScrollView}}
          keyboardShouldPersistTaps='handled'
          innerRef={ref => this.scrollViewRef = ref}
          // bounces={false}
          // extraHeight={75 + (this.state.scrollViewTop || 0)}
          onScroll={this.handleVerticalScroll}
          scrollEventThrottle={30}
          refreshControl={<RefreshControl refreshing={this.state.isRefreshing} onRefresh={this.onRefresh} enabled={true}/>}
          onLayout={(event) => {
            if (this.state.scrollViewHeight !== event.nativeEvent.layout.height) {
              this.setState({scrollViewHeight: event.nativeEvent.layout.height});
            }

            // if (this.scrollViewRef && this.scrollViewRef.measure) {
            //   this.scrollViewRef.measure((_x, _y, _width, _height, _pageX, pageY) => {
            //     this.state.scrollViewTop !== pageY && this.setState({scrollViewTop: pageY});
            //   });
            // }
          }}
          contentContainerStyle={this.props.verticalScrollViewContentContainerStyle}
        >
          {stickyHeaderCount === 0 && _.map(scrollHeaderItems, (item) => renderItem({item, index: 0, isScrollHeader: true}))}
          <View {...s.innerTable}>
            <View {...s.stickyColumn}>
              {_.map(restItems, (item, index) => (
                <Item key={item.key} {...{item, width: this.props.stickyColumnWidth, index: index + stickyHeaderCount + scrollHeaderCount, isStickyColumn: true, isVisible: item.isVisible, scrollY: this.scrollY, renderItem}}/>
              ))}
            </View>
            <HorizontalScrollView sideKey='bottom' {...{sticky}}>
              {_.map(restItems, (item, index) => (
                <Item key={item.key} {...{item, width: this.props.restWidth, index: index + stickyHeaderCount + scrollHeaderCount, isVisible: item.isVisible, scrollY: this.scrollY, renderItem}}/>
              ))}
            </HorizontalScrollView>
          </View>
        </ScrollView>
      </View>
    );
  }
}
