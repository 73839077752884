import { React, Component, View, ScrollView, styleSpread, Button, logAnalyticsEvent, resourceActions, Text, AccessManager, LabelledView } from '~/components'; //eslint-disable-line

import { setActiveView, setEvent } from '~/redux/index.js';
import { connect } from '@symbolic/redux';
import { PickerInput, TextInput, Tooltip, OrgIcon } from '@symbolic/rn-lib';
import { withSafeAreaInsets } from 'react-native-safe-area-context';
import { processTypeFor, processTypesFor, categoryTitleFor, getCanModifyProcessType } from '~/helpers/process-types';
import { createProcessInstance } from '~/helpers/process-instances';
import { DocumentTitle } from '@symbolic/rn-lib';
import { api } from '@symbolic/lib';

import _ from '@symbolic/lodash';
import K from '~/k';
import Papa from 'papaparse';
import moment from 'moment';
import SharePopup from '~/components/popups/share/share-popup';
import ProcessInstancesView from '~/components/process-instances-view/process-instances-view';
import CategorySettingsPopup from '~/components/popups/category-settings/category-settings-popup';


import styles from './category-page.styles';
import createIcon from '~/assets/create-icon-white.png';
import settingsIcon from '~/assets/settings-icon.png';

var s = styleSpread(styles);

class ProcessTypeShowView extends Component {
  state = {
    orderBy: this.usesProcessInstanceRank ? 'priority' : 'creationDate',
    processInstancesViewForceRender: 0,
    fieldFilters: [],
    isAddingFilterCriteria: false,
    filterMode: 'active',
    ownerIdFilter: '*',
    pageCount: 1
  };

  createProcessInstance = async () => {
    await createProcessInstance({
      ..._.pick(this.props, ['processType', 'org', 'session', 'history', 'trackProcessTypes', 'createProcessSteps', 'trackProcessInstances', 'updateProcessType'])
    });
  }

  handleProcessTypeTitleChange = ({value}) => {
    this.props.updateProcessType({props: {pluralTitle: value}, id: this.props.processType.id});
  }

  handleProcessInstanceRankChange = ({value, processInstanceId}) => {
    var updates = [];
    var newRank = parseInt(value) - 1;
    var sortedFilteredProcessInstances = this.sortedFilteredProcessInstancesFor({processInstances: this.props.processInstances});

    var oldRank = _.map(sortedFilteredProcessInstances, 'id').indexOf(processInstanceId);
    var oldIds = _.map(sortedFilteredProcessInstances, 'id');

    if (newRank > oldIds.length - 1) newRank = oldIds.length - 1;

    if (oldRank !== newRank) {
      var newIds = _.arrayMove([...oldIds], oldRank, newRank);

      _.forEach(newIds, (id, rank) => updates.push({where: {id}, props: {rank}}));

      this.props.updateProcessInstances({updates});

      setTimeout(() => this.setState({processInstancesViewForceRender: this.state.processInstancesViewForceRender + 1}));
    }
  }

  handleOrderByChange = ({value}) => this.setState({orderBy: value});

  handleFilterModeChange = ({value}) => this.setState({filterMode: value});

  handleOwnerFilterChange = ({value}) => this.setState({ownerIdFilter: value});

  handleShareCsvPress = async () => {
    var filename = _.kebabCase(this.categoryTitle);
    var {processInstances} = this.props;
    var {processTypes} = this;

    var csv = [];

    _.forEach(processTypes, processType => {
      var stepTitleBlanks = _.times(processType.processSteps.length - 1, '');
      var stepTitles = _.map(processType.processSteps, 'title');

      csv.push(
        ['', 'Completions', ...stepTitleBlanks, 'Deadlines',  ...stepTitleBlanks, 'Statuses'],
        ['Title', ...stepTitles, ...stepTitles, ...stepTitles]
      );

      _.forEach(_.filter(processInstances, {processTypeId: processType.id}), instance => {
        csv.push([
          instance.title,
          ..._.map(processType.processSteps, (processStep) => { //completion dates
            var status = _.get(instance, `steps[${processStep.id}].status`, processType.defaultStepStatus);
            var completedDate = _.get(instance, `steps[${processStep.id}].completedDate`, '');

            return status === 'complete' ? `${completedDate}` : '';
          }),
          ..._.map(processType.processSteps, (processStep) => { //deadline dates

            return _.get(instance, `steps[${processStep.id}].byWhen`, '');
          }),
          ..._.map(processType.processSteps, (processStep) => { //statuses
            return _.get(instance, `steps[${processStep.id}].status`, 'incomplete');
          })
        ]);
      });

      csv.push([])
    });

    csv = Papa.unparse(csv);

    if (K.isWeb) {
      filename = `${filename}.csv`;

      var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });

      if (navigator.msSaveBlob) { // IE 10+
        navigator.msSaveBlob(blob, filename);
      }
      else {
        var link = document.createElement("a");
        if (link.download !== undefined) { // feature detection
          // Browsers that support HTML5 download attribute
          var url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", filename);
          link.style.visibility = 'hidden';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
    }
    else {
      var newFileLocation = `${FileSystem.cacheDirectory}${filename}.csv`;

      await FileSystem.writeAsStringAsync(newFileLocation, csv);

      this.share({url: newFileLocation, message: 'Share CSV'});
    }

    logAnalyticsEvent('export_csv');
  }

  exportCompletionSummary = async () => {
    var {processType, isSingleUse, org} = this.props;
    var filename = `${_.kebabCase(this.categoryTitle)}-completion-data`;
    var {processInstances} = this.props;
    var csv = [];

    csv.push([
      'Step', 'Project', 'Projected hours',  'Actual hours', 'Hour difference',
      'Notes', 'Had Rework', 'People Out', 'Materials / Parts not in house', 'Complex product'
    ]);

    _.forEach(processType.processSteps, processStep => {
      if (processStep.id !== 1838 && processStep.id !== 1839){
        _.forEach(processInstances, instance => {
          var isComplete = _.get(instance, `steps[${processStep.id}].status`, 'incomplete') === 'complete';

          if (isComplete && moment(instance.steps[processStep.id].completedDate).isAfter(moment().subtract(30, 'days')) ){
            var projectedHours = _.get(instance, `steps[${processStep.id}].size`, '');
            var actualHours  = _.get(instance, `steps[${processStep.id}].hoursToComplete`, '');
            var timeDifference = actualHours - projectedHours;

            csv.push([
              processStep.title, // step name
              instance.title, //project name
              projectedHours, // projected hours
              actualHours, // actual hours
              timeDifference, // hour difference
              _.get(instance, `steps[${processStep.id}].completionNotes`, ''), //'notes'
              _.get(instance, `steps[${processStep.id}].hadRework`, 0) === 1 ? 'y' : 'n', //had rework
              _.get(instance, `steps[${processStep.id}].teammatesWereMissing`, 0) === 1 ? 'y' : 'n', //People out
              _.get(instance, `steps[${processStep.id}].requiredMaterialsWereNotReady`, 0) === 1 ? 'y' : 'n', //Materials...
              _.get(instance, `steps[${processStep.id}].workWasComplex`, 0) === 1 ? 'y' : 'n', //complex product
            ]);
          }
        });

        csv.push([]);
      }
    });

    csv = Papa.unparse(csv);

    if (K.isWeb) {
      filename = `${filename}.csv`;

      var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });

      if (navigator.msSaveBlob) { // IE 10+
        navigator.msSaveBlob(blob, filename);
      }
      else {
        var link = document.createElement("a");
        if (link.download !== undefined) { // feature detection
          // Browsers that support HTML5 download attribute
          var url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", filename);
          link.style.visibility = 'hidden';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
    }
    else {
      var newFileLocation = `${FileSystem.cacheDirectory}${filename}.csv`;

      await FileSystem.writeAsStringAsync(newFileLocation, csv);

      this.share({url: newFileLocation, message: 'Share CSV'});
    }

    logAnalyticsEvent('export_csv');
  }

  get visibleInstanceCount() {
    var pageSize = 1000;

    return this.state.pageCount * pageSize;
  }

  get processInstancesWithDueDates() {
    return _.map(this.props.processInstances, (processInstance) => {
      var stepsWithDueDates = _.filter(processInstance.steps, (step) => step.status !== 'complete' && step.byWhen);

      var nextDueDate = _.get(_.sortBy(stepsWithDueDates, 'byWhen'), '[0].byWhen');

      return {...processInstance, nextDueDate};
    });
  }

  get someProcessInstanceHasDueDate() {
    var nextDueDates = _.chain(this.processInstancesWithDueDates)
      .map((processInstance) => processInstance.nextDueDate)
      .compact()
      .value();

    return nextDueDates.length > 0;
  }

  get usesProcessInstanceRank() {
    return ((this.props.processType && this.props.processType.usesProcessInstanceRank) || (this.props.isSingleUse && this.props.org && this.props.org.usesProcessInstanceRank));
  }

  sortedFilteredProcessInstancesFor({processInstances}) {
    var {filterMode, ownerIdFilter, orderBy} = this.state;

    processInstances = processInstances.filter(processInstance => {
      if (filterMode === 'active') return !(processInstance.isComplete || processInstance.isArchived);
      if (filterMode === 'complete') return !!processInstance.isComplete;
      if (filterMode === 'archived') return !!processInstance.isArchived;
      if (filterMode === 'all') return true;
    });

    if (ownerIdFilter !== '*') {
      processInstances = processInstances.filter(processInstance => {
        return processInstance.ownerId === parseInt(ownerIdFilter);
      });
    }

    if (orderBy === 'priority' && this.usesProcessInstanceRank) {
      processInstances = _.sortBy(processInstances, ['rank', 'id']);
    }
    if (orderBy === 'creationDate') {
      processInstances = _.sortBy(processInstances, 'created');
    }
    if (orderBy === 'owner') {
      processInstances = _.sortBy(processInstances, [
        ({ownerId}) => this.props.usersById[ownerId] ? 0 : 1,
        ({ownerId}) => this.props.usersById[ownerId].name
      ]);
    }
    if (orderBy === 'nextDueDate') {
      processInstances = _.sortBy(this.processInstancesWithDueDates, ['nextDueDate', this.usesProcessInstanceRank ? 'rank' : 'id']);
    }
    if (_.startsWith(orderBy, 'process-step-')) {
      var processStepId = parseInt(_.trimStart(orderBy, 'process-step-'));

      processInstances = _.chain(processInstances)
        .filter(({steps}) => _.get(steps[processStepId], 'status') !== 'notApplicable')
        .orderBy(({steps}) => {
          var completedDate = _.get(steps[processStepId], 'completedDate');
          var byWhenDate = _.get(steps[processStepId], 'byWhen');
          var isComplete = _.get(steps[processStepId], 'status') === 'complete';

          //HINT if not complete and byWhenDate is not defined, put at the end of the list
          return [isComplete ? completedDate : byWhenDate || 9999999];
        }, ['asc'])
        .value();

        console.log(processInstances);
    }

    return processInstances;
  }

  dataForProcessInstancesView = ({filterBySearchTerm}) => {
    var {processType} = this.props;
    var {visibleInstanceCount} = this;
    var isSingleUse = this.props.isSingleUse || this.props.isShared;
    var data = [];

    var sortedFilteredProcessInstances = this.sortedFilteredProcessInstancesFor({
      processInstances: filterBySearchTerm({processInstances: this.props.processInstances,
      filters: this.state.fieldFilters
    })});

    var visibleProcessInstances = sortedFilteredProcessInstances.slice(0, visibleInstanceCount);

    _.forEach(visibleProcessInstances, (processInstance, index) => {
      if (isSingleUse) processType = _.find(this.props.allProcessTypes, {id: processInstance.processTypeId});

      if (processType) data.push({key: processInstance.id, type: 'processInstance', processInstanceIndex: index, processInstance, processType, isSingleUse, org: this.props.org, handleProcessInstanceRankChange: ({value}) => this.handleProcessInstanceRankChange({value, processInstanceId: processInstance.id})});
    });

    if (sortedFilteredProcessInstances.length > visibleInstanceCount) {
      data.push({
        key: `more`,
        type: 'more',
        moreQuantity: sortedFilteredProcessInstances.length - visibleInstanceCount,
        onPress: () => {
          this.setState({pageCount: this.state.pageCount + 1})
        }
      });
    }

    _.times(6, i => data.push({key: `extraSpacer${i}`, type: 'spacer'}));

    return data;
  }

  handleFilterCategoryChange = ({value}) => {
    if (value !== 'none') {
      var {fieldFilters} = this.state;

      // this will be more correct with multiple filters
      // if (_.every(fieldFilters, ({id}) => id !== value)) {
      //   fieldFilters.push({fieldId: parseInt(value), operator: '='});

      //   this.setState({fieldFilters});
      // }

      // only correct if there is one filter
      fieldFilters = [{fieldId: parseInt(value), operator: '='}];

      this.setState({fieldFilters});
    }
    else {
      this.setState({fieldFilters: []});
    }
  }

  handleCriterionChange = async ({fieldId, value}) => {
    var {fieldFilters} = this.state;

    var fieldFilter = _.find(fieldFilters, {fieldId});

    var field = _.find(this.props.processFields, {id: fieldId});

    if (!field.visibleInCategoryView) {
      await this.props.updateProcessFields({updates: [{where: {id: fieldId}, props: {visibleInCategoryView: true}}]});

      var field = await api.get('processField', {where: {id: fieldId}});

      await this.props.trackProcessFields({processFields: [field]});
    }

    fieldFilter.criterion = value;

    fieldFilters[_.findIndex(fieldFilters, fieldFilter)] = fieldFilter;

    this.setState({fieldFilters});
  }

  get processTypes() {
    return _.uniqBy(_.filter(_.map(this.props.processInstances, instance => _.find(this.props.allProcessTypes, {id: instance.processTypeId})), p => !!p), 'id');
  }

  get showSubsteps() {
    return this.props.getCategoryViewSettings().showSubsteps;
  }

  get visibleProcessSteps() {
    var {processType} = this.props;

    var visibleProcessSteps = _.get(processType, 'processSteps');

    if (!this.showSubsteps) {
      visibleProcessSteps = _.filter(_.get(processType, 'processSteps'), {parentStepId: null});
    }

    return visibleProcessSteps;
  }

  get maxStepCount() {
    var {processType} = this.props;

    var maxStepCount;

    if (processType) { //HINT standardized category
      var {visibleProcessSteps} = this;

      if (processType.hideNotApplicable) {
        maxStepCount = _.max(_.map(this.props.processInstances, processInstance => _.size(_.filter(visibleProcessSteps, processStep => _.get(processInstance, `steps.${processStep.id}.status`) !== 'notApplicable'))));
      }
      else {
        maxStepCount = _.size(visibleProcessSteps);
      }
    }
    else {
      maxStepCount = _.max(_.map(this.processTypes, processType => _.size(this.showSubsteps ? processType.processSteps : _.filter(processType.processSteps, {parentStepId: null}))));
    }

    return Math.max(4, maxStepCount || 4);
  }

  get ownerFilterOptions() {
    return [
      {value: '*', title: 'Anyone'},
      ..._.chain(this.props.processInstances)
        .map(({ownerId}) => ownerId)
        .uniq()
        .filter(ownerId => !!this.props.usersById[ownerId])
        .map((ownerId) => ({value: ownerId, title: this.props.usersById[ownerId].name}))
        .sortBy('title')
        .value()
    ];
  }

  get orderByOptions() {
    var orderByOptions = [
      {value: 'creationDate', title: 'Creation Date'},
      {value: 'owner', title: 'Owner'}
    ];

    if (this.someProcessInstanceHasDueDate) orderByOptions = [{value: 'nextDueDate', title: 'Next Due Date'}, ...orderByOptions];

    if (this.usesProcessInstanceRank) orderByOptions = [{value: 'priority', title: 'Priority'}, ...orderByOptions];

    var categoryProcessSteps = _.get(this.props, 'processType.processSteps');

    if (categoryProcessSteps && !_.isEmpty(categoryProcessSteps)) {
      var processStepOptions = _.map(categoryProcessSteps, ({id, title}) => (
        {value: `process-step-${id}`, title: `${_.startCase(title)} Date`}
      ));

      orderByOptions = [...orderByOptions, ...processStepOptions];
    }

    return orderByOptions;
  }

  get categoryTitle() {
    var {session, isSingleUse, org, processType} = this.props;

    var orgId = org ? org.id : _.get(processType, 'orgId');
    var categoryOrg = _.find(session.orgs, {id: orgId});

    return categoryTitleFor({processType, isSingleUse, org: categoryOrg, orgs: this.props.session.orgs});
  }

  render() {
    var {orderBy} = this.state;
    var {insets, session, sharePopupIsVisible, isSingleUse, org, processType} = this.props;
    var {user} = session;
    var {categoryTitle} = this;

    var visibleProcessFields = _.sortBy(_.filter(this.props.processFields, processField => {
      return processField.visibleInCategoryView === 1 && !processField.isDisabled && (processField.isInternalOnly === 0 || _.includes(_.map(this.props.session.orgs, 'id'), processField.orgId));
    }), 'rank');

    var dotWidth = this.props.getDotWidth();

    var dotRowWidth = this.props.getDotRowWidth({
      maxStepCount: this.maxStepCount, processType, org, isSingleUse, visibleProcessFields,
      isOrderedByOwner: orderBy === 'owner',
      isOrderedByNextDueDate: orderBy === 'nextDueDate',
      isOrderedByPriority: orderBy === 'priority'
    });

    var dotRowLeftWidth = this.props.getDotRowLeftWidth({
      processType, org, isSingleUse, visibleProcessFields,
      isOrderedByOwner: orderBy === 'owner',
      isOrderedByNextDueDate: orderBy === 'nextDueDate',
      isOrderedByPriority: orderBy === 'priority',
    });

    if (!processType && !isSingleUse && !org) return null; //HINT was deleted

    var orgId = org ? org.id : _.get(processType, 'orgId');
    var categoryOrg = _.find(session.orgs, {id: orgId});

    var isInAccessibleWorkspace = _.includes(_.map(session.orgs, 'id'), orgId);
    var isInSharedWorkspace = isInAccessibleWorkspace && orgId !== user.personalOrgId;
    var canModifyProcessType = !isSingleUse && getCanModifyProcessType({processType, session});
    var hasCompletionPopup = processType && processType.id === 187;

    var createMessage = `Your 30-day trial has expired, so creating new projects in a shared workspace is temporarily disabled.

If you're part of a team, you can ask them to set you up with a paid plan that unlocks this feature, or you can purchase it yourself for $4/month.`;

    return (
      <DocumentTitle title={`${categoryTitle} - Polydot`}>
        <View style={{flex: 1}}>
          <View style={{backgroundColor: K.colors.gray, marginBottom: 1, padding: K.spacing, paddingHorizontal: K.spacing + insets.left, flexDirection: 'row', justifyContent: 'center'}}>
            <View style={{flex: 1, width: '100%', ...(K.isWeb ? {maxWidth: K.spacing + styles.K.stickyColumnWidth + dotRowWidth} : {})}}>
              {canModifyProcessType ? (<>
                <TextInput
                  multiline
                  style={{...K.fonts.pageHeader, borderRadius: 0, paddingHorizontal: 2, marginLeft: K.spacing - 2, height: 'auto', ...(K.isWeb ? {} : {top: -3})}}
                  value={processType.pluralTitle}
                  onChange={this.handleProcessTypeTitleChange}
                  blurOnSubmit
                  returnKeyType='done'
                  selectTextOnFocus={_.includes(processType.pluralTitle, 'Untitled')}
                />
              </>) : (
                <Text style={{...K.fonts.pageHeader, marginLeft: K.spacing - 2}}>{categoryTitle}</Text>
              )}
              <View style={{flexDirection: 'row', alignItems: 'flex-end', marginTop: K.spacing * 2}}>
                {K.isWeb && (
                  <LabelledView label={'Order by'} styles={{innerView: {flexDirection: 'row'}}}>
                    <PickerInput
                      style={{height: K.inputHeight}}
                      buttonStyle={{backgroundColor: K.colors.doubleGray}}
                      showDownArrow={true}
                      options={this.orderByOptions}
                      value={this.state.orderBy}
                      onChange={this.handleOrderByChange}
                    />
                  </LabelledView>
                )}
                <LabelledView label='Status' styles={{innerView: {flexDirection: 'row'}, outerView: {marginLeft: K.margin}}}>
                  <PickerInput
                    style={{height: K.inputHeight}}
                    buttonStyle={{backgroundColor: K.colors.doubleGray}}
                    showDownArrow={true}
                    options={[
                      {value: 'active', title: 'Active'},
                      {value: 'complete', title: 'Complete'},
                      {value: 'archived', title: 'Archived'},
                      {value: 'all', title: 'All'},
                    ]}
                    value={this.state.filterMode}
                    onChange={this.handleFilterModeChange}
                  />
                </LabelledView>
                {K.isWeb && (
                  <LabelledView label='Owner' styles={{innerView: {flexDirection: 'row'}, outerView: {marginLeft: K.margin}}}>
                    <PickerInput
                      style={{height: K.inputHeight}}
                      buttonStyle={{backgroundColor: K.colors.doubleGray}}
                      showDownArrow={true}
                      options={this.ownerFilterOptions}
                      value={this.state.ownerIdFilter}
                      onChange={this.handleOwnerFilterChange}
                    />
                  </LabelledView>
                )}
                {K.isWeb && _.get(this.props.processFields, 'length', 0) > 0 &&  (
                  <LabelledView label='Filters' styles={{innerView: {flexDirection: 'row'}, outerView: {marginLeft: K.margin}}}>
                    <PickerInput
                      options={[{value: 'none', title: 'No filter'}, ..._.map(this.props.processFields, ({id, title}) => ({value: id, title}))]}
                      value={_.get(this.state.fieldFilters, '[0].fieldId', null)}
                      buttonStyle={{backgroundColor: K.colors.doubleGray}}
                      onChange={this.handleFilterCategoryChange}
                      style={{height: K.inputHeight}}
                      showDownArrow={true}
                    />
                  </LabelledView>
                )}
                {this.state.fieldFilters.length > 0 && _.map(this.state.fieldFilters, ({fieldId, criterion}) => {
                  var field = _.find(this.props.processFields, {id: fieldId});
                  var options = _.filter(_.get(field, 'data.options'), {isDeleted: 0});

                  return (
                    <LabelledView label='Search Value' styles={{innerView: {flexDirection: 'row'}, outerView: {marginLeft: K.margin}}} key={fieldId}>
                      {field.type === 'dropdown' ? (
                        <PickerInput
                          onChange={({value}) => this.handleCriterionChange({fieldId, value})}
                          options={_.map(options, ({title, value}) => ({title, value}))}
                          buttonStyle={{backgroundColor: K.colors.doubleGray}}
                          value={criterion || options[0]}
                          style={{height: K.inputHeight}}
                          showDownArrow={true}
                        />
                      ) : (
                        <TextInput
                          onChange={({value}) => this.handleCriterionChange({fieldId, value})}
                          style={{height: K.inputHeight}}
                          value={processType.pluralTitle}
                          returnKeyType='done'
                          blurOnSubmit
                        />
                      )}
                    </LabelledView>
                  );
                })}
                <View style={{flexDirection: 'row', marginLeft: K.margin}}>
                  <Button
                    style={{backgroundColor: K.colors.doubleGray}}
                    icon={settingsIcon}
                    onPress={() => this.setState({isEditing: true})}
                  />
                  {categoryOrg && <OrgIcon {...{org: categoryOrg}} style={{height: K.inputHeight, marginLeft: K.margin, ...(K.isWeb ? {cursor: 'pointer'} : {})}}/>}
                </View>
              </View>
            </View>
          </View>
          <ProcessInstancesView
            processType={processType}
            orderBy={this.state.orderBy} //HINT trigger render on change
            filterMode={this.state.filterMode} //HINT trigger render on change
            ownerIdFilter={this.state.ownerIdFilter} //HINT trigger render on change
            pageCounts={this.state.pageCount} //HINT trigger render on change
            processInstanceIds={_.join(_.map(this.props.processInstances, 'id'), ' ')} //hint trigger render on change
            processTypeIds={_.join(_.map(this.props.allProcessTypes, 'id'), ' ')} //hint trigger render on change
            processInstancesViewForceRender={this.state.processInstancesViewForceRender} //HINT trigger render on change
            dataFor={this.dataForProcessInstancesView}
            categoryViewSettings={this.props.getCategoryViewSettings({isHome: false})}
            considerLoadingResources={this.props.considerLoadingResources}
            visibleProcessFields={visibleProcessFields}
            stickyHeaderCount={K.isWeb && !!processType}
            showTitleHeader
            {...{dotRowLeftWidth, dotRowWidth, dotWidth}}
          />
          {!this.props.isShared && (
            <View style={{...styles.addButtonContainer, bottom: K.spacing * (K.isWeb ? 5 : 3), ...(K.orientation === 'landscape' ? {right: K.spacing * 2} : {alignItems: 'center', width: '100%'})}}>
              <Tooltip text={isSingleUse ? `Create new blank project here` : `New ${processType.title} in ${processType.pluralTitle}`}>
                <AccessManager behaviorMap={{inactive: isInSharedWorkspace ? 'showPopup' : 'show'}} webMessage={createMessage}>
                  <Button
                    style={{...styles.addButton}}
                    onPress={this.createProcessInstance}
                    icon={createIcon}
                    iconSize={{width: K.calc(35), height: K.calc(35)}}
                  />
                </AccessManager>
              </Tooltip>
            </View>
          )}
          {this.state.isEditing && (
            <CategorySettingsPopup
              {...{processType, categoryOrg}}
              triggerUndoPopup={this.props.triggerUndoPopup}
              onClose={() => this.setState({isEditing: false})}
            />
          )}
          {sharePopupIsVisible && (
            <SharePopup
              activeProcessType={processType}
              hideSharePopup={() => this.props.setActiveView({data: {sharePopupIsVisible: false}})}
              handleShareCsvPress={() => this.handleShareCsvPress()}
              exportCompletionSummary={() => this.exportCompletionSummary()}
              hasCompletionPopup={hasCompletionPopup}
            />
          )}
        </View>
      </DocumentTitle>
    );
  }
}

export default withSafeAreaInsets(connect({
  mapState: (state, ownProps) => {
    var processTypeId = parseInt(ownProps.match.params.processTypeId);
    var orgId = parseInt(ownProps.match.params.orgId);
    var processInstancesById = state.resources.processInstances.byId;
    var triggered404 = false;

    if (processTypeId && state.activeView.data.deletedProcessTypeId !== processTypeId) {
      var processType = processTypeFor({state, processTypeId});

      if (!processType) {
        triggered404 = true;

        ownProps.history.push('/404/category');
      }
    }

    if (!triggered404 && orgId) {
      var org = _.find(state.session.orgs, {id: orgId});

      if (!org) {
        triggered404 = true;

        ownProps.history.push('/404/category');
      }
    }

    if (!triggered404) {
      if (ownProps.isShared) {
        var processInstances = _.filter(processInstancesById, ({orgId}) => !_.includes(_.map(state.session.orgs, 'id'), orgId));
      }
      else if (ownProps.isSingleUse) {
        var processInstances = _.filter(processInstancesById, instance => instance.orgId === org.id && _.get(_.find(state.resources.processTypes.byId, {id: instance.processTypeId}), 'isSingleUse'));
      }
      else if (processType) {
        var processInstances = _.filter(processInstancesById, {processTypeId: processType.id});
      }
    }

    return {
      ..._.pick(state.activeView.data, ['sharePopupIsVisible']),
      org,
      processType,
      allProcessTypes: processType ? [processType] : processTypesFor({state}), //HINT don't need to do this for standard projects - just for shared/single use
      processInstances: _.orderBy(processInstances, 'id', 'desc'),
      usersById: state.resources.users.byId,
      processFields: _.filter(state.resources.processFields.byId, {processTypeId}),
    };
  },
  mapDispatch: {
    setActiveView, setEvent, trackUsers: resourceActions.users.trackUsers,
    ..._.pick(resourceActions.processInstances, ['trackProcessInstances', 'updateProcessInstance', 'updateProcessInstances', 'destroyProcessInstance']),
    ..._.pick(resourceActions.processTypes, ['trackProcessTypes', 'updateProcessType']),
    ..._.pick(resourceActions.processSteps, ['createProcessSteps']),
    ..._.pick(resourceActions.processFields, ['trackProcessFields', 'updateProcessFields'])
  }
})(ProcessTypeShowView));
