import { React, resourceActions, Component, Text, View, store, Link, Notifications, Loading, logAnalyticsEvent } from '~/components';
import { Label, AppWrapper, prepareToAnimate, copilot, startCopilot, getDevice, BillingPortal } from '@symbolic/rn-lib';
import { setActiveView, setEvent } from '~/redux/index.js';
import { connect, pollApiForChanges, setAppData, updateMyAccount, signUp, logIn, logOut } from '@symbolic/redux';
import { Switch, Route } from 'react-router-native';
import { Platform, BackHandler } from 'react-native';
import { api } from '@symbolic/lib';
// import GestureRecognizer, {swipeDirections} from 'react-native-swipe-gestures';
import { DEFAULT_APP_OPTIONS } from 'expo-firebase-core';

import * as ExpoNotifications from 'expo-notifications';
import * as Linking from 'expo-linking';

import K from '~/k';
import Url from 'url-parse';
import UrlPattern from 'url-pattern';
import introSlides from './intro-slides/intro-slides';
import queryString from 'query-string';
import _ from 'lodash';
import styles from './app.styles';

import HeaderContent from '~/components/header-content/header-content';
import NotifiedIndicator from '~/components/notified-indicator/notified-indicator';
import UndoPopup from  '~/components/popups/undo-popup/undo-popup.js';

import HomePage from '~/pages/home/home-page';
import ProjectPage from '~/pages/project/project-page';
import CategoryPage from '~/pages/category/category-page';
import ReportPage from '~/pages/report/report-page';

ExpoNotifications.setNotificationHandler({
  handleNotification: async () => ({
    shouldShowAlert: true,
    shouldPlaySound: false,
    shouldSetBadge: true,
  }),
});

class App extends React.PureComponent {
  static resourceKeys = ['notifications'];

  state = {
    resourceStatus: 'unloaded',
    renderForSharing: false,
    template: {},
    isLoaded: true,
    firebaseInitialized: false
  };

  constructor(props) {
    super(props);

    if (K.isWeb) this.initialWebUrl = window.location.href;

    this.handleNotificationPress = this.handleNotificationPress.bind(this);
  }

  componentDidMount = async () => {
    //unique use count
    var uniqueUsesCount = await sessionStore.get('uniqueUsesCount') || 0;

    await sessionStore.set('uniqueUsesCount', uniqueUsesCount + 1);

    //url handling
    if (K.isMobileWeb) this.sendToApp({url: this.initialWebUrl});

    if (!K.isWeb) { //HINT this is only for when mobile app is open & running and someone visits a web link that sends them to the app
      Linking.addEventListener('url', this.handleUrlChange);
    }

    if (Platform.OS === 'android') {
      BackHandler.addEventListener('hardwareBackPress', this.back);
    }

    ExpoNotifications.addNotificationResponseReceivedListener(response => {
      if (response.actionIdentifier === ExpoNotifications.DEFAULT_ACTION_IDENTIFIER) {
        var data = response.notification.request.content.data;
        var {resourceId} = data;

        var handlerInterval = setInterval(() => {
          if (this.props.session.isLoggedIn && this.state.resourceStatus === 'loaded') {
            clearInterval(handlerInterval);

            this.handleNotificationPress({notification: {resourceId, data}});

            this.props.updateNotifications({updates: [{props: {status: 'read'}, where: {id: data.notificationId}}]});
          }
        }, 100);
      }
    });

    //copilot
    this.props.copilotEvents.on('stepChange', ({order}) => {
      this.props.setActiveView({data: {copilotStep: order}});
    });

    this.props.copilotEvents.on('stop', () => {
      global.copilotRunning = false;
      global.isShowingHelpCopilot = false;

      if (this.props.showingTutorial) {
        this.props.setActiveView({data: {tutorialComplete: true}});
      }
    });

    var {latestValidBuildNumber, pollFrequency} = _.get(await api.request({uri: '/app-status', body: {}}), 'data', {});

    this.setState({invalidBuildNumber: APP_BUILD_NUMBER < latestValidBuildNumber, pollFrequency});

    //main load resources call
    setTimeout(() => this.considerLoadingResources({}));
  }

  componentWillUnmount() {
    this.props.copilotEvents.off('stepChange');
    this.props.copilotEvents.off('stop');

    if (!K.isWeb) Linking.removeEventListener('url', this.handleUrlChange);

    if (Platform.OS === 'android') {
      BackHandler.removeEventListener('hardwareBackPress', this.back);
    }
  }

  async componentDidUpdate() {
    await this.considerLoadingResources({});
    await this.initializeFirebaseMessaging();
  }

  async initializeFirebaseMessaging() {
    // if (K.isWeb && this.props.session.isLoggedIn && !this.state.firebaseInitialized) {
    //   var deviceId = (await getDevice({appKey: 'weflowLite'})).id;
    //   var firebase = require('firebase/app').default;

    //   if (DEFAULT_APP_OPTIONS && !firebase.apps.length) {
    //     firebase.initializeApp(DEFAULT_APP_OPTIONS);
    //   }

    //   try {
    //     require('firebase/messaging');

    //     const messaging = firebase.messaging();

    //     //WARNING public key
    //     var currentToken = await messaging.getToken({vapidKey: "BL-7NN13EcltcJ6eWu_-hmu84twbXvyVH5dGDdQSdXzWcNkdIBARPbN4RR8uYlzTFs-zuvphq4GefMY-EYHQ-54"});

    //     if (currentToken) {
    //       var device = await getDevice({appKey: 'weflowLite'});

    //       if (_.get(this.props.session, `user.devices.${deviceId}.firebasePushToken`) !== currentToken) {
    //         await this.props.updateMyAccount({firebasePushTokenData: {firebasePushToken: currentToken, deviceId: device.id}});
    //       }
    //     } else {
    //       // Show permission request UI
    //       console.log('No registration token available. Request permission to generate one.');
    //       // ...
    //     }

    //     // //#WARNING probably not doing anything?
    //     // messaging.onTokenRefresh(async (token) => {
    //     //   var device = await getDevice({appKey: 'weflowLite'});

    //     //   if (_.get(this.props.session, `user.devices.${deviceId}.firebasePushToken`) !== token) {
    //     //     await this.props.updateMyAccount({firebasePushTokenData: {firebasePushToken: token, deviceId: device.id}});
    //     //   }
    //     // });

    //     // messaging.onMessage(async (payload) => {
    //     //   console.log('[firebase-messaging-sw.js] Received message ', payload);
    //     //   // show an animation??
    //     // });
    //   }
    //   catch (error) {
    //     console.log('An error occurred while retrieving token. ', error);
    //   }

    //   this.setState({firebaseInitialized: true});
    // }
  }

  considerLoadingResources = async ({forceReload, callback}={}) => {
    var {invalidBuildNumber} = this.state;

    if (!invalidBuildNumber && this.props.session.isLoggedIn && (this.state.resourceStatus === 'unloaded' || forceReload)) {
      try {
        if (!forceReload) this.setState({resourceStatus: 'loading'});

        var paramsByResourceKey = {
          notifications: {where: {userId: this.props.session.user.id, resourceKey: 'processInstance', status: 'unseen'}},
        };

        var resources = await Promise.all(_.map(App.resourceKeys, resourceKey => (
          api.get(resourceKey, paramsByResourceKey[resourceKey] || {where: {orgId: _.map(this.props.session.orgs, 'id')}})
        )));

        await Promise.all(_.map(App.resourceKeys, (resourceKey, index) => {
          this.props[`track${_.upperFirst(resourceKey)}`]({[resourceKey]: resources[index], reset: true});
        }));

        var processInstancesResponse = await api.request({uri: '/process-instances/get', body: {}});
        var usersData = await api.request({uri: '/get-users'});

        await this.props.trackUsers({users: _.get(usersData, 'data.users'), reset: true});
        await this.props.trackProcessInstances({processInstances: _.get(processInstancesResponse, 'data.processInstances'), reset: true});
        await this.props.trackProcessTypes({processTypes: _.get(processInstancesResponse, 'data.processTypes'), reset: true});
        await this.props.trackProcessSteps({processSteps: _.get(processInstancesResponse, 'data.processSteps'), reset: true});
        await this.props.trackProcessFields({processFields: _.get(processInstancesResponse, 'data.processFields'), reset: true});

        //WARNING throws errors intermittently on local ip
        var {trackComments, destroyComments,
          trackNotifications, destroyNotifications,
          trackComments, destroyComments,
          trackProcessInstances, destroyProcessInstances,
          trackProcessSteps, destroyProcessSteps,
          trackProcessTypes, destroyProcessTypes,
          trackProcessFields, destroyProcessFields
        } = this.props;

        if (process.env.NODE_ENV === 'production' || K.isWeb) {
          if (this.pollApiForChangesInterval) clearInterval(this.pollApiForChangesInterval);

          var userEmail = this.props.session.user.email.toLowerCase();

          global.IS_HENRYBUILT_USER = _.includes(userEmail, 'henrybuilt') || _.includes(userEmail, 'symbolicframeworks') || _.includes(userEmail, 'spacetheory');

          logAnalyticsEvent('app_loaded');

          var lastProcessInstance, lastPathname;

          var getActiveProcessInstance = () => {
            if (this.props.session.isLoggedIn && this.routerProps) {
              var pattern = new UrlPattern('/projects/:processInstanceId(/*)');
              var {pathname} = this.routerProps.location;

              if (pathname !== lastPathname) {
                lastPathname = pathname;
                lastProcessInstance = pattern.match(pathname) ? store.getState().resources.processInstances.byId[pattern.match(pathname).processInstanceId] : null;
              }

              return lastProcessInstance;
            }
          };

          this.pollApiForChangesInterval = pollApiForChanges({
            store, prepareToAnimate, pollFrequency: this.state.pollFrequency,
            resources: {
              notifications: {trackNotifications, destroyNotifications, getData: () => ({resourceKey: 'processInstance'})},
              comments: {trackComments, destroyComments, getData: () => ({processInstanceId: getActiveProcessInstance().id}), getShouldPoll: () => !!getActiveProcessInstance()},
              processInstances: {trackProcessInstances, destroyProcessInstances},
              processSteps: {trackProcessSteps, destroyProcessSteps},
              processTypes: {trackProcessTypes, destroyProcessTypes},
              processFields: {trackProcessFields, destroyProcessFields}
            }
          });
        }

        setTimeout(() => {
          this.setState({resourceStatus: 'loaded'});
        });
      }
      catch(error) {
        console.error(error);
      }

      if (callback) callback();
    }

    if (!this.props.session.isLoggedIn) {
      if (this.pollApiForChangesInterval) clearInterval(this.pollApiForChangesInterval);

      if (!this.props.session.isLoading) {
        await this.props.trackProcessInstances({processInstances: [], reset: true}); //TODO
        await this.props.trackNotifications({notifications: [], reset: true});
        await this.props.trackComments({comments: [], reset: true});

        if (this.state.resourceStatus === 'loaded') this.setState({resourceStatus: 'unloaded'});
      }
    }
  }

  sendToApp = ({url}) => {
    var {pathname, query} = new Url(url);

    var mobileUrl = `${__DEV__ ? `exp://192.168.86.36:19000` : `polydot://`}?goto=${encodeURIComponent(pathname+query)}`;

    try {
      Linking.openURL(mobileUrl);
    }
    catch (error) {
      console.error(error);
    }
  }

  handleUrlChange = async ({url}) => {
    if (url && !K.isMobileWeb) {
      var {goto: fullPath} = queryString.parse(`?${url.split('?')[1]}`); //HINT handle mobile browser sending user to app

      if (!K.isWeb && fullPath) {
        var [pathname, query] = _.split(fullPath, '?'); //HINT full path from mobile browser
      }
      else {
        var {pathname, query} = new Url(url); //HINT handle desktop/dynamic link

        fullPath = pathname + query;
      }

      var {userToken, projectId} = queryString.parse(query);

      userToken = _.trim(userToken);

      if (userToken && projectId) {
        var device = await getDevice({appKey: 'weflowLite'});

        if (this.props.session.isLoggedIn) {
          await this.props.logOut({shouldRemoveToken: false});
        }

        setTimeout(async () => {
          await sessionStore.setToken(userToken);

          await this.props.logIn({token: userToken, device});

          setTimeout(async () => {
            await this.considerLoadingResources();

            this.routerProps.history.push(`/projects/${projectId}`); //HINT specifically don't want query string in this case
          })
         });
      }
      else if (!K.isWeb) {
        this.routerProps.history.push(fullPath);
      }
    }
  }

  triggerUndoPopup = ({event}) => {
    this.props.setEvent({event});

    this.props.setActiveView({data: {undoPopupIsVisible: true}});
  }

  getBackLink = ({activeProcessInstance, activeProcessInstanceType, ignoreCameFromHome=false}={}) => {
    var url = '/';
    if (!activeProcessInstance) var resources = store.getState().resources;

    if ((ignoreCameFromHome || !global.cameFromHome) && (activeProcessInstance || (this.routerProps && resources.processInstances.byId && resources.processTypes.byId))) {
      if (!activeProcessInstance) {
        var pattern = new UrlPattern('/projects/:processInstanceId(/*)');
        var {pathname} = this.routerProps.location;
        var activeProcessInstance = pattern.match(pathname) ? resources.processInstances.byId[pattern.match(pathname).processInstanceId] : null;
      }

      if (activeProcessInstance) {
        var org = _.find(this.props.session.orgs, {id: activeProcessInstance.orgId});

        activeProcessInstanceType = activeProcessInstanceType || resources.processTypes.byId[activeProcessInstance.processTypeId];

        if (!org) url = `/categories/shared`;
        else if (activeProcessInstanceType.isSingleUse) url = `/categories/single-use/${org.id}`;
        else url = `/categories/${activeProcessInstance.processTypeId}`
      }
    }

    return url;
  }

  back = (options) => {
    if (this.routerProps.location.pathname !== '/') {
      var url = this.getBackLink(options);

      this.routerProps.history.push(url);

      return true;
    }
  }

  getRouterProps = async (routerProps) => {
    var isFirstRouterLoad = !this.routerProps;

    this.routerProps = routerProps;

    if (isFirstRouterLoad) {
      this.handleUrlChange({url: K.isWeb ? this.initialWebUrl : await Linking.getInitialURL()});
    }
  }

  getDotRowLeftWidth = ({processType, org, isSingleUse, isHome, isOrderedByOwner, isOrderedByNextDueDate, isOrderedByPriority}) => {
    var categoryViewSettings = this.getCategoryViewSettings({isHome});
    var dotRowLeftWidth = 0;

    if (K.isWeb) dotRowLeftWidth += K.calc(20) + K.spacing; //pin icon - width 20 + spacing
    if (!isHome && ((processType && processType.usesProcessInstanceRank && isOrderedByPriority) || (isSingleUse && org && org.usesProcessInstanceRank))) dotRowLeftWidth += K.calc(30) + K.spacing;
    if (!isHome && (_.get(categoryViewSettings, 'showOwners', 0) === 1 || isOrderedByOwner)) dotRowLeftWidth += K.calc(30) + K.spacing;
    if (!isHome && isOrderedByNextDueDate) dotRowLeftWidth += (K.calc(55) + K.margin);

    return dotRowLeftWidth;
  }

  getDotRowWidth = (arg) => {
    var {maxStepCount, visibleProcessFields, isHome} = arg;

    var dotRowWidth = maxStepCount * this.getDotWidth({isHome}) + K.spacing; //dots + right spacing

    dotRowWidth += this.getDotRowLeftWidth(arg);

    if (!isHome && visibleProcessFields.length > 0) dotRowWidth += _.sum(_.map(visibleProcessFields, processField => 100 * processField.sizeMultiplier)) + K.spacing;

    return dotRowWidth;
  }

  getCategoryViewSettings = ({isHome}={}) => {
    var categoryViewSettings = _.get(this.props.session.user, `appData.weflowLite.categoryViewSettings`, {});

    if (!isHome) categoryViewSettings.isActuallyShowingDates = categoryViewSettings.showByWhenDates || categoryViewSettings.showCompletedDates;

    return categoryViewSettings;
  }

  getDotWidth = ({isHome}={}) => {
    var dotWidth = styles.K.dotSize + styles.K.dotMargin;
    var categoryViewSettings = this.getCategoryViewSettings({isHome});

    if (!isHome) {
      var showDates = _.get(categoryViewSettings, 'showCompletedDates', 0) === 1 || _.get(categoryViewSettings, 'showByWhenDates', 0) === 1;

      if (showDates) dotWidth += K.calc(8);
    }

    return dotWidth;
  }

  async handleNotificationPress({notification}) {
    var notificationRoute = `/projects/${notification.resourceId}/`;

    var storeState = store.getState();

    var existingProcessInstance = _.get(storeState, `resources.processInstances.byId[${notification.resourceId}]`);

    if (!existingProcessInstance) {
      var processInstanceData = await api.request({uri: '/process-instances/get', body: {processInstanceId: notification.resourceId}});
      var processInstance = _.get(processInstanceData, 'data.processInstance');
      var processType = _.get(processInstanceData, 'data.processType');
      var processSteps = _.get(processInstanceData, 'data.processSteps');

      this.props.trackProcessInstances({processInstances: [processInstance]});
      this.props.trackProcessTypes({processTypes: [processType]});
      this.props.trackProcessSteps({processSteps});
    }

    if (notification.data.processInstanceStepId) notificationRoute += `steps/${notification.data.processInstanceStepId}/`;

    setTimeout(() => this.routerProps.history.push(notificationRoute));
  }

  startCopilot = options => {
    global.copilotRunning = true;

    startCopilot({
      start: this.props.start,
      setSessionStore: ({key, value}) => this.props.setAppData({key, value, appKey: 'weflowLite'}),
      getSessionStore: (key) => _.get(this.props, `session.user.appData.weflowLite.${key}`),
      ...options
    });
  }

  help = () => {
    this.startCopilot();
  }

  render() {
    var {considerLoadingResources, triggerUndoPopup, startCopilot, back} = this;
    var {copilotEvents} = this.props;

    var sharedProps = {considerLoadingResources, triggerUndoPopup, startCopilot, copilotEvents, getCategoryViewSettings: this.getCategoryViewSettings, ..._.pick(this, ['getDotRowWidth', 'getDotRowLeftWidth', 'getDotWidth']), back};

    return this.state.invalidBuildNumber ? (
      <View style={{flex: 1, height: '100%', alignItems: 'center', justifyContent: 'center'}}>
        <Text style={{textAlign: 'center', justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
          {K.isWeb ? 'Polydot has been upated.\n\nPlease refresh to get the latest version.' : 'Your Polydot app is out of date.\n\nPlease update it via the app store.'}
        </Text>
      </View>
    ) : (
      <AppWrapper
        appName={'Polydot'}
        appTagline={'by Symbolic Frameworks'}
        appKey={'weflowLite'}
        appDescription={`Clarify steps and get things done with other people`}
        hideHeader={this.props.isPrinting}
        onNotificationPress={this.handleNotificationPress}
        Notifications={Notifications}
        headerContent={HeaderContent}
        headerContentProps={{..._.pick(this, ['startCopilot', 'getBackLink'])}}
        introSlides={introSlides}
        setSessionStore={({key, value}) => this.props.setAppData({key, value, appKey: 'weflowLite'})}
        getSessionStore={(key) => _.get(this.props, `session.user.appData.weflowLite.${key}`)}
        trackUsers={this.props.trackUsers}
        getRouterProps={this.getRouterProps}
      >
        {(this.state.resourceStatus !== 'loaded') ? (
          <Loading text={'Grabbing projects\n\nthis can take a few seconds\n\nHold tight...'}/>
        ) : (
        <>
          {/* <GestureRecognizer
            config={{velocityThreshold: 0.3, directionalOffsetThreshold: 80}}
            onSwipeRight={this.back}
            style={{flex: 1}}
          > */}
            <Switch>
              <Route
                exact
                path={[
                  '/projects/:processInstanceId',
                  '/projects/:processInstanceId/steps/:processStepId',
                  '/projects/:processInstanceId/:email/:token',
                ]}
                render={props => <ProjectPage {...props} {...sharedProps}/>}
              />
              <Route exact path={['/categories/shared']} render={props => (
                <CategoryPage {...props} isShared isSingleUse {...sharedProps}/>
              )}/>
              <Route exact path={['/categories/single-use/:orgId']} render={props => (
                <CategoryPage {...props} isSingleUse {...sharedProps}/>
              )}/>
              <Route exact path={['/categories/:processTypeId']} render={props => (
                <CategoryPage {...props} {...sharedProps}/>
              )}/>
              <Route exact path={['/reports/:reportId']} render={props => (
                <ReportPage {...props} {...sharedProps}/>
              )}/>
              <Route exact path={['/billing']} render={props => <BillingPortal trackUsers={this.props.trackUsers} {...props}/>}/>
              <Route exact path={['/billing/seats/:userFriendlyProductKey']} render={props => <BillingPortal trackUsers={this.props.trackUsers} {...props} userFriendlyProductKey={props.match.params.userFriendlyProductKey}/>}/>
              <Route exact path={['', '/', '/@symbolic*']} render={props => (
                <>
                  <HomePage
                    {...props}
                    {...sharedProps}
                    // help={this.help}
                  />
                </>
              )}/>
              <Route path={['/404/:type/:processInstanceId', '/404/:type', '']} render={({match}) => {
                var type = _.startCase(match.params.type || '');

                return (
                  <View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
                    {!!type && (
                      <Label style={{paddingHorizontal: K.spacing * 2, maxWidth: 300, marginBottom: K.spacing * 3, textAlign: 'center'}}>{`You might need to ask for an invite or what you're looking for might have been deleted`}</Label>
                    )}
                    <Link to='/' style={{...K.button, backgroundColor: K.colors.gray, paddingHorizontal: K.spacing, width: 'auto'}}><Label>Go Home</Label></Link>
                  </View>
                );
              }}/>
            </Switch>
            {/* </GestureRecognizer> */}
          <NotifiedIndicator/>
        </>)}
        {this.props.undoPopupIsVisible && (
          <UndoPopup key={_.get(this.props, 'event.uuid')}/>
        )}
      </AppWrapper>
    );
  }
}

export default copilot()(connect({
  mapState: (state, ownProps) => {
    return {
      ..._.pick(state.activeView.data, ['undoPopupIsVisible', 'showingTutorial', 'isPrinting']),
      users: state.resources.users.byId,
      session: state.session,
      event: state.event
    }
  },
  mapDispatch: {
    ..._.pick(resourceActions.users, ['trackUsers']),
    ..._.pick(resourceActions.comments, ['trackComments', 'destroyComments']),
    ..._.pick(resourceActions.notifications, ['trackNotifications', 'updateNotifications', 'destroyNotifications']),
    ..._.pick(resourceActions.processInstances, ['trackProcessInstances', 'destroyProcessInstances']),
    ..._.pick(resourceActions.processTypes, ['trackProcessTypes', 'destroyProcessTypes']),
    ..._.pick(resourceActions.processSteps, ['trackProcessSteps', 'destroyProcessSteps']),
    ..._.pick(resourceActions.processFields, ['trackProcessFields', 'destroyProcessFields']),
    setActiveView, setAppData, logIn, logOut, signUp, updateMyAccount, setEvent
  }
})(App));
